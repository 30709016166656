import { action, makeObservable, observable } from 'mobx';

export interface INotificationsOptions {
  title?: string;
  message?: string;
  variant?: NotificationVariantType;
  action?: {
    text: string;
    actionCallback?: () => void;
  };
  onClose?: () => void;
}

export type NotificationVariantType = 'default' | 'success' | 'error';

const defaultOptions = {
  title: '',
  message: '',
  variant: 'default' as NotificationVariantType,
};

export class NotificationsStore {
  @observable public isOpen: boolean;
  @observable public notificationOptions: INotificationsOptions;

  constructor() {
    makeObservable(this);

    this.isOpen = false;
    this.notificationOptions = { ...defaultOptions };
  }

  @action
  public showNotification = (options: INotificationsOptions): void => {
    this.isOpen = true;
    this.notificationOptions = {
      ...defaultOptions,
      ...options,
    };
  };

  @action
  public hideNotification = (): void => {
    this.isOpen = false;
    this.notificationOptions = { ...defaultOptions };
  };
}

export default NotificationsStore;
