import { BaseAPI } from 'src/core/api/BaseAPI';
import {
  PaginatedLessonsResponse,
  DrivingLessonResponse,
  NewDrivingLessonData,
  RequestParams,
  UpdateDrivingLessonData,
  MaintainDrivingLessonData,
} from 'src/core/entities/DLMService/DrivingLesson';
import { ApiRequestResult } from 'src/types';

export class DrivingLessonsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  fetchAll(
    params: RequestParams,
    signal?: AbortSignal
  ): ApiRequestResult<PaginatedLessonsResponse> {
    return this.baseApi.get('/api/v1/ispa/lessons', { signal, params });
  }

  fetchById(lessonId: string): ApiRequestResult<DrivingLessonResponse> {
    return this.baseApi.get(`/api/v1/ispa/lessons/${lessonId}`);
  }

  fetchUpcoming(): ApiRequestResult<PaginatedLessonsResponse> {
    return this.baseApi.get('/api/v1/ispa/lessons/upcoming');
  }

  fetchUncompleted(): ApiRequestResult<PaginatedLessonsResponse> {
    return this.baseApi.get('/api/v1/ispa/instructors/lessons/uncompleted');
  }

  create(data: NewDrivingLessonData): ApiRequestResult<DrivingLessonResponse> {
    return this.baseApi.post('/api/v1/ispa/lessons', data);
  }

  update(
    lessonId: string,
    data: UpdateDrivingLessonData
  ): ApiRequestResult<DrivingLessonResponse> {
    return this.baseApi.put(`/api/v1/ispa/lessons/${lessonId}`, data);
  }

  cancel(lessonId: string): ApiRequestResult<DrivingLessonResponse> {
    return this.baseApi.put(`/api/v1/ispa/lessons/${lessonId}/cancel`);
  }

  maintain(
    lessonId: string,
    data: MaintainDrivingLessonData
  ): ApiRequestResult {
    return this.baseApi.put(`/api/v1/ispa/lessons/${lessonId}/maintain`, data);
  }

  removeStudent(lessonId: string): ApiRequestResult<DrivingLessonResponse> {
    return this.baseApi.put(`/api/v1/ispa/lessons/${lessonId}/remove_student`);
  }

  markStudentAsAbsent(
    lessonId: string
  ): ApiRequestResult<DrivingLessonResponse> {
    return this.baseApi.put(`/api/v1/ispa/lessons/${lessonId}/student_absent`);
  }
}
