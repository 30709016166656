import { ispaAPI, queryClient } from 'src/App';

import { useMutation } from '@tanstack/react-query';

import {
  PREFERENCES_QUERY_KEY,
  useDLMPreferencesQuery,
} from '../queries/useDLMPreferencesQuery';

interface Variables {
  lessonDefinitionId: string;
}

export const useUpdateDLMPreferencesMutation = () => {
  const preferencesQuery = useDLMPreferencesQuery();

  return useMutation({
    mutationFn: async ({ lessonDefinitionId }: Variables) => {
      const data = {
        preferred_pick_up_point: preferencesQuery.data?.preferred_pick_up_point,
        preferred_driving_lesson_category: lessonDefinitionId,
      };

      const result = await ispaAPI.instructorsAPI.updateDLMPreferences(data);

      if (result.success) {
        return result.data;
      }

      return null;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(PREFERENCES_QUERY_KEY);
    },
  });
};
