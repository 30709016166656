import { makeAutoObservable, runInAction } from 'mobx';
import EditableScheduleEventStore from 'src/core/stores/EditableScheduleEventsStore';
import { Result } from 'src/types';
import { getPersonFullName } from 'src/utils/helpers';
import {
  addTimeToDate,
  getDate,
  getDiff,
  getMinuteStart,
  isDateAfter,
  isSameMinuteDates,
} from 'src/utils/time';

import { ScheduleEvent, ScheduleEventType } from '../ScheduleEvent';
import {
  NewStudent,
  ScheduleEventChangedData,
} from './EditableScheduleEvent.types';

export class EditableScheduleEvent {
  saving: boolean;

  readonly id: string;

  type: ScheduleEventType;
  newStartDate: Date | null;
  newEndDate: Date | null;
  newDescription: string | null;
  newStudent: NewStudent | null;

  constructor(
    private readonly initialData: ScheduleEvent,
    private readonly store: EditableScheduleEventStore
  ) {
    makeAutoObservable(this);

    this.saving = false;

    const { id, type } = initialData;

    this.id = id;
    this.type = type;
    this.newStartDate = null;
    this.newEndDate = null;
    this.newDescription = null;
    this.newStudent = null;
  }

  get startDate(): Date {
    return getDate(this.newStartDate || this.initialData.startDate);
  }

  setStartDate = (value: Date) => {
    this.setEndDate(
      addTimeToDate(
        this.endDate,
        getDiff(value, this.startDate),
        'milliseconds'
      )
    );

    if (isSameMinuteDates(value, this.initialData.startDate)) {
      this.newStartDate = null;
      return;
    }

    this.newStartDate = getMinuteStart(value);
  };

  get endDate(): Date {
    return getDate(this.newEndDate || this.initialData.endDate);
  }

  setEndDate = (value: Date) => {
    if (isSameMinuteDates(value, this.initialData.endDate)) {
      this.newEndDate = null;
      return;
    }

    this.newEndDate = getMinuteStart(value);
  };

  get description() {
    return typeof this.newDescription === 'string'
      ? this.newDescription
      : this.initialData.description;
  }

  setDescription = (value: string) => {
    if (value === this.initialData.description) {
      this.newDescription = null;
      return;
    }

    this.newDescription = value;
  };

  get studentFullName(): string {
    const student =
      this.newStudent || this.initialData.studentAttendee?.student;

    return student ? getPersonFullName(student) : '';
  }

  setStudent(student: NewStudent): void {
    if (student.id === this.initialData.studentAttendee?.student?.id) {
      this.newStudent = null;
      return;
    }

    this.newStudent = student;
  }

  get changedData(): ScheduleEventChangedData {
    switch (this.type) {
      default: {
        return {
          ...(this.newStartDate && { startDate: this.newStartDate }),
          ...(this.newEndDate && { endDate: this.newEndDate }),
          ...(typeof this.newDescription === 'string' && {
            description: this.newDescription,
          }),
        };
      }
    }
  }

  get changed(): boolean {
    return Object.keys(this.changedData).length > 0;
  }

  get datesValid(): boolean {
    return isDateAfter(this.endDate, this.startDate);
  }

  async save(): Promise<Result> {
    this.saving = true;

    if (this.newStudent) {
      const studentUpdateResult = await this.store.changeStudent(
        this.initialData,
        this.newStudent.id,
        this.initialData.studentAttendee?.id
      );

      if (!studentUpdateResult.success) {
        this.store.reloadScheduleEventData(this.id);

        return studentUpdateResult;
      }
    }

    const result = await this.store.updateScheduleEvent(
      this.id,
      this.changedData
    );

    runInAction(() => {
      this.saving = false;
    });

    this.store.reloadScheduleEventData(this.id);

    return result;
  }
}
