import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';
import {
  CreateInstructorCommentData,
  InstructorCommentData,
} from 'src/core/entities/InstructorComment';

const endpoints = {
  fetchByStudentId: (studentId: string) =>
    `/api/v1/students/${studentId}/comments`,
  mark: (commentId: string) => `/api/v1/student_comments/${commentId}/mark`,
  create: () => '/api/v1/student_comments',
  delete: (commentId: string) => `/api/v1/student_comments/${commentId}`,
};

export class InstructorCommentsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchByStudentId(
    studentId: string
  ): ApiRequestResult<InstructorCommentData[]> {
    return this.baseApi.get(endpoints.fetchByStudentId(studentId));
  }

  public toggleMark(
    commentId: string
  ): ApiRequestResult<InstructorCommentData> {
    return this.baseApi.put(endpoints.mark(commentId));
  }

  public create(
    data: CreateInstructorCommentData
  ): ApiRequestResult<InstructorCommentData> {
    return this.baseApi.post(endpoints.create(), data);
  }

  public delete(commentId: string): ApiRequestResult {
    return this.baseApi.delete(endpoints.delete(commentId));
  }
}
