import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {
  PracticeProtocol,
  ProtocolLessonType,
  UpdatePracticeProtocolItemPayload,
} from 'src/core/entities/PracticeProtocol';
import { ScheduleEvent } from 'src/core/entities/ScheduleEvent';
import { errorLogger, getRequestErrorDetails } from 'src/utils/errorLogger';

import { Result } from '../../types';
import { PracticeProtocolAPI } from '../api/IspaAPI/PracticeProtocolAPI';
import { PracticeProtocolAPI as TachoPracticeProtocolAPI } from '../api/TachoAPI/PracticeProtocolAPI';
import RootStore from './RootStore';

class PracticeProtocolStore {
  studentEducationId: string;
  eventId: string;
  eventType: ProtocolLessonType | null;
  isDLMLesson: boolean;
  protocol: PracticeProtocol | null;
  protocolLoading: boolean;
  protocolUpdating: boolean;
  protocolUpdated: boolean;
  protocolLoadingFailed: boolean;

  constructor(
    private readonly rootStore: RootStore,
    private readonly practiceProtocolAPI: PracticeProtocolAPI,
    private readonly tachoPracticeProtocolAPI: TachoPracticeProtocolAPI
  ) {
    makeObservable<
      PracticeProtocolStore,
      'loadByStudentEducationId' | 'updatePracticeProtocol'
    >(this, {
      eventId: observable,
      eventType: observable,
      isDLMLesson: observable,
      studentEducationId: observable,
      protocol: observable,
      protocolLoading: observable,
      protocolUpdating: observable,
      protocolUpdated: observable,
      protocolLoadingFailed: observable,
      currentEvent: computed,
      currentEventStudentEducationId: computed,
      currentEducationId: computed,
      toInitialState: action,
      resetProtocol: action,
      setStudentEducationId: action,
      setEventId: action,
      setEventType: action,
      loadByStudentEducationId: action,
      setDLMLesson: action,
      updatePracticeProtocol: action,
    });

    this.toInitialState();

    reaction(
      () => this.currentEvent,
      (event) => {
        this.rootStore.studentEducationsStore.setStudentId(event?.studentId);
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.currentEducationId,
      (studentEducationId) => {
        if (!studentEducationId) {
          return;
        }

        this.resetProtocol();

        this.loadByStudentEducationId(studentEducationId);
      },
      { fireImmediately: true }
    );
  }

  public get currentEvent(): ScheduleEvent | undefined {
    if (!this.eventId || this.isDLMLesson) {
      return;
    }

    return this.rootStore.eventScheduleStore.getScheduleEventById(this.eventId);
  }

  get currentEventStudentEducationId(): string | undefined {
    if (this.rootStore.studentEducationsStore.educationsLoading) {
      return;
    }

    const education =
      this.rootStore.studentEducationsStore.getByDrivingCategoryId(
        this.currentEvent?.drivingCategoryChargerId
      );

    return education?.education_id;
  }

  get currentEducationId(): string | undefined {
    return this.studentEducationId || this.currentEventStudentEducationId;
  }

  public toInitialState = (): void => {
    this.protocolLoading = false;
    this.protocolUpdating = false;
    this.protocolLoadingFailed = false;

    this.studentEducationId = '';
    this.eventId = '';
    this.eventType = null;
    this.isDLMLesson = false;

    this.resetProtocol();
  };

  public resetProtocol = (): void => {
    this.protocol = null;
    this.protocolUpdated = false;
  };

  setStudentEducationId(studentEducationId: string = ''): void {
    this.studentEducationId = studentEducationId;
  }

  setDLMLesson(isDLMLesson: boolean = true): void {
    this.isDLMLesson = isDLMLesson;
  }

  public setEventId = (eventId: string): void => {
    this.eventId = eventId;
  };

  public setEventType = (eventType: ProtocolLessonType): void => {
    this.eventType = eventType;
  };

  async saveChanges(hasProgress: boolean = false): Promise<Result> {
    if (!this.eventId || !this.currentEducationId || !this.eventType) {
      return { success: false };
    }

    const changedItemsData =
      this.protocol?.changedItems.map(({ id, newLevel }) => ({
        id,
        new_level: newLevel,
      })) || [];

    if (!changedItemsData.length && hasProgress) {
      this.setProgressOnTacho(this.eventId, this.eventType);

      return { success: true };
    }

    return this.updatePracticeProtocol(
      this.eventId,
      this.eventType,
      this.currentEducationId,
      changedItemsData
    );
  }

  public async setNoProgress(): Promise<Result> {
    if (!this.eventId || !this.currentEducationId || !this.eventType) {
      return { success: false };
    }

    return this.updatePracticeProtocol(
      this.eventId,
      this.eventType,
      this.currentEducationId,
      []
    );
  }

  // API requests
  async loadByStudentEducationId(educationId: string): Promise<void> {
    if (this.protocolLoading || this.protocol) {
      return;
    }

    runInAction(() => {
      this.protocolLoading = true;
    });

    const result = await this.practiceProtocolAPI.fetchByStudentEducationId(
      educationId
    );

    runInAction(() => {
      this.protocolLoading = false;
    });

    if (result.success) {
      this.protocol = new PracticeProtocol(result.data);
    } else {
      errorLogger('Error loading practice protocol', {
        extras: {
          educationId,
          eventId: this.eventId,
          ...getRequestErrorDetails(result.error),
        },
      });

      runInAction(() => {
        this.protocolLoadingFailed = true;
      });
    }
  }

  async updatePracticeProtocol(
    eventId: string,
    eventType: ProtocolLessonType,
    educationId: string,
    changedItems: UpdatePracticeProtocolItemPayload[]
  ): Promise<Result> {
    runInAction(() => {
      this.protocolUpdating = true;
    });

    const result = this.isDLMLesson
      ? await this.practiceProtocolAPI.updateDLMLesson(
          eventId,
          educationId,
          changedItems
        )
      : await this.practiceProtocolAPI.update(
          eventId,
          eventType,
          educationId,
          changedItems
        );

    runInAction(() => {
      this.protocolUpdating = false;
    });

    if (result.success) {
      runInAction(() => {
        this.protocolUpdated = true;
        this.protocol = new PracticeProtocol(result.data);
      });

      this.setProgressOnTacho(eventId, eventType);
    }

    return result;
  }

  private async setProgressOnTacho(
    eventId: string,
    eventType: ProtocolLessonType
  ): Promise<void> {
    if (!this.isDLMLesson && eventType === 'driving-lesson') {
      this.tachoPracticeProtocolAPI.setNoProgress(eventId);
    }
  }
}

export default PracticeProtocolStore;
