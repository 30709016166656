import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getWeekStart } from 'src/utils/time';

import { useDLMCalendarEventsQuery } from './useDLMCalendarEventsQuery';
import { useTachoCalendarEventsQuery } from './useTachoCalendarEventsQuery';

interface Variables {
  dlmEnabled: boolean;
  instructorId: string;
  startDate: string;
  endDate: string;
}

const REQUESTS_DELAY_MS = 300;

export const useCalendarEventsQuery = ({
  dlmEnabled,
  instructorId,
  startDate,
  endDate,
}: Variables) => {
  const queryClient = useQueryClient();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setEnabled(true);
    }, REQUESTS_DELAY_MS);

    return () => {
      clearTimeout(timer);
      setEnabled(false);
      queryClient.cancelQueries({
        queryKey: [
          {
            api: 'tacho',
            scope: 'events',
            type: 'calendar',
            weekStart: getWeekStart(startDate),
            startDate,
            endDate,
          },
        ],
      });
      queryClient.cancelQueries({
        queryKey: [
          {
            api: 'dlm',
            scope: 'events',
            type: 'calendar',
            startDate,
            endDate,
          },
        ],
      });
    };
  }, [queryClient, setEnabled, startDate, endDate]);

  const tachoEventsQuery = useTachoCalendarEventsQuery({
    instructorId,
    startDate,
    endDate,
    enabled,
  });

  const dlmEventsQuery = useDLMCalendarEventsQuery({
    enabled: enabled && dlmEnabled,
    startDate,
    endDate,
  });

  const data = [
    ...(tachoEventsQuery.data || []),
    ...(dlmEventsQuery.data || []),
  ];

  return {
    data,
  };
};
