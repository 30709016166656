import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
  when,
} from 'mobx';
import { queryClient } from 'src/App';
import { HTTP_STATUS_CODE } from 'src/constants';
import { DLMServiceAPI, IspaAPI, TachoAPI } from 'src/core/api';
import { BaseAPI } from 'src/core/api/BaseAPI';
import {
  DLMPreferencesResponse,
  Instructor,
  UpdateInstructorData,
} from 'src/core/entities/Instructor';
import { LoginData, User } from 'src/core/entities/User';
import { isTokenRefreshRequest } from 'src/utils/helpers';

import * as Sentry from '@sentry/react';

import history from '../../history';
import { ROUTES } from '../../routes';
import { Result } from '../../types';
import { MediaObjectsAPI } from '../api/TachoAPI/MediaObjectsAPI';
import { UserAPI } from '../api/TachoAPI/UserAPI';
import { PREFERENCES_QUERY_KEY } from '../entities/Instructor/queries/useDLMPreferencesQuery';
import IndexedDBService from '../services/IndexedDBService';
import LocalStorageService from '../services/LocalStorageService';
import RootStore from './RootStore';

class UserStore {
  @observable public fetchingCurrentUser: boolean = false;
  @observable public updatingPreferredPickUpPoint: boolean = false;

  @observable public tokenRefreshing: boolean = false;

  @observable private token?: string | null;
  @observable private currentUser?: User | null;

  @observable private su?: string | null;

  @observable private refreshToken: string | null;

  constructor(
    private readonly rootStore: RootStore,
    private readonly ispaAPI: IspaAPI,
    private readonly tachoAPI: TachoAPI,
    private readonly dlmApi: DLMServiceAPI,
    private readonly userAPI: UserAPI,
    private readonly mediaObjectsAPI: MediaObjectsAPI,
    private readonly localDB: IndexedDBService
  ) {
    makeObservable(this);

    this.setApiInterceptors();

    const authToken = LocalStorageService.getAuthToken();
    const refreshToken = LocalStorageService.getRefreshToken();
    const suToken = LocalStorageService.getSuToken();

    this.refreshToken = refreshToken;
    this.su = suToken;

    if (authToken) {
      this.token = authToken;
    } else if (this.refreshToken) {
      this.tokenRefresh();
    }

    reaction(
      () => this.token,
      (token) => {
        if (token) {
          LocalStorageService.setAuthToken(token);
        } else {
          LocalStorageService.removeAuthToken();
        }
      }
    );

    reaction(
      () => this.refreshToken,
      (token) => {
        if (token) {
          LocalStorageService.setRefreshToken(token);
        } else {
          LocalStorageService.removeRefreshToken();
        }
      }
    );

    reaction(
      () => this.su,
      (su) => {
        if (su) {
          LocalStorageService.setSuToken(su);
        } else {
          LocalStorageService.removeSuToken();
        }
      }
    );

    const query = window.location.search;

    if (query) {
      const searchParams = new URLSearchParams(query);
      const t = searchParams.get('t');
      const su = searchParams.get('su');
      this.switch({ t, su });
    }

    reaction(
      () => ({ token: this.token, su: this.su }),
      ({ token, su }) => {
        if (token) {
          this.fetchCurrentUser();
        }
      },
      { fireImmediately: true }
    );

    autorun(() => {
      const user = this.currentUser && {
        id: this.currentUser.id,
        email: this.currentUser.email,
      };

      Sentry.setUser(user || null);
    });

    if (this.su) {
      Sentry.setTag('switch_user', true);
    }
  }

  @computed
  get fetchingCurrentInstructor(): boolean {
    if (!this.currentUser) {
      return false;
    }
    return this.rootStore.instructorsStore.fetchingInstructorsIds.includes(
      this.currentUser.id
    );
  }

  @computed
  get loadingCurrentUserData(): boolean {
    return this.fetchingCurrentUser || this.fetchingCurrentInstructor;
  }

  @action
  public switch({ t, su }: { t: string | null; su: string | null }): void {
    if (!t || !su) {
      return;
    }

    this.su = su;
    this.token = t;
    this.refreshToken = null;
    this.currentUser = null;
    this.localDB.clear();

    history.replace(ROUTES.HOME);
  }

  @computed
  public get isAuthenticated(): boolean {
    return Boolean(
      (this.token && this.token.length > 0) || this.tokenRefreshing
    );
  }

  @computed
  get currentUserId(): string | undefined {
    return this.currentUser?.id;
  }

  @computed
  public get currentInstructor(): Instructor | undefined {
    if (!this.currentUser) {
      return undefined;
    }
    return this.rootStore.instructorsStore.getInstructorById(
      this.currentUser.id
    );
  }

  public getCurrentUser(): User | null | undefined {
    return this.currentUser;
  }

  @action
  public logout(): void {
    this.clear();
    this.localDB.clear();
    history.push(ROUTES.LOGIN);
    queryClient.clear();
  }

  @action
  public clear(): void {
    this.token = null;
    this.refreshToken = null;
    this.currentUser = null;
    this.su = null;
  }

  public async uploadAvatar(data: File): Promise<Result> {
    const result = await this.mediaObjectsAPI.create(data);

    if (result.success) {
      return this.updatePersonalData({
        profileImage: result.data.id,
      });
    }

    return result;
  }

  public setDefaultDrivingLessonCategory = (
    categoryId: string
  ): Promise<Result> => {
    const payload = {
      preferredDrivingLessonCategory: categoryId,
    };

    return this.updatePersonalData(payload);
  };

  public setPreferredPickUpPoint = async (pointId: string): Promise<Result> => {
    const payload = {
      preferredPickUpPoint: pointId,
    };

    this.updatingPreferredPickUpPoint = true;

    const result = await this.updatePersonalData(payload);

    runInAction(() => {
      this.updatingPreferredPickUpPoint = false;
    });

    return result;
  };

  setDLMPreferredPickUpPoint = async (pickUpPointId: string): Promise<void> => {
    const data = queryClient.getQueryData<DLMPreferencesResponse>(
      PREFERENCES_QUERY_KEY
    );

    const result = await this.ispaAPI.instructorsAPI.updateDLMPreferences({
      preferred_pick_up_point: pickUpPointId,
      preferred_driving_lesson_category:
        data?.preferred_driving_lesson_category,
    });

    if (result.success) {
      queryClient.invalidateQueries(PREFERENCES_QUERY_KEY);
    }
  };

  // Login
  public phonePinLogin(phone: string, pin: string): Promise<Result> {
    const payload: LoginData = {
      pin: Number(pin),
      phone: /^\+/.test(phone) ? phone : `+${phone}`,
      type: 'phone-pin',
    };

    return this.login(payload);
  }

  @action
  private async login(payload: LoginData): Promise<Result> {
    const result = await this.userAPI.instructorLogin(payload);

    if (result.success) {
      const { token, refresh_token } = result.data;

      runInAction(() => {
        this.token = token;
        this.refreshToken = refresh_token;
      });
    }

    return result;
  }

  // API requests
  @action
  private async fetchCurrentUser(): Promise<void> {
    if (this.fetchingCurrentUser) {
      return;
    }

    runInAction(() => {
      this.fetchingCurrentUser = true;
    });

    const result = await this.userAPI.whoami();

    runInAction(() => {
      this.fetchingCurrentUser = false;
    });

    if (result.success) {
      const { user } = result.data;

      runInAction(() => {
        this.currentUser = user;
      });
    } else if (result.status === HTTP_STATUS_CODE.FORBIDDEN) {
      this.logout();
    }
  }

  @action
  private async tokenRefresh(): Promise<void> {
    if (!this.refreshToken || this.tokenRefreshing) {
      return;
    }

    runInAction(() => {
      this.tokenRefreshing = true;
      this.token = null;
    });

    const result = await this.userAPI.refreshToken(this.refreshToken);

    if (result.success) {
      const { token, refresh_token } = result.data;

      runInAction(() => {
        this.token = token;
        this.refreshToken = refresh_token;
      });
    } else {
      this.logout();
    }

    runInAction(() => {
      this.tokenRefreshing = false;
    });
  }

  private async updatePersonalData(
    personalData: UpdateInstructorData
  ): Promise<Result> {
    if (!this.currentUser) {
      return { success: false };
    }

    return this.rootStore.instructorsStore.updateInstructor(
      this.currentUser.id,
      personalData
    );
  }

  private addAuthHeader(api: BaseAPI): void {
    api.addRequestInterceptors(async (config) => {
      if (!isTokenRefreshRequest(config)) {
        await when(() => !this.tokenRefreshing);
      }

      if (this.token) {
        config.headers.Authorization = `Bearer ${this.token}`;
      }
      if (this.su) {
        config.headers['x-switch-user'] = this.su;
      }
      if (this.currentUser) {
        config.headers['X-User-Id'] = this.currentUser.id;
      }
      return config;
    });
  }

  private handleErrorResponse(api: BaseAPI): void {
    api.addResponseInterceptors(undefined, async (error) => {
      if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
        if (isTokenRefreshRequest(error?.config)) {
          throw error;
        }

        this.token = null;

        if (this.refreshToken && !this.tokenRefreshing) {
          this.tokenRefresh();
        }

        await when(() => !this.tokenRefreshing);

        if (this.token) {
          return api.request(error.config);
        }

        this.logout();
      }

      throw error;
    });
  }

  private setApiInterceptors(): void {
    [this.tachoAPI, this.ispaAPI, this.dlmApi].forEach((api) => {
      this.addAuthHeader(api);
      this.handleErrorResponse(api);
    });
  }
}

export default UserStore;
