import { observer } from 'mobx-react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import DirectionsCar from '@material-ui/icons/DirectionsCar';

import { ROUTES } from '../../../routes';
import CalendarIcon from '../../icons/CalendarIcon';
import IdCardIcon from '../../icons/IdCardIcon';
import styles from './styles';

interface IProps
  extends WithTranslation<'common'>,
    WithStyles<typeof styles>,
    RouteComponentProps {
  visible: boolean;
}

const BOTTOM_NAVIGATION_ROUTES = {
  HOME: ROUTES.HOME,
  CALENDAR: ROUTES.EVENT_SCHEDULE,
  STUDENTS: ROUTES.STUDENTS_LIST,
} as const;

type BottomNavigationRoute =
  (typeof BOTTOM_NAVIGATION_ROUTES)[keyof typeof BOTTOM_NAVIGATION_ROUTES];

const FhrBottomNavigation: React.FunctionComponent<IProps> = ({
  classes,
  t,
  history,
  visible,
}) => {
  if (!visible) {
    return null;
  }

  const currentValue = history.location.pathname;

  const handleChange = (event: unknown, value: BottomNavigationRoute) => {
    if (value === currentValue) {
      return;
    }

    history.push(value);
  };

  return (
    <BottomNavigation
      value={currentValue}
      onChange={handleChange}
      showLabels={true}
      className={classes.rootNav}
      data-testid="bottom_navigation"
    >
      <BottomNavigationAction
        value={BOTTOM_NAVIGATION_ROUTES.HOME}
        label={t('today')}
        icon={<DirectionsCar />}
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
        }}
      />
      <BottomNavigationAction
        value={BOTTOM_NAVIGATION_ROUTES.CALENDAR}
        label={t('appointment')}
        icon={<CalendarIcon />}
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
        }}
      />
      <BottomNavigationAction
        value={BOTTOM_NAVIGATION_ROUTES.STUDENTS}
        label={t('student')}
        icon={<IdCardIcon />}
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
        }}
      />
    </BottomNavigation>
  );
};

export default withStyles(styles)(
  withTranslation()(withRouter(observer(FhrBottomNavigation)))
);
