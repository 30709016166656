import classNames from 'classnames';
import hasListeners from 'event-emitter/has-listeners';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Edit from '@material-ui/icons/Edit';
import FileCopy from '@material-ui/icons/FileCopy';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Menu from '@material-ui/icons/Menu';
import Refresh from '@material-ui/icons/Refresh';

import {
  IWithEventEmitter,
  withEventEmitter,
} from '../../../core/services/withEventEmitter';
import RootStore from '../../../core/stores/RootStore';
import UiStore from '../../../core/stores/UiStore';
import DrivingExamPassedIcon from '../../icons/DrivingExamPassedIcon';
import TheoryExamPassedIcon from '../../icons/TheoryExamPassedIcon';
import FhrSearch from '../FhrSearch';
import styles from './styles';

export const APP_ICONS = {
  NONE: null,
  MENU: 1,
  ARROW_BACK: 2,
  EDIT: 3,
  CLOSE: 4,
  COPY: 5,
  ADD: 6,
  DRIVING_EXAM_PASSED: 7,
  THEORY_EXAM_PASSED: 8,
  REMOVE: 9,
  REFRESH: 10,
  INFO: 11,
} as const;

export type AppBarIcon = (typeof APP_ICONS)[keyof typeof APP_ICONS];

export interface AppBarRightIcon {
  icon: AppBarIcon;
  clickHandler?: () => void;
}

export enum APP_BAR_EVENTS {
  LEFT_ICON_CLICK = 'onClickLeftIcon',
  RIGHT_ICON_CLICK = 'onClickRightIcon',
  SECOND_RIGHT_ICON_CLICK = 'onClickSecondRightIcon',
  RIGHT_TEXT_CLICK = 'onClickRightText',
}

interface IProps
  extends WithTranslation<'common'>,
    WithStyles<typeof styles>,
    IWithEventEmitter {
  stores: RootStore;
  onClickLeftIcon: () => void;
  onClickRightIcon: () => void;
  onClickSecondRightIcon: () => void;
  onClickRightText: () => void;
}

export enum APP_BAR_CUSTOM_COLORS {
  GREEN_BLUE = 'green-blue',
}

class FhrAppBar extends React.Component<IProps> {
  ui: UiStore;

  constructor(props: IProps) {
    super(props);
    this.ui = this.props.stores.uiStore;
    this.onClickLeftIcon = this.onClickLeftIcon.bind(this);
    this.onClickRightIcon = this.onClickRightIcon.bind(this);
    this.onClickRightText = this.onClickRightText.bind(this);
  }

  onClickLeftIcon = () => {
    if (!hasListeners(this.props.ee, APP_BAR_EVENTS.LEFT_ICON_CLICK)) {
      if (this.props.onClickLeftIcon) {
        this.props.onClickLeftIcon();
      }
    } else {
      this.props.ee.emit(APP_BAR_EVENTS.LEFT_ICON_CLICK);
    }
  };

  onClickRightIcon = () => {
    if (!hasListeners(this.props.ee, APP_BAR_EVENTS.RIGHT_ICON_CLICK)) {
      if (this.props.onClickRightIcon) {
        this.props.onClickRightIcon();
      }
    } else {
      this.props.ee.emit(APP_BAR_EVENTS.RIGHT_ICON_CLICK);
    }
  };

  onClickSecondRightIcon = () => {
    if (!hasListeners(this.props.ee, APP_BAR_EVENTS.SECOND_RIGHT_ICON_CLICK)) {
      if (this.props.onClickSecondRightIcon) {
        this.props.onClickSecondRightIcon();
      }
    } else {
      this.props.ee.emit(APP_BAR_EVENTS.SECOND_RIGHT_ICON_CLICK);
    }
  };

  onClickRightText = () => {
    if (!hasListeners(this.props.ee, APP_BAR_EVENTS.RIGHT_TEXT_CLICK)) {
      if (this.props.onClickRightText) {
        this.props.onClickRightText();
      }
    } else {
      this.props.ee.emit(APP_BAR_EVENTS.RIGHT_TEXT_CLICK);
    }
  };

  renderLeftIcon(icon: number | null) {
    switch (icon) {
      case APP_ICONS.ARROW_BACK:
        return <ArrowBack />;
      case APP_ICONS.MENU:
        return <Menu />;
      case APP_ICONS.CLOSE:
        return <Close />;
      default:
        return null;
    }
  }

  renderRightIcon(icon: number | null) {
    switch (icon) {
      case APP_ICONS.EDIT:
        return <Edit />;
      case APP_ICONS.CLOSE:
        return <Close />;
      case APP_ICONS.COPY:
        return <FileCopy />;
      case APP_ICONS.ADD:
        return <Add />;
      case APP_ICONS.DRIVING_EXAM_PASSED:
        return <DrivingExamPassedIcon />;
      case APP_ICONS.THEORY_EXAM_PASSED:
        return <TheoryExamPassedIcon />;
      case APP_ICONS.REMOVE:
        return <DeleteForever />;
      case APP_ICONS.REFRESH:
        return <Refresh />;
      case APP_ICONS.INFO:
        return <InfoOutlined />;
      default:
        return null;
    }
  }

  render() {
    if (
      !this.ui.visibleStatusBar ||
      !this.props.stores.userStore.isAuthenticated
    ) {
      return null;
    }
    const { classes } = this.props;
    return (
      <AppBar
        className={classNames(
          classes.appBar,
          this.ui.currentEventType,
          this.ui.appBarCustomColor
        )}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={this.onClickLeftIcon}
            disabled={!this.ui.leftIcon}
          >
            {this.renderLeftIcon(this.ui.leftIcon)}
          </IconButton>
          <Typography
            variant="h6"
            color="inherit"
            noWrap={true}
            className={classes.appBarTitle}
          >
            {this.ui.visibleStatusBar ? this.ui.title : null}
          </Typography>
          {this.ui.search && <FhrSearch />}
          {this.ui.rightIcon && (
            <IconButton
              color="inherit"
              onClick={this.onClickRightIcon}
              disabled={!this.ui.rightIcon}
            >
              {this.renderRightIcon(this.ui.rightIcon)}
            </IconButton>
          )}

          {this.ui.secondRightIcon && (
            <IconButton
              color="inherit"
              onClick={this.onClickSecondRightIcon}
              disabled={!this.ui.secondRightIcon}
            >
              {this.renderRightIcon(this.ui.secondRightIcon)}
            </IconButton>
          )}

          {this.ui.appBarRightIcons.length > 0 &&
            this.ui.appBarRightIcons.map(({ icon, clickHandler }) => (
              <IconButton
                key={icon || ''}
                color="inherit"
                onClick={clickHandler}
                disableRipple={!clickHandler}
                className={classNames(
                  classes.iconButton,
                  !clickHandler && 'inactive'
                )}
              >
                {this.renderRightIcon(icon)}
              </IconButton>
            ))}

          {this.ui.rightText && (
            <Button color="inherit" onClick={this.onClickRightText}>
              {this.ui.rightText}
            </Button>
          )}
        </Toolbar>
        {this.ui.renderAppBarBottom}
      </AppBar>
    );
  }
}

export default withEventEmitter(
  withStyles(styles)(withTranslation()(inject('stores')(observer(FhrAppBar))))
);
