import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    actionItem: {
      color: '#929292',
      '&$selected': {
        color: theme.palette.secondary.main,
      },
    },
    rootNav: {
      position: 'fixed',
      bottom: 0,
      width: '100vw',
      backgroundColor: theme.palette.common.white,
      zIndex: 100,
      height: theme.dimensions.NAVIGATION_HEIGHT_SM,
      borderTop: '1px solid #ddddde',
    },
    selected: {
      color: theme.palette.secondary.main,
    },
    actionItemIcon: {
      width: 30,
      height: 30,
    },
  });

export default styles;
