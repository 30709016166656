import { inject, observer } from 'mobx-react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import RootStore from 'src/core/stores/RootStore';

import Button from '@material-ui/core/Button';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, WithTranslation<'common'> {
  stores?: RootStore;
}

class MaintenanceMode extends React.Component<Props> {
  componentDidMount() {
    this.props.stores!.uiStore.setVisibleStatusBar(false);
    this.props.stores!.uiStore.setVisibleBottomNavigation(false);
  }

  componentWillUnmount() {
    this.props.stores?.uiStore.setVisibleStatusBar(true);
    this.props.stores?.uiStore.setVisibleBottomNavigation(true);
  }

  updateStatus = () => {
    this.props.stores?.applicationStore.updateMaintenanceStatus();
  };

  render(): React.ReactNode {
    const { t, classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography className={classes.message}>
          {t('maintenance_mode_message')}
        </Typography>
        <Button onClick={this.updateStatus} variant="outlined" color="primary">
          {t('update')}
        </Button>
      </div>
    );
  }
}

export default withTranslation()(
  withStyles(styles)(inject('stores')(observer(MaintenanceMode)))
);
