import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { EVENT_TYPE } from 'src/core/entities/ScheduleEvent';

import asyncComponent from './AsyncComponent';
import MaintenanceMode from './components/views/MaintenanceMode';
import RootStore from './core/stores/RootStore';
import { PrivateRoute } from './PrivateRoute';
import { ROUTES } from './routes';

const AsyncHome = asyncComponent(() => import('./components/views/Home'));
const AsyncLogin = asyncComponent(() => import('./components/views/Login'));
const AsyncNoMatch = asyncComponent(() => import('./components/views/NoMatch'));
const AsyncInstructorProfile = asyncComponent(
  () => import('./components/views/InstructorProfile')
);
const AsyncEventSchedule = asyncComponent(
  () => import('./components/views/EventSchedule')
);
const AsyncAddNewSlot = asyncComponent(
  () => import('./components/views/AddNewSlot')
);
const AsyncPickupPointsList = asyncComponent(
  () => import('./components/views/PickupPointsList')
);
const AsyncNotMaintainedLessons = asyncComponent(
  () => import('./components/views/NotMaintainedLessons')
);

const AsyncTheoryLesson = asyncComponent(
  () => import('./components/views/TheoryLesson')
);
const AsyncAttendance = asyncComponent(
  () => import('./components/views/Attendance')
);
const AsyncAttendanceImages = asyncComponent(
  () => import('./components/views/AttendanceImages')
);

const AsyncDrivingLesson = asyncComponent(
  () => import('./components/views/DrivingLesson')
);
const AsyncEditDrivingLesson = asyncComponent(
  () => import('./components/views/EditDrivingLesson')
);
const AsyncDrivingLessonTypes = asyncComponent(
  () => import('./components/views/DrivingLessonTypes')
);

const AsyncEventDetails = asyncComponent(
  () => import('./components/views/EventDetails')
);
const AsyncEditScheduleEvent = asyncComponent(
  () => import('./components/views/EditScheduleEvent')
);

const AsyncStudentList = asyncComponent(
  () => import('./components/views/Studentlist')
);
const AsyncStudentComponent = asyncComponent(
  () => import('./components/views/Student')
);
const AsyncBookedLessons = asyncComponent(
  () => import('./components/views/BookedLessons')
);

const AsyncPracticeProtocol = asyncComponent(
  () => import('./components/views/PracticeProtocol')
);

const AsyncInstructorComments = asyncComponent(
  () => import('./components/views/InstructorComments')
);

const AsyncDLMDrivingLesson = asyncComponent(
  () => import('./components/views/DLMDrivingLesson')
);

const AsyncEditDLMDrivingLesson = asyncComponent(
  () => import('./components/views/EditDLMDrivingLesson')
);

const AsyncDLMDrivingLessonTypes = asyncComponent(
  () => import('./components/views/DLMDrivingLessonTypes')
);

const routes = [
  {
    path: ROUTES.HOME,
    component: AsyncHome,
    exact: true,
  },
  {
    path: ROUTES.INSTRUCTOR_PROFILE,
    component: AsyncInstructorProfile,
    exact: true,
  },
  {
    path: ROUTES.EVENT_SCHEDULE,
    component: AsyncEventSchedule,
    exact: true,
  },
  {
    path: ROUTES.NOT_MAINTAINED_LESSONS,
    component: AsyncNotMaintainedLessons,
    exact: true,
  },
  {
    path: ROUTES.ADD_NEW_SLOT,
    component: AsyncAddNewSlot,
    exact: true,
  },
  {
    path: [
      ROUTES.ADD_NEW_SLOT_PICKUP_POINTS,
      ROUTES.DRIVING_LESSON_PICKUP_POINTS,
      ROUTES.PREFERRED_PICK_UP_POINT,
      ROUTES.DLM_DRIVING_LESSON_PICKUP_POINTS,
      ROUTES.PREFERRED_DLM_PICK_UP_POINT,
    ],
    component: AsyncPickupPointsList,
    exact: true,
  },
  {
    path: ROUTES[EVENT_TYPE.THEORY_LESSON],
    component: AsyncTheoryLesson,
    exact: true,
  },
  {
    path: ROUTES.THEORY_LESSON_ATTENDANCE,
    component: AsyncAttendance,
    exact: true,
  },
  {
    path: ROUTES.THEORY_LESSON_ATTENDANCE_IMAGES,
    component: AsyncAttendanceImages,
    exact: true,
  },
  {
    path: ROUTES[EVENT_TYPE.DRIVING_LESSON],
    component: AsyncDrivingLesson,
    exact: true,
  },
  {
    path: ROUTES.EDIT_DRIVING_LESSON,
    component: AsyncEditDrivingLesson,
    exact: true,
  },
  {
    path: ROUTES.DRIVING_LESSON_TYPES,
    component: AsyncDrivingLessonTypes,
    exact: true,
  },
  {
    path: [
      ROUTES.PRACTICE_PROTOCOL,
      ROUTES.STUDENT_PRACTICE_PROTOCOL,
      ROUTES.DRIVING_EXAM_PRACTICE_PROTOCOL,
      ROUTES.DLM_PRACTICE_PROTOCOL,
    ],
    component: AsyncPracticeProtocol,
    exact: true,
  },
  {
    path: [
      ROUTES.STUDENT_COMMENTS,
      ROUTES.DRIVING_LESSON_INSTRUCTOR_COMMENTS,
      ROUTES.DRIVING_EXAM_INSTRUCTOR_COMMENTS,
      ROUTES.DLM_DRIVING_LESSON_INSTRUCTOR_COMMENTS,
    ],
    component: AsyncInstructorComments,
    exact: true,
  },
  {
    path: ROUTES.DLM_DRIVING_LESSON,
    component: AsyncDLMDrivingLesson,
    exact: true,
  },
  {
    path: ROUTES.EDIT_DLM_DRIVING_LESSON,
    component: AsyncEditDLMDrivingLesson,
    exact: true,
  },
  {
    path: ROUTES.DLM_DRIVING_LESSON_TYPES,
    component: AsyncDLMDrivingLessonTypes,
    exact: true,
  },
  {
    path: [
      ROUTES[EVENT_TYPE.DRIVING_EXAM],
      ROUTES[EVENT_TYPE.MEETING],
      ROUTES[EVENT_TYPE.OFFICE_ACTIVITY],
      ROUTES[EVENT_TYPE.TRAINING],
      ROUTES[EVENT_TYPE.VACATION],
      ROUTES[EVENT_TYPE.SICKNESS],
      ROUTES[EVENT_TYPE.BLOCKER],
      ROUTES[EVENT_TYPE.MISC],
    ],
    component: AsyncEventDetails,
    exact: true,
  },
  {
    path: [
      ROUTES.EDIT_EVENT[EVENT_TYPE.BLOCKER],
      ROUTES.EDIT_EVENT[EVENT_TYPE.MEETING],
      ROUTES.EDIT_EVENT[EVENT_TYPE.OFFICE_ACTIVITY],
      ROUTES.EDIT_EVENT[EVENT_TYPE.TRAINING],
      ROUTES.EDIT_EVENT[EVENT_TYPE.MISC],
    ],
    component: AsyncEditScheduleEvent,
    exact: true,
  },
  {
    path: [
      ROUTES.STUDENTS_LIST,
      ROUTES.ADD_NEW_SLOT_STUDENTS,
      ROUTES.DRIVING_LESSON_STUDENTS,
      ROUTES.THEORY_LESSON_ADD_STUDENT,
      ROUTES.DLM_DRIVING_LESSON_STUDENTS,
    ],
    component: AsyncStudentList,
    exact: true,
  },
  {
    path: [
      ROUTES.STUDENT_PAGE,
      ROUTES.DRIVING_LESSON_STUDENT,
      ROUTES.DLM_DRIVING_LESSON_STUDENT,
    ],
    component: AsyncStudentComponent,
    exact: true,
  },
  {
    path: ROUTES.BOOKED_LESSONS,
    component: AsyncBookedLessons,
    exact: true,
  },
  {
    path: '',
    component: AsyncNoMatch,
    exact: false,
  },
];

interface IProps {
  stores?: RootStore;
}

@inject('stores')
@observer
class RoutesComponent extends React.Component<IProps> {
  // eslint-disable-next-line no-confusing-arrow
  renderLogin = (): React.ReactNode =>
    this.props.stores!.userStore.isAuthenticated ? (
      <Redirect to={{ pathname: '/' }} />
    ) : (
      <AsyncLogin />
    );

  render() {
    if (!this.props.stores?.applicationStore.maintenanceModeChecked) {
      return null;
    }

    if (this.props.stores?.applicationStore.maintenanceMode) {
      return <MaintenanceMode />;
    }

    return (
      <Switch>
        <Route path={ROUTES.LOGIN} exact={true} render={this.renderLogin} />
        {routes.map((r) => (
          <PrivateRoute
            isAuthenticated={this.props.stores!.userStore.isAuthenticated}
            key={r.path.toString()}
            path={r.path}
            component={r.component}
            exact={r.exact}
          />
        ))}
      </Switch>
    );
  }
}

export default RoutesComponent;
