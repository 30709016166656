import { observer } from 'mobx-react';
import * as React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';

import { ROUTES } from './routes';

interface IProps extends RouteProps {
  isAuthenticated: boolean;
}

export const PrivateRoute: React.FunctionComponent<IProps> = ({
  isAuthenticated,
  component,
  ...rest
}) => {
  if (!component) {
    throw Error('component is undefined');
  }

  const Component = component; // JSX Elements have to be uppercase.
  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    if (isAuthenticated) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component {...props} />;
    }

    return <Redirect to={{ pathname: ROUTES.LOGIN }} />;
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} render={render} />;
};

export default observer(PrivateRoute);
