import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import uniqBy from 'lodash/uniqBy';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { AttendanceImage } from 'src/core/entities/AttendanceImage';
import {
  Attendee,
  ATTENDEE_TYPE,
  ATTENDEES_TYPES,
  CreateStudentAttendeeData,
} from 'src/core/entities/Attendee';
import {
  CreateDrivingLessonTypeData,
  DrivingLessonType,
  DrivingType,
  UpdateDrivingLessonTypeData,
} from 'src/core/entities/DrivingLessonType';
import { Instructor } from 'src/core/entities/Instructor';
import {
  CreateDrivingLessonData,
  CreateScheduleEventData,
  EDITABLE_EVENTS_TYPES,
  EVENT_TYPE,
  ScheduleEvent,
  ScheduleEventRequestParams,
  UpdateDrivingLessonData,
  UpdateScheduleEventData,
} from 'src/core/entities/ScheduleEvent';
import { Student } from 'src/core/entities/Student';

import { DateString, Result } from '../../types';
import {
  getCurrentDate,
  getEventDuration,
  getEventEndDate,
  getMinuteStart,
  isSameMinuteDates,
} from '../../utils/time';
import { AttendanceImagesAPI } from '../api/TachoAPI/AttendanceImagesAPI';
import { AttendeesAPI } from '../api/TachoAPI/AttendeesAPI';
import { DrivingLessonTypesAPI } from '../api/TachoAPI/DrivingLessonTypesAPI';
import { ScheduleEventsAPI } from '../api/TachoAPI/ScheduleEventsAPI';
import {
  addTachoCalendarEvent,
  updateTachoCalendarEvent,
} from '../entities/CalendarScheduleEvent';
import RootStore from './RootStore';

export interface INewDrivingLessonData {
  startDate: Date;
  duration: number;
  instructor?: Instructor | null;
  pickUpPoint?: { id: string };
  student?: Student;
  categoryId?: string;
}

export interface IDrivingLessonUpdateData {
  startDate?: Date;
  endDate?: Date;
}

export interface IEventUpdateData {
  startDate?: Date;
  endDate?: Date;
  description?: string;
  drivingLessonTypes?: [];
  category?: string | null;
}

export type EventForUpdate = {
  id: string;
  startDate: Date | DateString;
  endDate: Date | DateString;
  student?: { id: string; student?: { id: string } | null };
  duration: number;
  drivingLessonTypes?: DrivingLessonType[];
  categoryId?: string;
  pickUpPointId?: string;
  description?: string;
};

class EventScheduleStore {
  @observable public eventSchedule: IObservableArray<ScheduleEvent>;
  @observable public fetchingEventsIds: IObservableArray<string>;
  @observable public failedFetchingEventsIds: IObservableArray<string>;

  constructor(
    private readonly rootStore: RootStore,
    private readonly scheduleEventsAPI: ScheduleEventsAPI,
    private readonly attendeesAPI: AttendeesAPI,
    private readonly drivingLessonTypesAPI: DrivingLessonTypesAPI,
    private readonly attendanceImagesAPI: AttendanceImagesAPI
  ) {
    makeObservable(this);

    this.eventSchedule = observable.array();
    this.fetchingEventsIds = observable.array();
    this.failedFetchingEventsIds = observable.array();
  }

  public getScheduleEventById(eventId: string): ScheduleEvent | undefined {
    const event = this.eventSchedule.find(({ id }) => id === eventId);
    if (!event) {
      this.loadScheduleEventById(eventId);
    }
    return event;
  }

  public reloadScheduleEventData(eventId: string): Promise<void> {
    return this.loadScheduleEventById(eventId);
  }

  public findScheduleEventById(eventId: string): ScheduleEvent | undefined {
    return this.eventSchedule.find(({ id }) => id === eventId);
  }

  public async reloadWebinarData(eventId: string): Promise<void> {
    const webinarAttendees = await this.loadWebinarAttendees(eventId);

    this.findScheduleEventById(eventId)?.updateWebinarAttendees(
      webinarAttendees
    );
  }

  @action
  addScheduleEvent(event: ScheduleEvent): void {
    this.eventSchedule.replace(uniqBy([event, ...this.eventSchedule], 'id'));
  }

  public async checkIfEditable(eventId?: string): Promise<boolean> {
    const event = eventId && this.findScheduleEventById(eventId);
    if (!event) {
      return false;
    }

    const editableType = EDITABLE_EVENTS_TYPES.includes(event.type);

    if (!editableType) {
      return false;
    }

    await when(() => Boolean(this.currentInstructor));

    return event.instructorId === this.currentInstructor?.id;
  }

  @action
  public async removeDrivingLesson(lessonId: string): Promise<Result> {
    return this.removeScheduleEvent(lessonId);
  }

  @action
  public async removeEvent(event: ScheduleEvent): Promise<Result> {
    if (!this.currentInstructor) {
      return { success: false };
    }

    if (event.instructorId !== this.currentInstructor?.id) {
      return { success: false };
    }

    return this.removeScheduleEvent(event.id);
  }

  @action
  public async setDrivingLessonType(
    drivingLesson: ScheduleEvent,
    {
      index,
      value,
    }: {
      index: number;
      value: DrivingType;
    }
  ): Promise<Result> {
    const existType = drivingLesson.drivingLessonTypes.find(
      ({ id, indexNumber }) => id && indexNumber === index
    );

    if (existType) {
      existType.type = value;
    } else {
      const type: DrivingLessonType = {
        id: '',
        indexNumber: index,
        type: value,
      };

      if (drivingLesson.drivingLessonTypes) {
        drivingLesson.drivingLessonTypes = [
          type,
          ...drivingLesson.drivingLessonTypes,
        ];
      }
    }

    if (existType) {
      const data: UpdateDrivingLessonTypeData = {
        type: value,
      };

      const updateDrivingTypeResult = await this.drivingLessonTypesAPI.update(
        existType.id,
        data
      );

      return updateDrivingTypeResult;
    }

    const payload: CreateDrivingLessonTypeData = {
      indexNumber: index,
      type: value,
      drivingLesson: drivingLesson.id,
    };

    const createDrivingLessonTypeResult =
      await this.drivingLessonTypesAPI.create(payload);

    if (createDrivingLessonTypeResult.success) {
      const newDrivingType = createDrivingLessonTypeResult.data;

      drivingLesson.drivingLessonTypes = uniqBy(
        [newDrivingType, ...drivingLesson.drivingLessonTypes],
        'indexNumber'
      );
    }

    return createDrivingLessonTypeResult;
  }

  @action
  public async updateDrivingLesson(
    lesson: EventForUpdate,
    lessonData: IDrivingLessonUpdateData
  ): Promise<Result> {
    const drivingLessonUpdateData: UpdateDrivingLessonData =
      this.filterEventUpdateData(lesson, lessonData);

    if (lessonData.startDate && lessonData.endDate) {
      const newDuration = getEventDuration(
        lessonData.startDate,
        lessonData.endDate
      );

      if (newDuration < lesson.duration && lesson.drivingLessonTypes?.length) {
        drivingLessonUpdateData.drivingLessonTypes = [];
      }
    }

    return this.updateScheduleEvent(lesson.id, drivingLessonUpdateData);
  }

  public filterEventUpdateData(
    event: EventForUpdate,
    eventData: IEventUpdateData
  ): UpdateScheduleEventData {
    const updateData: IEventUpdateData = {};

    if (
      eventData.startDate &&
      event.startDate &&
      !isSameMinuteDates(eventData.startDate, event.startDate)
    ) {
      updateData.startDate = getMinuteStart(eventData.startDate);
    }

    if (
      eventData.endDate &&
      event.endDate &&
      !isSameMinuteDates(eventData.endDate, event.endDate)
    ) {
      updateData.endDate = getMinuteStart(eventData.endDate);
    }

    if (eventData.description !== event.description) {
      updateData.description = eventData.description;
    }

    if (event.categoryId !== eventData.category) {
      updateData.category = eventData.category;
    }

    return updateData;
  }

  public async markAsAbsent(
    eventId: string,
    attendeeId: string
  ): Promise<Result> {
    const result = await this.attendeesAPI.markAsAbsent(attendeeId);

    if (result.success) {
      await this.loadScheduleEventById(eventId);
    }

    return result;
  }

  @action
  public async toggleAttendeePresence(
    lessonId: string,
    attendeeId: string,
    webinar?: boolean
  ): Promise<Result> {
    const result = await this.attendeesAPI.togglePresent(attendeeId);

    if (result.success) {
      if (webinar) {
        this.reloadWebinarData(lessonId);

        if (result.data.presentAt) {
          this.rootStore.manualPresenceStore.add(lessonId, attendeeId);
        } else {
          this.rootStore.manualPresenceStore.remove(lessonId, attendeeId);
        }
      } else {
        await this.loadScheduleEventById(lessonId);
      }
    }

    return result;
  }

  @action
  public async approveAttendanceImage(image: AttendanceImage): Promise<void> {
    const result = await this.attendanceImagesAPI.approve(image.id);

    if (result.success) {
      image.approvedAt = getCurrentDate().toISOString();
      image.rejectedAt = null;
    }
  }

  @action
  public async rejectAttendanceImage(image: AttendanceImage): Promise<void> {
    const result = await this.attendanceImagesAPI.reject(image.id);

    if (result.success) {
      image.rejectedAt = getCurrentDate().toISOString();
      image.approvedAt = null;
    }
  }

  async addTheoryLessonStudent(
    lessonId: string,
    studentId: string
  ): Promise<Result> {
    const data: CreateStudentAttendeeData = {
      scheduleEvent: lessonId,
      [ATTENDEES_TYPES.STUDENT]: studentId,
    };

    const result = await this.attendeesAPI.create(data);

    if (result.success) {
      const newAttendee = result.data;
      const lesson = this.getScheduleEventById(lessonId);

      runInAction(() => {
        lesson?.addAttendee(newAttendee);
      });

      return this.toggleAttendeePresence(lessonId, newAttendee.id);
    }

    return result;
  }

  @action
  async removeDrivingLessonStudent(
    lessonId: string,
    attendeeId: string
  ): Promise<Result> {
    const result = await this.attendeesAPI.delete(attendeeId);

    if (result.success) {
      await this.loadScheduleEventById(lessonId);
    }

    return result;
  }

  @action
  public async createDrivingLesson(
    eventData: INewDrivingLessonData
  ): Promise<Result> {
    const { startDate, duration, pickUpPoint, student, categoryId } = eventData;

    const eventStartDate = getMinuteStart(startDate);

    const endDate = getEventEndDate(eventStartDate, Number(duration));

    const instructorAttendee = {
      [ATTENDEE_TYPE.INSTRUCTOR]: this.currentInstructor!.id,
    };
    const studentAttendee = student && {
      [ATTENDEE_TYPE.STUDENT]: student.id,
    };
    const attendees = [instructorAttendee, studentAttendee].filter(Boolean);

    const resource = pickUpPoint && {
      pickUpPoint: pickUpPoint.id,
    };

    const newDrivingLessonData: CreateDrivingLessonData = {
      attendees: attendees as CreateDrivingLessonData['attendees'],
      resource,
      endDate,
      startDate: eventStartDate,
      type: EVENT_TYPE.DRIVING_LESSON,
      category: categoryId,
    };

    return this.createScheduleEvent(newDrivingLessonData);
  }

  public async getWebinarHostUrl(eventId: string): Promise<string> {
    const instructorAttendee =
      this.findScheduleEventById(eventId)?.instructorAttendee;

    if (
      !instructorAttendee ||
      instructorAttendee.instructor.id !== this.currentInstructor?.id
    ) {
      return '';
    }

    const result = await this.attendeesAPI.getWebinarHostUrl(
      instructorAttendee.id
    );

    return result.success ? result.data.start_url : '';
  }

  @computed
  private get currentInstructor(): Instructor | undefined | null {
    return this.rootStore.instructorsStore.currentInstructor;
  }

  // API requests
  @action
  private async loadScheduleEventById(
    eventId: string,
    params?: ScheduleEventRequestParams
  ): Promise<void> {
    runInAction(() => {
      this.fetchingEventsIds.push(eventId);
    });

    const result = await this.scheduleEventsAPI.fetchById(eventId, params);

    if (result.success) {
      runInAction(() => {
        this.fetchingEventsIds.remove(eventId);
      });

      const event = ScheduleEvent.fromTachoData(result.data);

      updateTachoCalendarEvent(result.data);

      runInAction(() => {
        this.eventSchedule.replace(
          uniqBy([event, ...this.eventSchedule], 'id')
        );
      });
      if (this.failedFetchingEventsIds.includes(eventId)) {
        runInAction(() => {
          this.failedFetchingEventsIds.remove(eventId);
        });
      }
    } else {
      runInAction(() => {
        this.failedFetchingEventsIds.push(eventId);
      });
    }
  }

  @action
  async loadWebinarAttendees(eventId: string): Promise<Attendee[]> {
    const params: ScheduleEventRequestParams = {
      groups: ['api:attendance_image:read', 'api:student:event', 'api:id'],
    };

    const result = await this.scheduleEventsAPI.fetchById(eventId, params);

    if (result.success) {
      return result.data.attendees;
    }

    return [];
  }

  @action
  private async createScheduleEvent(
    eventData: CreateScheduleEventData
  ): Promise<Result> {
    const result = await this.scheduleEventsAPI.create(
      pickBy(eventData, identity) as CreateScheduleEventData
    );

    if (result.success) {
      const newEvent = ScheduleEvent.fromTachoData(result.data);

      addTachoCalendarEvent(result.data);

      runInAction(() => {
        this.eventSchedule.push(newEvent);
      });
    }

    return result;
  }

  @action
  private async updateScheduleEvent(
    eventId: string,
    eventData: UpdateScheduleEventData
  ): Promise<Result> {
    const result = await this.scheduleEventsAPI.update(eventId, eventData);

    if (result.success) {
      const updatedEvent = ScheduleEvent.fromTachoData(result.data);

      updateTachoCalendarEvent(result.data);

      runInAction(() => {
        this.eventSchedule.replace(
          uniqBy([updatedEvent, ...this.eventSchedule], 'id')
        );
      });
    }

    return result;
  }

  @action
  private async removeScheduleEvent(eventId: string): Promise<Result> {
    const result = await this.scheduleEventsAPI.delete(eventId);

    if (result.success) {
      runInAction(() => {
        this.eventSchedule.replace(
          this.eventSchedule.filter(({ id }) => id !== eventId)
        );
      });
    }

    return result;
  }
}

export default EventScheduleStore;
