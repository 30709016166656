import { sortEventsByDate } from '../ScheduleEvent.utils';
import { useDLMTodayScheduleEventsQuery } from './useDLMTodayScheduleEventsQuery';
import { useTachoTodayScheduleEventsQuery } from './useTachoTodayScheduleEventsQuery';

export const useTodayScheduleEventsQuery = (
  instructorId: string | undefined,
  dlmServiceEnabled: boolean
) => {
  const tachoQuery = useTachoTodayScheduleEventsQuery(instructorId);
  const dlmQuery = useDLMTodayScheduleEventsQuery(dlmServiceEnabled);

  const data = sortEventsByDate([...tachoQuery.data, ...dlmQuery.data]);

  return {
    data,
  };
};
