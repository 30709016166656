import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: 8,
    },
    titleText: {
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
    messageText: {
      fontSize: 14,
      color: theme.palette.text.primary,
      textAlign: 'center',

      '&.error': {
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.secondary.main,
      },

      '&.success': {
        fontSize: 16,
        fontWeight: 600,
        color: theme.greenBlue.color,
      },
    },
    action: {
      borderTop: '1px solid #707070',
      margin: 0,
      padding: '8px 4px',
      justifyContent: 'center',
    },
  });

export default styles;
