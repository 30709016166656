import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import {
  SunriseSunsetRequestParams,
  SunriseSunsetResponseData,
} from 'src/core/entities/School';

import { DateString, LoadingStatus } from '../../types';
import {
  addDaysToDate,
  formatDate,
  getDate,
  getDayStart,
  getWeekEnd,
  getWeekStart,
} from '../../utils/time';
import { SchoolsAPI } from '../api/TachoAPI/SchoolsAPI';
import RootStore from './RootStore';

interface ILoadingStatus {
  [weekStartDate: string]: LoadingStatus;
}

class TimesStore {
  @observable dates: {
    [key: string]: SunriseSunsetResponseData;
  };

  @observable loadingStatus: ILoadingStatus;

  constructor(
    private readonly rootStore: RootStore,
    private readonly schoolsAPI: SchoolsAPI
  ) {
    makeObservable(this);

    this.dates = {};
    this.loadingStatus = {};
  }

  @computed
  get schoolId(): string | undefined {
    const schools = this.rootStore.userStore.currentInstructor?.schools || [];

    return schools?.find(({ enabled }) => enabled)?.id || schools[0]?.id;
  }

  @computed
  get currentWorkWeekStart(): Date {
    return getWeekStart(getDate(this.rootStore.uiStore.currentCalendarDate));
  }

  @computed
  get currentWorkWeekEnd(): Date {
    return addDaysToDate(getWeekEnd(this.currentWorkWeekStart), -1);
  }

  @computed
  get timesLoading(): boolean {
    if (!this.currentWorkWeekStart) {
      return false;
    }

    return (
      this.loadingStatus[this.getKey(this.currentWorkWeekStart)] ===
      LoadingStatus.Loading
    );
  }

  getKey(date: Date | DateString): string {
    return formatDate(getDayStart(date));
  }

  getSunsetSunriseTimes(
    date: Date | DateString
  ): SunriseSunsetResponseData | undefined {
    return this.dates[this.getKey(date)];
  }

  getSunrise(date: Date | DateString): DateString | undefined {
    return this.dates[this.getKey(date)]?.sunrise;
  }

  getSunset(date: Date | DateString): DateString | undefined {
    return this.dates[this.getKey(date)]?.sunset;
  }

  @action
  async loadCurrentWorkWeekTimes(): Promise<void> {
    if (!this.schoolId) {
      await when(() => Boolean(this.schoolId));
    }

    const loadingStatusKey = this.getKey(this.currentWorkWeekStart);

    if (
      [LoadingStatus.Loading, LoadingStatus.Loaded].includes(
        this.loadingStatus[loadingStatusKey]
      )
    ) {
      return;
    }

    this.loadingStatus[loadingStatusKey] = LoadingStatus.Loading;

    const params: SunriseSunsetRequestParams = {
      startDate: this.currentWorkWeekStart,
      endDate: this.currentWorkWeekEnd,
    };

    const result = await this.schoolsAPI.fetchSunriseSunsetTimes(
      this.schoolId!,
      params
    );

    if (result.success) {
      const newDates = result.data.reduce((acc, time) => {
        const date = time.sunrise;
        acc[this.getKey(date)] = time;

        return acc;
      }, {});

      runInAction(() => {
        this.dates = { ...this.dates, ...newDates };
        this.loadingStatus[loadingStatusKey] = LoadingStatus.Loaded;
      });
    } else {
      runInAction(() => {
        this.loadingStatus[loadingStatusKey] = LoadingStatus.Failed;
      });
    }
  }
}

export default TimesStore;
