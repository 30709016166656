import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon';

interface IProps {
  className?: string;
}

const DrivingExamPassedIcon: React.FunctionComponent<IProps> = ({
  className,
}) => (
  <SvgIcon
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-3 -3 27 22"
  >
    <path d="M18.051 16.382H16.99a1.045 1.045 0 0 1-1.062-1.024v-1.023H3.186v1.023a1.045 1.045 0 0 1-1.062 1.024H1.062A1.045 1.045 0 0 1 0 15.358V7.167l2.208-6.133A1.586 1.586 0 0 1 3.716 0H15.4a1.585 1.585 0 0 1 1.5 1.034l2.208 6.133v8.191a1.045 1.045 0 0 1-1.057 1.024zM5.859 5.866a.5.5 0 0 0-.351.141l-.7.678a.468.468 0 0 0 0 .678l3.234 3.118a.51.51 0 0 0 .7 0l5.72-5.516a.467.467 0 0 0 0-.678l-.7-.678a.51.51 0 0 0-.7 0l-4.665 4.5-2.178-2.1a.5.5 0 0 0-.36-.143z" />
  </SvgIcon>
);

export default DrivingExamPassedIcon;
