import 'moment/locale/de';

import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import momentTimezone from 'moment-timezone';
import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Router } from 'react-router';
import { AppAPI, DLMServiceAPI, IspaAPI, TachoAPI } from 'src/core/api';

import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Layout from './components/views/Layout';
import Notifications from './components/views/Notifications';
import { DEFAULT_TIME_ZONE } from './constants';
import IndexedDBService from './core/services/IndexedDBService';
import RootStore from './core/stores/RootStore';
import history from './history';
import RoutesComponent from './RoutesComponent';
import theme from './theme';
import i18n from './translations/i18';

configure({ enforceActions: 'never' });

momentTimezone.locale('de');
momentTimezone.tz.setDefault(DEFAULT_TIME_ZONE);

const localDB = new IndexedDBService();

export const appAPI = new AppAPI();
export const tachoAPI = new TachoAPI(localDB);
export const ispaAPI = new IspaAPI();
export const dlmAPI = new DLMServiceAPI();

const rootStore: RootStore = new RootStore(
  appAPI,
  ispaAPI,
  tachoAPI,
  dlmAPI,
  localDB
);

history.listen(rootStore.applicationStore.locationListener);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
    mutations: {
      retry: 1,
    },
  },
});

const App: React.FunctionComponent = () => (
  <I18nextProvider i18n={i18n}>
    <Provider stores={rootStore}>
      <Router history={history}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <CssBaseline />
              <Notifications />

              <Layout>
                <RoutesComponent />
              </Layout>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </QueryClientProvider>
      </Router>
    </Provider>
  </I18nextProvider>
);

export default App;
