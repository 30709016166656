import { computed, makeObservable } from 'mobx';

import { PracticeProtocolData } from './PracticeProtocol.types';
import { PracticeProtocolCategory } from './PracticeProtocolCategory.model';
import { PracticeProtocolItem } from './PracticeProtocolItem.model';

export class PracticeProtocol {
  readonly categories: PracticeProtocolCategory[];

  constructor({ categories }: PracticeProtocolData) {
    makeObservable(this, {
      changedItems: computed,
      allExpanded: computed,
    });

    this.categories = categories.map(
      (categoryData) => new PracticeProtocolCategory(categoryData)
    );
  }

  get changedItems(): PracticeProtocolItem[] {
    return this.categories.reduce((acc, category) => {
      const categoryChangedItems = category.items.filter(
        (item) => item.changed
      );

      return [...acc, ...categoryChangedItems];
    }, []);
  }

  get allExpanded(): boolean {
    return Boolean(
      this.categories.length &&
        this.categories.every((category) => category.expanded)
    );
  }

  toggleAllCategories = (): void => {
    const { allExpanded } = this;
    this.categories.forEach((category) => {
      category.setExpanded(!allExpanded);
    });
  };
}
