import { BaseAPI } from 'src/core/api/BaseAPI';
import IndexedDBService from 'src/core/services/IndexedDBService';
import { RequestResult } from 'src/types';
import {
  InstructorData,
  UpdateInstructorData,
} from 'src/core/entities/Instructor';

const endpoints = {
  fetchById: (instructorId: string) => `/api/v1/instructors/${instructorId}`,
  update: (instructorId: string) => `/api/v1/instructors/${instructorId}`,
};

export class InstructorsAPI {
  constructor(
    private readonly baseApi: BaseAPI,
    private readonly localDB: IndexedDBService
  ) {}

  public async fetchById(
    instructorId: string
  ): Promise<RequestResult<InstructorData>> {
    const localDBRequestResult = await this.localDB.getCurrentInstructor();

    if (localDBRequestResult.success) {
      return localDBRequestResult;
    }

    const result = await this.baseApi.get<InstructorData>(
      endpoints.fetchById(instructorId)
    );

    if (result.success) {
      this.localDB.setCurrentInstructor(result.data);
    }

    return result;
  }

  public async update(
    instructorId: string,
    data: UpdateInstructorData
  ): Promise<RequestResult<InstructorData>> {
    const result = await this.baseApi.put<InstructorData, UpdateInstructorData>(
      endpoints.update(instructorId),
      data
    );

    if (result.success) {
      this.localDB.updateCurrentInstructor(result.data);
    }

    return result;
  }
}
