import { dlmAPI } from 'src/App';

import { useQuery } from '@tanstack/react-query';
import { LessonDefinitionParams } from '../LessonDefinition';

export const useDLMDrivingLessonDefinitionsQuery = (
  params?: LessonDefinitionParams
) =>
  useQuery({
    queryKey: [
      {
        api: 'dlm',
        scope: 'driving lesson definitions',
        ...(params?.studentEducationId && params),
      },
    ],
    queryFn: async () => {
      const result = await dlmAPI.lessonDefinitionsAPI.fetchAll(params);
      if (result.success) {
        return result.data.sort((a, b) => a.label.localeCompare(b.label));
      }

      return [];
    },
  });
