import { identity, pickBy } from 'lodash';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { ATTENDEE_TYPE } from 'src/core/entities/Attendee';
import { EventSlot, NEW_SLOT_TYPES } from 'src/core/entities/EventSlot';
import { Instructor } from 'src/core/entities/Instructor';
import {
  CreateDrivingLessonData,
  CreateGenericEventData,
  CreateScheduleEventData,
  CreateSicknessData,
  CreateVacationData,
  EVENT_TYPE,
  ScheduleEvent,
  ScheduleEventData,
} from 'src/core/entities/ScheduleEvent';
import { Result } from 'src/types';
import { getDayStart, getEventEndDate, getMinuteStart } from 'src/utils/time';
import { DrivingLessonsAPI } from '../api/DLMServiceAPI/DrivingLessonsAPI';

import { ScheduleEventsAPI } from '../api/TachoAPI/ScheduleEventsAPI';
import { addTachoCalendarEvent } from '../entities/CalendarScheduleEvent';
import {
  LESSON_DURATION_IN_MINUTES,
  NewOpenDrivingLessonData as DLMNewOpenDrivingLessonData,
  NewRequestedDrivingLessonData as DLMNewRequestedDrivingLessonData,
} from '../entities/DLMService/DrivingLesson';
import RootStore from './RootStore';

class NewEventStore {
  @observable public newEventSlot: EventSlot | null | undefined;
  @observable public loadingNewEventsIds: IObservableArray<string>;

  constructor(
    private readonly rootStore: RootStore,
    private readonly scheduleEventsAPI: ScheduleEventsAPI,
    private readonly dlmDrivingLessonsApi: DrivingLessonsAPI
  ) {
    makeObservable(this);

    this.newEventSlot = null;
    this.loadingNewEventsIds = observable.array();
  }

  @computed
  get currentInstructor(): Instructor | undefined {
    return this.rootStore.userStore.currentInstructor;
  }

  @action
  resetNewEventSlot = (): void => {
    this.newEventSlot = null;
  };

  setNewEventSlot = (startDate?: Date): void => {
    this.newEventSlot = new EventSlot({ startDate }, this.rootStore);
  };

  addNewEventDataToStores(eventData: ScheduleEventData): void {
    const newEvent = ScheduleEvent.fromTachoData(eventData);

    this.rootStore.eventScheduleStore.addScheduleEvent(newEvent);

    addTachoCalendarEvent(eventData);
  }

  @action
  public getDrivingLessonData(eventSlot: EventSlot): CreateDrivingLessonData {
    const { startDate, duration, pickUpPoint, student, categoryId } = eventSlot;

    const eventStartDate = getMinuteStart(startDate);

    const endDate = getEventEndDate(eventStartDate, Number(duration));

    const instructorAttendee = {
      [ATTENDEE_TYPE.INSTRUCTOR]: this.currentInstructor!.id,
    };
    const studentAttendee = student && {
      [ATTENDEE_TYPE.STUDENT]: student.id,
    };
    const attendees = [instructorAttendee, studentAttendee].filter(Boolean);

    const resource = pickUpPoint && {
      pickUpPoint: pickUpPoint.id,
    };

    const newDrivingLessonData: CreateDrivingLessonData = {
      resource,
      endDate,
      attendees: attendees as CreateDrivingLessonData['attendees'],
      startDate: eventStartDate,
      type: EVENT_TYPE.DRIVING_LESSON,
      category: categoryId,
    };

    return newDrivingLessonData;
  }

  public getGenericEventData(eventSlot: EventSlot): CreateGenericEventData {
    const { type, startDate, endDate, description } = eventSlot;

    const eventStartDate = getMinuteStart(startDate);
    const eventEndDate = getMinuteStart(endDate);

    const instructorAttendee = {
      [ATTENDEE_TYPE.INSTRUCTOR]: this.currentInstructor!.id,
    };

    const newEventData: CreateGenericEventData = {
      type,
      description,
      attendees: [instructorAttendee],
      startDate: eventStartDate,
      endDate: eventEndDate,
    };

    return newEventData;
  }

  public async createVacation(eventSlot: EventSlot): Promise<Result> {
    const {
      startDate,
      endDate,
      halfDayStartDate,
      halfDayEndDate,
      oneDayEvent,
    } = eventSlot;

    const eventStartDate = halfDayStartDate
      ? getMinuteStart(startDate)
      : getDayStart(startDate);
    let eventEndDate: Date = halfDayEndDate
      ? getMinuteStart(endDate)
      : getDayStart(endDate);

    if (oneDayEvent && halfDayStartDate && !halfDayEndDate) {
      eventEndDate = eventStartDate;
    }

    const newVacationData: CreateVacationData = {
      startDate: eventStartDate,
      endDate: eventEndDate,
      instructor: this.currentInstructor!.id,
      startDateHalfDay: halfDayStartDate,
      endDateHalfDay: halfDayEndDate,
    };

    return this.createVacationRequest(newVacationData);
  }

  public createSickness(eventSlot: EventSlot): Promise<Result> {
    const { startDate, endDate } = eventSlot;

    const eventStartDate = getDayStart(startDate);
    const eventEndDate = getDayStart(endDate);

    const newSicknessData: CreateSicknessData = {
      startDate: eventStartDate,
      endDate: eventEndDate,
      instructor: this.currentInstructor!.id,
    };

    return this.createSicknessRequest(newSicknessData);
  }

  private async createDLMDrivingLesson(eventSlot: EventSlot): Promise<Result> {
    const {
      startDate,
      dlmDrivingLessons,
      pickUpPoint,
      studentEducationId,
      DLMDrivingLessonDefinitionId,
      DLMDrivingLessonDefinitionIds,
    } = eventSlot;

    if (
      !startDate ||
      !pickUpPoint ||
      (studentEducationId && !DLMDrivingLessonDefinitionId) ||
      (!studentEducationId && DLMDrivingLessonDefinitionIds.length < 0)
    ) {
      return { success: false };
    }

    if (studentEducationId) {
      const data: DLMNewRequestedDrivingLessonData = {
        start_time: startDate.toISOString(),
        pick_up_point_id: pickUpPoint.id,
        pickup_location_id: pickUpPoint.id,
        student_education_id: studentEducationId,
        lessons: dlmDrivingLessons.map(({ value }) => ({
          lesson_definition_id: DLMDrivingLessonDefinitionId,
          driving_activity_id: value,
          duration_in_minutes: LESSON_DURATION_IN_MINUTES,
        })),
      };

      return this.dlmDrivingLessonsApi.create(data);
    }

    const data: DLMNewOpenDrivingLessonData = {
      start_time: startDate.toISOString(),
      pick_up_point_id: pickUpPoint.id,
      pickup_location_id: pickUpPoint.id,
      lesson_definitions: DLMDrivingLessonDefinitionIds,
      lessons: dlmDrivingLessons.map(({ value }) => ({
        driving_activity_id: value,
        duration_in_minutes: LESSON_DURATION_IN_MINUTES,
      })),
    };

    return this.dlmDrivingLessonsApi.create(data);
  }

  public async createScheduleEvent(eventSlot: EventSlot): Promise<Result> {
    let eventData: CreateScheduleEventData | null = null;

    switch (eventSlot.type) {
      case NEW_SLOT_TYPES.DRIVING_LESSON: {
        if (this.rootStore.applicationStore.dlmServiceEnabled) {
          return this.createDLMDrivingLesson(eventSlot);
        }

        eventData = this.getDrivingLessonData(eventSlot);
        break;
      }
      case NEW_SLOT_TYPES.BLOCKER:
      case NEW_SLOT_TYPES.MEETING:
      case NEW_SLOT_TYPES.OFFICE_ACTIVITY:
      case NEW_SLOT_TYPES.TRAINING:
      case NEW_SLOT_TYPES.MISC: {
        eventData = this.getGenericEventData(eventSlot);
        break;
      }
      case NEW_SLOT_TYPES.VACATION: {
        return this.createVacation(eventSlot);
      }
      case NEW_SLOT_TYPES.SICKNESS: {
        return this.createSickness(eventSlot);
      }
    }

    if (!eventData) {
      return { success: false };
    }

    return this.createScheduleEventRequest(eventData);
  }

  @action
  private async createScheduleEventRequest(
    eventData: CreateScheduleEventData
  ): Promise<Result> {
    const result = await this.scheduleEventsAPI.create(
      pickBy(eventData, identity) as CreateScheduleEventData
    );

    if (result.success) {
      this.addNewEventDataToStores(result.data);
    }

    return result;
  }

  @action
  private async createVacationRequest(
    eventData: CreateVacationData
  ): Promise<Result> {
    const result = await this.scheduleEventsAPI.createVacation(eventData);

    if (result.success) {
      const vacationEventsIds = result.data.days.map(({ id }) => id);

      vacationEventsIds.forEach((id) => {
        this.loadNewEventDataById(id);
      });
    }

    return result;
  }

  @action
  private async createSicknessRequest(
    eventData: CreateSicknessData
  ): Promise<Result> {
    const result = await this.scheduleEventsAPI.createSickness(eventData);

    if (result.success) {
      const sicknessEventsIds = result.data.days.map(({ id }) => id);

      sicknessEventsIds.forEach((id) => {
        this.loadNewEventDataById(id);
      });
    }

    return result;
  }

  @action
  private async loadNewEventDataById(eventId: string): Promise<void> {
    if (this.loadingNewEventsIds.includes(eventId)) {
      return;
    }

    this.loadingNewEventsIds.push(eventId);
    const result = await this.scheduleEventsAPI.fetchById(eventId);

    if (result.success) {
      this.addNewEventDataToStores(result.data);
    }

    runInAction(() => {
      this.loadingNewEventsIds.remove(eventId);
    });
  }
}

export default NewEventStore;
