import { EVENT_STATUS, ScheduleEventStatus } from '../ScheduleEvent';
import {
  DrivingLessonResponse,
  LESSON_STATUS,
  LessonStatus,
} from './DrivingLesson';
import { LESSON_DEFINITION_COLOR } from './LessonDefinition';

export const getDLMLessonStatus = (
  lesson: DrivingLessonResponse
): ScheduleEventStatus => {
  if (lesson.student_did_not_show_up) {
    return EVENT_STATUS.ABSENT;
  }

  switch (lesson.status) {
    case LESSON_STATUS.OPEN: {
      return EVENT_STATUS.FREE;
    }
    case LESSON_STATUS.REQUESTED: {
      return EVENT_STATUS.RESERVED;
    }
    case LESSON_STATUS.SCHEDULED: {
      return EVENT_STATUS.BOOKED;
    }
    case LESSON_STATUS.REJECTED:
    case LESSON_STATUS.CANCELED: {
      return EVENT_STATUS.CANCELLED;
    }
  }

  if (!lesson.student) {
    return EVENT_STATUS.FREE;
  }

  return EVENT_STATUS.BOOKED;
};

export const isCancelledLesson = (lesson: DrivingLessonResponse): boolean => {
  if (lesson.student_did_not_show_up) {
    return true;
  }

  if (
    lesson.status === LESSON_STATUS.CANCELED ||
    lesson.status === LESSON_STATUS.REJECTED
  ) {
    return true;
  }

  return false;
};

export const isMaintainedLesson = <T extends { status: LessonStatus }>(
  lesson?: T
): boolean => lesson?.status === LESSON_STATUS.COMPLETED;

export const getLessonDefinitionColor = (
  lesson: Pick<DrivingLessonResponse, 'status' | 'lesson_definition'>
): string | undefined => {
  if (lesson.status === LESSON_STATUS.OPEN || !lesson.lesson_definition?.id) {
    return;
  }

  return LESSON_DEFINITION_COLOR[lesson.lesson_definition.id];
};
