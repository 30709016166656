import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { DrivingLessonCategory } from 'src/core/entities/DrivingLessonCategory';

import { DEFAULT_DRIVING_LESSON_CATEGORY_CHARGER_ID } from '../../constants';
import { ISelectOption } from '../../types';
import { DrivingLessonCategoriesAPI } from '../api/TachoAPI/DrivingLessonCategoriesAPI';
import RootStore from './RootStore';

export class DrivingLessonCategoriesStore {
  @observable categories: IObservableArray<DrivingLessonCategory>;
  @observable categoriesLoading: boolean;
  @observable categoriesLoaded: boolean;

  constructor(
    private readonly rootStore: RootStore,
    private readonly drivingLessonCategoriesAPI: DrivingLessonCategoriesAPI
  ) {
    makeObservable(this);

    this.categories = observable.array();
    this.categoriesLoading = false;
    this.categoriesLoaded = false;

    this.loadInitialData();
  }

  async loadInitialData(): Promise<void> {
    await when(() => Boolean(this.rootStore.userStore.currentInstructor));

    this.loadDrivingLessonCategories();
  }

  findByChargerId = (id?: number): DrivingLessonCategory | undefined =>
    this.categories.find(({ chargerId }) => chargerId === id);

  @computed
  get defaultDrivingLessonCategoryId(): string | undefined {
    return this.findByChargerId(DEFAULT_DRIVING_LESSON_CATEGORY_CHARGER_ID)?.id;
  }

  @computed
  get categoriesIds(): string[] {
    return this.categories.map(({ id }) => id);
  }

  @computed
  get categoriesSelectOptions(): ISelectOption[] {
    return this.categories.map(({ id, name }) => ({ value: id, label: name }));
  }

  @action
  async loadDrivingLessonCategories(): Promise<void> {
    if (this.categoriesLoading) {
      return;
    }

    const params = {
      active: true,
    };

    runInAction(() => {
      this.categoriesLoading = true;
    });

    const result = await this.drivingLessonCategoriesAPI.fetchAll(params);

    if (result.success) {
      this.categories.replace(result.data);
    }

    runInAction(() => {
      this.categoriesLoading = false;
      this.categoriesLoaded = true;
    });
  }
}

export default DrivingLessonCategoriesStore;
