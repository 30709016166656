import {
  autorun,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { Socket } from 'phoenix';
import errorLogger from 'src/utils/errorLogger';

import { InstructorsStudent } from '../entities/Instructor';
import LocalStorageService from '../services/LocalStorageService';

interface UserStore {
  isAuthenticated: boolean;
  tokenRefreshing: boolean;
  currentUserId?: string;
}

interface StudentsListUpdatedListener {
  (data: InstructorsStudent[]): void;
}

interface SocketListResponse<T> {
  list: T[];
}

// eslint-disable-next-line max-len
// delay before the first connection is used to wait for the token to refresh if needed on the app start
const CONNECTION_INITIALIZATION_DELAY = 10000;

class INASocket {
  private socket: Socket | null;
  private studentsListUpdatedListeners: StudentsListUpdatedListener[];
  private enabled: boolean;

  constructor(private readonly userStore: UserStore) {
    makeAutoObservable<INASocket, 'canConnect' | 'enabled'>(this, {
      enabled: observable,
      canConnect: computed,
    });

    this.studentsListUpdatedListeners = [];
    this.enabled = false;

    setTimeout(() => {
      runInAction(() => {
        this.enabled = true;
      });
    }, CONNECTION_INITIALIZATION_DELAY);

    autorun(() => {
      if (!this.enabled) {
        return;
      }

      if (this.canConnect) {
        this.connect();
        return;
      }

      this.disconnect();
    });
  }

  async onStudentsListUpdated(
    listener: StudentsListUpdatedListener
  ): Promise<void> {
    this.studentsListUpdatedListeners.push(listener);
  }

  private get canConnect(): boolean {
    return this.userStore.isAuthenticated && !this.userStore.tokenRefreshing;
  }

  private get token(): string | null {
    return LocalStorageService.getAuthToken();
  }

  private get switchUserToken(): string | null {
    return LocalStorageService.getSuToken();
  }

  private disconnect(): void {
    if (this.socket?.isConnected()) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  private connect(): void {
    if (!process.env.REACT_APP_INA_BACKEND_SOCKET) {
      return;
    }

    const userToken = this.token;

    if (!userToken) {
      return;
    }

    const socket = new Socket(
      `${process.env.REACT_APP_INA_BACKEND_SOCKET}/socket`,
      {
        timeout: 45 * 1000,
        params: {
          token: userToken || 'token missing', // The fallback string used to get meaningful errors from BE
          switch_user: this.switchUserToken,
        },
      }
    );

    socket.onError((error) => {
      errorLogger('Error establishing web socket connection', {
        extras: {
          error,
        },
      });

      socket.disconnect();
    });

    socket.onOpen(() => {
      this.joinInstructorChannel();
    });

    socket.connect();

    this.socket = socket;
  }

  private async joinInstructorChannel(): Promise<void> {
    await when(() => Boolean(this.userStore.currentUserId));

    if (!this.socket || !this.userStore.currentUserId) {
      return;
    }

    const channel = this.socket.channel(
      `instructor:${this.userStore.currentUserId}`
    );

    channel.on(
      'student_list_updated',
      (data: SocketListResponse<InstructorsStudent>) => {
        this.studentsListUpdatedListeners.forEach((listener) =>
          listener(data.list)
        );
      }
    );

    channel
      .join()
      .receive('error', (err) =>
        errorLogger('Error establishing instructor channel connection', {
          extras: { err },
        })
      )
      .receive('timeout', () =>
        errorLogger('Connection instructor channel timed out')
      );
  }
}

export default INASocket;
