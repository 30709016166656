import './index.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_STAGE,
    ignoreErrors: [
      /Request failed with status code 40[0-4]/,
      /Request failed with status code 50[03]/,
      'ChunkLoadError',
      /Loading.*chunk.*failed/,
      'Network Error',
      'ReferenceError: google is not defined',
      'CanceledError',
    ],
  });
}

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

serviceWorkerRegistration.customRegistration();
