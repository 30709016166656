import { IObservableArray, makeAutoObservable, observable } from 'mobx';
import { getDate, isSameMinuteDates } from 'src/utils/time';

import { DRIVING_LESSON_DURATION, MAX_DRIVING_LESSONS } from '../EventSlot';
import { PickUpPoint } from '../PickUpPoint';
import { Student } from '../Student';
import {
  DEFAULT_DLM_DRIVING_LESSON_TYPE,
  DLMDrivingLesson,
  LESSON_DURATION_IN_MINUTES,
  Student as DLMStudent,
  UpdateDrivingLessonData,
} from './DrivingLesson';
import { PickUpPoint as DLMPickUpPoint } from './PickUpPoint';

interface SelectedStudent {
  id: string;
  fullName: string;
}

type SelectedPickUpPoint = {
  id: string;
  name?: string;
  streetAndNumber?: string;
  zipCodeAndCity?: string;
};

export class EditableDLMDrivingLesson {
  startDate: Date;
  durationInMinutes: number;
  student?: SelectedStudent;
  studentEducationId: string;
  pickUpPoint?: SelectedPickUpPoint;
  drivingLessonDefinitionId: string;
  drivingLessonDefinitionIds: IObservableArray<string>;
  private privateDlmDrivingLessons: IObservableArray<{
    key: string;
    value: string;
  }>;

  private initialData: DLMDrivingLesson | null = null;

  constructor(readonly id: string) {
    makeAutoObservable(this);

    this.durationInMinutes = DRIVING_LESSON_DURATION;
    this.privateDlmDrivingLessons = observable.array(
      Array.from(Array(MAX_DRIVING_LESSONS), (_, index) => ({
        key: `${DEFAULT_DLM_DRIVING_LESSON_TYPE}-${index}`,
        value: DEFAULT_DLM_DRIVING_LESSON_TYPE,
      }))
    );
    this.drivingLessonDefinitionIds = observable.array();
  }

  get isReady(): boolean {
    return Boolean(this.initialData);
  }

  setInitialData(initialData: DLMDrivingLesson): void {
    if (this.initialData) {
      return;
    }

    this.initialData = { ...initialData, ...initialData.pending_changes };

    const {
      start_time,
      duration_in_minutes,
      student,
      student_education_id,
      lesson_definition,
      lesson_definitions,
    } = this.initialData;

    const pick_up_point =
      this.initialData.pickup_location || this.initialData.pick_up_point;

    this.startDate = getDate(start_time);
    this.durationInMinutes = duration_in_minutes;
    this.studentEducationId = student_education_id || '';
    this.drivingLessonDefinitionId = lesson_definition?.id || '';
    this.drivingLessonDefinitionIds.replace(
      lesson_definitions?.map(({ id }) => id) ?? []
    );

    if (!this.student) {
      this.setDLMStudent(student);
    }

    if (!this.pickUpPoint) {
      this.setDLMPickUpPoint(pick_up_point);
    }

    if (this.initialData.pending_changes?.driving_activities?.length) {
      this.initialData.pending_changes.driving_activities.forEach(
        (activityId, index) => {
          this.setDrivingLessonType(index)(activityId);
        }
      );
    } else {
      this.initialData.lessons.forEach((lesson, index) => {
        this.setDrivingLessonType(index)(lesson.driving_activity_id);
      });
    }
  }

  get lessons(): number {
    return this.durationInMinutes / DRIVING_LESSON_DURATION;
  }

  get dlmDrivingLessons() {
    return this.privateDlmDrivingLessons.slice(0, this.lessons);
  }

  get isOpenLesson(): boolean {
    return !this.student;
  }

  setStartDate = (startDate: Date): void => {
    this.startDate = startDate;
  };

  setDuration = (durationInMinutes: string): void => {
    this.durationInMinutes = Number(durationInMinutes);
  };

  private setDLMPickUpPoint(pickUpPoint?: DLMPickUpPoint): void {
    if (!pickUpPoint) {
      this.pickUpPoint = pickUpPoint;
      return;
    }

    const { id, name, address } = pickUpPoint;
    const zipCodeAndCity = `${address?.city || ''} ${
      address?.zip_code || ''
    }`.trim();

    this.pickUpPoint = {
      id,
      name,
      zipCodeAndCity,
      streetAndNumber: address?.street_and_number,
    };
  }

  setPickUpPoint = ({ id, name, address }: PickUpPoint): void => {
    const streetAndNumber = `${address?.street || ''} ${
      address?.streetNumber || ''
    }`.trim();
    const zipCodeAndCity = `${address?.city || ''} ${
      address?.zipCode || ''
    }`.trim();

    this.pickUpPoint = {
      id,
      name,
      zipCodeAndCity,
      streetAndNumber,
    };
  };

  setDrivingLessonDefinitionId = (lessonDefinitionId: string) => {
    this.drivingLessonDefinitionId = lessonDefinitionId;
  };

  setDrivingLessonDefinitionsIds = (lessonDefinitionsIds: string[]) => {
    this.drivingLessonDefinitionIds.replace(lessonDefinitionsIds);
  };

  private setDLMStudent(student?: DLMStudent): void {
    this.student = student && { id: student.id, fullName: student.full_name };
  }

  setStudent = (student: Student): void => {
    this.drivingLessonDefinitionId = '';
    this.studentEducationId = '';
    this.student = student;
  };

  setStudentEducationId = (studentEducationId: string): void => {
    this.drivingLessonDefinitionId = '';
    this.studentEducationId = studentEducationId;
  };

  setDrivingLessonType =
    (index: number) =>
    (value: string): void => {
      this.privateDlmDrivingLessons.splice(index, 1, {
        value,
        key: `${value}-${index}`,
      });
    };

  getEditedData(): UpdateDrivingLessonData | null {
    if (!this.initialData) {
      return null;
    }

    let data: UpdateDrivingLessonData | null = null;

    if (!isSameMinuteDates(this.startDate, this.initialData.start_time)) {
      data = { start_time: this.startDate.toISOString() };
    }

    if (
      this.pickUpPoint &&
      this.pickUpPoint.id !== this.initialData.pick_up_point?.id
    ) {
      data = {
        ...data,
        pick_up_point_id: this.pickUpPoint.id,
        pickup_location_id: this.pickUpPoint.id,
      };
    }

    if (
      this.studentEducationId &&
      this.studentEducationId !== this.initialData.student_education_id
    ) {
      data = { ...data, student_education_id: this.studentEducationId };
    }

    if (
      this.drivingLessonDefinitionId !== this.initialData.lesson_definition?.id
    ) {
      data = { ...data, lesson_definition_id: this.drivingLessonDefinitionId };
    }

    if (
      this.drivingLessonDefinitionIds.sort().join('') !==
      this.initialData.lesson_definitions
        ?.map(({ id }) => id)
        .sort()
        .join('')
    ) {
      data = {
        ...data,
        lesson_definitions: this.drivingLessonDefinitionIds,
      };
    }

    const currentDrivingActivities =
      this.initialData.pending_changes?.driving_activities ||
      this.initialData.lessons.map(
        ({ driving_activity_id }) => driving_activity_id
      );

    if (
      this.dlmDrivingLessons.map(({ value }) => value).join('') !==
      currentDrivingActivities.join('')
    ) {
      data = {
        ...data,
        lessons: this.dlmDrivingLessons.map((lesson) => ({
          driving_activity_id: lesson.value,
          duration_in_minutes: LESSON_DURATION_IN_MINUTES,
        })),
      };
    }

    return data;
  }
}
