import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
} from 'mobx';
import { BLOCKER_DEFAULT_DURATION } from 'src/constants';
import RootStore from 'src/core/stores/RootStore';
import { Result } from 'src/types';
import { cleanAddressString } from 'src/utils/helpers';
import {
  addTimeToDate,
  getCurrentDate,
  getDiff,
  isSameDayDates,
} from 'src/utils/time';

import { DEFAULT_DLM_DRIVING_LESSON_TYPE } from '../DLMService/DrivingLesson';
import { PickUpPoint } from '../PickUpPoint';
import { Student } from '../Student';

export const NEW_SLOT_TYPES = {
  DRIVING_LESSON: 'driving_lesson',
  BLOCKER: 'blocker',
  VACATION: 'vacation',
  SICKNESS: 'sickness',
  MEETING: 'meeting',
  OFFICE_ACTIVITY: 'office_activity',
  TRAINING: 'training',
  MISC: 'misc',
} as const;

export type NewSlotType = (typeof NEW_SLOT_TYPES)[keyof typeof NEW_SLOT_TYPES];

export const isNewSlotType = (value: string): value is NewSlotType =>
  Object.keys(NEW_SLOT_TYPES).some((key) => NEW_SLOT_TYPES[key] === value);

export const DRIVING_LESSON_DURATION = 45;
export const SLOT_DURATION = [45, 90, 135] as const;

export type SlotDuration = (typeof SLOT_DURATION)[number];

export interface NewSlotData {
  startDate?: Date;
}

export const NEW_SLOT_TYPE_VALUES: ReadonlyArray<NewSlotType> =
  Object.values(NEW_SLOT_TYPES);

const DEFAULT_DURATION = 90;
export const MAX_DRIVING_LESSONS = 3;

export class EventSlot {
  @observable type: NewSlotType;
  @observable startDate: Date;
  @observable endDate: Date;
  @observable duration: SlotDuration;
  @observable student?: Student;
  @observable pickUpPoint?: PickUpPoint;
  @observable categoryId: string;
  @observable description: string;
  @observable halfDayStartDate: boolean;
  @observable halfDayEndDate: boolean;
  @observable DLMDrivingLessonDefinitionId: string;
  @observable DLMDrivingLessonDefinitionIds: IObservableArray<string>;
  @observable privateDlmDrivingLessons: IObservableArray<{
    key: string;
    value: string;
  }>;
  @observable studentEducationId: string;
  @observable private dlmPreferredPickUpPoint: string;

  constructor(
    { startDate = getCurrentDate() }: NewSlotData,
    private readonly rootStore: RootStore
  ) {
    makeObservable(this);

    this.type = NEW_SLOT_TYPES.DRIVING_LESSON;
    this.startDate = startDate;
    this.endDate = addTimeToDate(
      this.startDate,
      BLOCKER_DEFAULT_DURATION,
      'minutes'
    );
    this.duration = DEFAULT_DURATION;
    this.categoryId =
      this.rootStore.drivingLessonCategoriesStore
        .defaultDrivingLessonCategoryId || '';
    this.description = '';
    this.halfDayStartDate = false;
    this.halfDayEndDate = false;
    this.DLMDrivingLessonDefinitionId = '';
    this.DLMDrivingLessonDefinitionIds = observable.array();
    this.privateDlmDrivingLessons = observable.array(
      Array.from(Array(MAX_DRIVING_LESSONS), (_, index) => ({
        key: `${DEFAULT_DLM_DRIVING_LESSON_TYPE}-${index}`,
        value: DEFAULT_DLM_DRIVING_LESSON_TYPE,
      }))
    );
    this.studentEducationId = '';
    this.dlmPreferredPickUpPoint = '';

    reaction(
      () => this.duration,
      () => {
        this.setEndDate();
      }
    );

    reaction(
      () => this.startDate,
      () => {
        this.setEndDate();
      },
      { fireImmediately: true }
    );

    reaction(
      () => ({
        instructor: this.rootStore.userStore.currentInstructor,
        categoriesLoaded:
          this.rootStore.drivingLessonCategoriesStore.categoriesLoaded,
        categoriesIds:
          this.rootStore.drivingLessonCategoriesStore.categoriesIds,
      }),
      (
        { instructor, categoriesLoaded, categoriesIds },
        previousValue,
        currentReaction
      ) => {
        if (!instructor || !categoriesLoaded) {
          return;
        }

        if (
          instructor.preferredDrivingLessonCategory?.id &&
          categoriesIds.includes(instructor.preferredDrivingLessonCategory?.id)
        ) {
          this.setCategory(instructor.preferredDrivingLessonCategory.id);
        }

        currentReaction.dispose();
      },
      { fireImmediately: true }
    );

    reaction(
      () =>
        this.rootStore.drivingLessonCategoriesStore
          .defaultDrivingLessonCategoryId,
      (categoryId, previousValue, currentReaction) => {
        if (this.categoryId) {
          currentReaction.dispose();
          return;
        }

        if (!categoryId) {
          return;
        }

        this.categoryId = categoryId;
        currentReaction.dispose();
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.rootStore.userStore.currentInstructor,
      (currentInstructor) => {
        if (!currentInstructor?.preferredPickUpPoint) {
          return;
        }

        this.setPickUpPoint(currentInstructor.preferredPickUpPoint);
      },
      { fireImmediately: true }
    );
  }

  @computed
  get isOpenLesson(): boolean {
    return !this.student;
  }

  @computed
  get addressString(): string {
    const address = this.pickUpPoint?.address;

    return address
      ? cleanAddressString(`${address.street} ${address.streetNumber}`)
      : '';
  }

  @computed
  get oneDayEvent(): boolean {
    return isSameDayDates(this.startDate, this.endDate);
  }

  @computed
  get lessons(): number {
    return this.duration / DRIVING_LESSON_DURATION;
  }

  @computed
  get dlmDrivingLessons() {
    return this.privateDlmDrivingLessons.slice(0, this.lessons);
  }

  @action
  setDLMDrivingLessonDefinitionId = (value: string): void => {
    this.DLMDrivingLessonDefinitionId = value;
  };

  @action
  setDLMDrivingLessonDefinitionIds = (value: string[]): void => {
    this.DLMDrivingLessonDefinitionIds.replace(value);
  };

  @action
  setStudentEducationId = (value: string): void => {
    this.DLMDrivingLessonDefinitionId = '';
    this.studentEducationId = value;
  };

  @action
  checkVacationEndDate(): void {
    if (this.type !== NEW_SLOT_TYPES.VACATION || !this.oneDayEvent) {
      return;
    }

    if (this.halfDayStartDate) {
      this.halfDayEndDate = false;
    }
  }
  @action
  setType = (type: NewSlotType): void => {
    this.type = type;
  };

  @action
  setStartDate = (newDate: Date) => {
    this.endDate = addTimeToDate(
      this.endDate,
      getDiff(newDate, this.startDate),
      'milliseconds'
    );

    this.startDate = newDate;

    this.checkVacationEndDate();
  };

  @action
  setEndDate = (newDate?: Date) => {
    if (!newDate || this.type === NEW_SLOT_TYPES.DRIVING_LESSON) {
      return;
    }

    this.endDate = newDate;

    this.checkVacationEndDate();
  };

  @action
  setDuration = (duration: SlotDuration): void => {
    this.duration = duration;
  };

  @action
  setStudent = (student?: Student): void => {
    this.student = student;
    this.studentEducationId = '';
    this.categoryId = '';
    this.DLMDrivingLessonDefinitionId = '';
  };

  @action
  setPickUpPoint = (point?: PickUpPoint): void => {
    this.pickUpPoint = point;
  };

  @action
  setDlmPreferredPickUpPoint = async (pointId: string): Promise<void> => {
    if (this.dlmPreferredPickUpPoint) {
      return;
    }

    this.dlmPreferredPickUpPoint = pointId;

    if (!this.rootStore.pickUpPointsStore.pickUpPoints.length) {
      await this.rootStore.pickUpPointsStore.getCurrentInstructorPickUpPoints();
    }

    const point = this.rootStore.pickUpPointsStore.findById(pointId);

    if (this.pickUpPoint) {
      return;
    }

    this.setPickUpPoint(point);
  };

  @action
  setCategory = (categoryId: string): void => {
    this.categoryId = categoryId;
  };

  @action
  setDescription = (description: string): void => {
    this.description = description;
  };

  @action
  toggleHalfDayStartDate = (): void => {
    this.halfDayStartDate = !this.halfDayStartDate;

    this.checkVacationEndDate();
  };

  @action
  toggleHalfDayEndDate = (): void => {
    this.halfDayEndDate = !this.halfDayEndDate;
  };

  async create(): Promise<Result> {
    return this.rootStore.newEventStore.createScheduleEvent(this);
  }
}

export default EventSlot;
