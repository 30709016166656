import { sortEventsByDate } from '../ScheduleEvent.utils';
import { useDLMTodayScheduleEventsQuery } from './useDLMTodayScheduleEventsQuery';
import { useTachoTodayScheduleEventsQuery } from './useTachoTodayScheduleEventsQuery';

const selectUpcomingEvents = <T extends { isUpcoming: boolean }>(
  data: T[]
): T[] => data.filter(({ isUpcoming }) => isUpcoming);

export const useUpcomingScheduleEventsQuery = (
  instructorId: string | undefined,
  dlmServiceEnabled: boolean
) => {
  const tachoQuery = useTachoTodayScheduleEventsQuery(instructorId, {
    select: selectUpcomingEvents,
  });
  const dlmQuery = useDLMTodayScheduleEventsQuery(dlmServiceEnabled, {
    select: selectUpcomingEvents,
  });

  const data = sortEventsByDate([...tachoQuery.data, ...dlmQuery.data]).slice(
    0,
    3
  );

  return {
    data,
    isFetched: dlmServiceEnabled
      ? tachoQuery.isFetched && dlmQuery.isFetched
      : tachoQuery.isFetched,
    isFetching: tachoQuery.isFetching || dlmQuery.isFetching,
  };
};
