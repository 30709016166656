import ee from 'event-emitter';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { EmitterStore } from '../stores/EmitterStore';

export interface IWithEventEmitter {
  ee: ee.Emitter;
}

export const withEventEmitter = (Child: any) =>
  inject('stores')(
    observer(
      class WithEmitter extends React.Component<any> {
        store: EmitterStore;
        constructor(props: any) {
          super(props);
          this.store = props.stores.emitterStore;
        }

        render() {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Child ee={this.store.emitter} {...this.props} />;
        }
      }
    )
  );
