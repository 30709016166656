import { dlmAPI } from 'src/App';
import { getCurrentDate, getDayEnd, getDayStart } from 'src/utils/time';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ScheduleEvent } from '..';
import { LESSON_STATUS } from '../../DLMService';

type Options = Pick<
  UseQueryOptions<ScheduleEvent[], unknown, ScheduleEvent[], string[]>,
  'select'
>;

export const useDLMTodayScheduleEventsQuery = (
  enabled: boolean,
  options?: Options
) =>
  useQuery({
    enabled,
    initialData: [],
    queryKey: ['events', 'dlm', 'today'],
    queryFn: async () => {
      const result = await dlmAPI.drivingLessonsAPI.fetchAll({
        start_time: getDayStart(getCurrentDate()).toISOString(),
        end_time: getDayEnd(getCurrentDate()).toISOString(),
        status: [
          LESSON_STATUS.OPEN,
          LESSON_STATUS.REQUESTED,
          LESSON_STATUS.SCHEDULED,
          LESSON_STATUS.FINISHED,
          LESSON_STATUS.COMPLETED,
        ],
      });

      if (result.success) {
        return result.data.data.map(ScheduleEvent.fromDLMData);
      }

      return [];
    },
    refetchInterval: 5 * 60 * 1000, // 5 minutes
    ...options,
  });
