import { tachoAPI } from 'src/App';

import { useQuery } from '@tanstack/react-query';

import { ScheduleEvent, STUDENT_EVENTS_TYPES } from '..';

export const useStudentTachScheduleEvents = (studentId: string) =>
  useQuery({
    initialData: [],
    queryKey: ['events', 'tacho', { studentId }],
    queryFn: async () => {
      const result = await tachoAPI.scheduleEventsAPI.fetchAll({
        'attendees.student': studentId,
        type: STUDENT_EVENTS_TYPES,
      });

      if (result.success) {
        return result.data.map(ScheduleEvent.fromTachoData);
      }

      return [];
    },
  });
