import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon';

interface IProps {
  className?: string;
}

const TheoryExamPassedIcon: React.FunctionComponent<IProps> = ({
  className,
}) => (
  <SvgIcon
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 22"
  >
    <path d="M13.158 21.533l-8.373-4.57V9.788L0 7.178 13.158 0l13.159 7.178v9.57h-2.392V8.482l-2.393 1.305v7.176l-8.373 4.569zM9.7 10.307a.545.545 0 0 0-.377.149l-.754.718a.491.491 0 0 0 0 .718l3.465 3.3a.553.553 0 0 0 .754 0l6.129-5.835a.49.49 0 0 0 0-.717l-.754-.718a.552.552 0 0 0-.754 0l-5 4.758-2.333-2.221a.545.545 0 0 0-.376-.152z" />
  </SvgIcon>
);

export default TheoryExamPassedIcon;
