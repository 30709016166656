import { BaseAPI } from 'src/core/api/BaseAPI';
import { PracticeProtocolHistoryCategoryData } from 'src/core/entities/PracticeProtocolHistory';
import { ApiRequestResult } from 'src/types';

export class PracticeProtocolHistoryAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchByEventId(
    eventId: string,
    eventType: string
  ): ApiRequestResult<PracticeProtocolHistoryCategoryData[]> {
    return this.baseApi.get(
      `/api/v1/practice_protocol_changes?lesson_id=de.123fahrschule:tacho:${eventType}:${eventId}`
    );
  }

  fetchByDLMEventId(
    eventId: string
  ): ApiRequestResult<PracticeProtocolHistoryCategoryData[]> {
    return this.baseApi.get(
      `/api/v1/practice_protocol_changes?lesson_id=${eventId}`
    );
  }
}
