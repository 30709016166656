import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import {
  ApiRequestResult,
  FailedRequestResult,
  RequestResult,
  SuccessRequestResult,
} from 'src/types';
import { errorLogger, getErrorMessage } from 'src/utils/errorLogger';

export class BaseAPI {
  private axios: AxiosInstance;
  constructor(baseURL: string = '') {
    this.axios = axios.create({ baseURL });
  }

  public addRequestInterceptors(
    callback?: (
      value: InternalAxiosRequestConfig
    ) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>,
    errorCallback?: (error: any) => any
  ) {
    this.axios.interceptors.request.use(callback, errorCallback);
  }

  public addResponseInterceptors(
    callback?: (
      value: AxiosResponse<any>
    ) => AxiosResponse<any> | Promise<AxiosResponse<any>>,
    errorCallback?: (error: any) => any
  ) {
    this.axios.interceptors.response.use(callback, errorCallback);
  }

  public get<T>(url: string, config?: AxiosRequestConfig): ApiRequestResult<T> {
    return this.handleRequest<T>(this.axios.get<T>(url, config));
  }

  public post<T, T2 = null>(
    url: string,
    data: T2,
    config?: AxiosRequestConfig
  ): ApiRequestResult<T> {
    return this.handleRequest<T>(this.axios.post<T>(url, data, config));
  }

  public put<T, T2 = null>(
    url: string,
    data?: T2,
    config?: AxiosRequestConfig
  ): ApiRequestResult<T> {
    return this.handleRequest<T>(this.axios.put<T>(url, data, config));
  }

  public delete<T>(
    url: string,
    config?: AxiosRequestConfig
  ): ApiRequestResult<T> {
    return this.handleRequest<T>(this.axios.delete(url, config));
  }

  public request<T = any>(config: AxiosRequestConfig): ApiRequestResult<T> {
    return this.handleRequest<T>(this.axios.request(config));
  }

  private async handleRequest<T>(
    request: AxiosPromise<T>
  ): Promise<RequestResult<T>> {
    try {
      const response = await request;

      const result: SuccessRequestResult<T> = {
        success: true,
        data: response.data,
      };

      return result;
    } catch (error) {
      errorLogger(error);

      const result: FailedRequestResult = {
        error,
        success: false,
        errorMessage: getErrorMessage(error),
        errorSlug: error?.response?.data?.error,
        status: error?.response?.status,
      };

      return result;
    }
  }
}
