import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';
import {
  AttendeeData,
  CreateAttendeeData,
  TogglePresenceData,
  WebinarHostUrlData,
} from 'src/core/entities/Attendee';

const endpoints = {
  absent: (attendeeId: string) => `/api/v1/attendees/${attendeeId}/absent`,
  present: (attendeeId: string) => `/api/v1/attendees/${attendeeId}/present`,
  create: () => '/api/v1/attendees',
  delete: (attendeeId: string) => `/api/v1/attendees/${attendeeId}`,
  webinarHostUrl: (attendeeId: string) =>
    `/api/v1/attendees/${attendeeId}/host-url`,
};

export class AttendeesAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public markAsAbsent(attendeeId: string): ApiRequestResult {
    return this.baseApi.put(endpoints.absent(attendeeId));
  }

  public togglePresent(
    attendeeId: string
  ): ApiRequestResult<TogglePresenceData> {
    return this.baseApi.put(endpoints.present(attendeeId));
  }

  public create(data: CreateAttendeeData): ApiRequestResult<AttendeeData> {
    return this.baseApi.post(endpoints.create(), data);
  }

  public delete(attendeeId: string): ApiRequestResult {
    return this.baseApi.delete(endpoints.delete(attendeeId));
  }

  public getWebinarHostUrl(
    attendeeId: string
  ): ApiRequestResult<WebinarHostUrlData> {
    return this.baseApi.get(endpoints.webinarHostUrl(attendeeId));
  }
}
