import { IObservableArray, makeObservable, observable } from 'mobx';

interface ManualPresence {
  [eventId: string]: IObservableArray<string>;
}

class ManualPresenceStore {
  @observable manualPresences: ManualPresence;
  constructor() {
    makeObservable(this);

    this.manualPresences = {};
  }

  add(eventId: string, attendeeId: string): void {
    if (!this.manualPresences[eventId]) {
      this.manualPresences[eventId] = observable.array();
    }

    this.manualPresences[eventId].push(attendeeId);
  }

  remove(eventId: string, attendeeId: string): void {
    this.manualPresences[eventId]?.remove(attendeeId);
  }

  isSetted(eventId: string, attendeeId: string): boolean {
    return this.manualPresences[eventId]?.includes(attendeeId);
  }
}

export default ManualPresenceStore;
