import { BaseAPI } from 'src/core/api/BaseAPI';
import {
  CreateScheduleEventData,
  CreateSicknessData,
  CreateVacationData,
  ScheduleEventData,
  ScheduleEventRequestParams,
  SicknessData,
  UpdateScheduleEventData,
  VacationData,
} from 'src/core/entities/ScheduleEvent';
import { ApiRequestResult } from 'src/types';

const endpoints = {
  fetchAll: () => '/api/v1/schedule_events',
  fetchById: (eventId: string) => `/api/v1/schedule_events/${eventId}`,
  create: () => '/api/v1/schedule_events',
  createVacation: () => '/api/v1/vacations',
  createSickness: () => '/api/v1/sickness',
  update: (eventId: string) => `/api/v1/schedule_events/${eventId}`,
  delete: (eventId: string) => `/api/v1/schedule_events/${eventId}`,
};

export class ScheduleEventsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchAll(
    params?: ScheduleEventRequestParams
  ): ApiRequestResult<ScheduleEventData[]> {
    return this.baseApi.get(endpoints.fetchAll(), {
      params,
    });
  }

  public fetchById(
    id: string,
    params?: ScheduleEventRequestParams
  ): ApiRequestResult<ScheduleEventData> {
    return this.baseApi.get(endpoints.fetchById(id), {
      params,
    });
  }

  public create(
    data: CreateScheduleEventData
  ): ApiRequestResult<ScheduleEventData> {
    return this.baseApi.post(endpoints.create(), data);
  }

  public createVacation(
    data: CreateVacationData
  ): ApiRequestResult<VacationData> {
    return this.baseApi.post(endpoints.createVacation(), data);
  }

  public createSickness(
    data: CreateSicknessData
  ): ApiRequestResult<SicknessData> {
    return this.baseApi.post(endpoints.createSickness(), data);
  }

  public update(
    eventId: string,
    data: UpdateScheduleEventData
  ): ApiRequestResult<ScheduleEventData> {
    return this.baseApi.put(endpoints.update(eventId), data);
  }

  public delete(eventId: string): ApiRequestResult {
    return this.baseApi.delete(endpoints.delete(eventId));
  }
}
