import { sortEventsByDate } from '../ScheduleEvent.utils';
import { useDLMNextWorkDayScheduleEventsQuery } from './useDLMNextWorkDayScheduleEventsQuery';
import { useTachoNextWorkDayScheduleEventsQuery } from './useTachoNextWorkDayScheduleEventsQuery';

export const useNextWorkDayScheduleEventsQuery = (
  instructorId: string | undefined,
  dlmServiceEnabled: boolean
) => {
  const tachoQuery = useTachoNextWorkDayScheduleEventsQuery(instructorId);
  const dlmQuery = useDLMNextWorkDayScheduleEventsQuery(dlmServiceEnabled);

  const data = sortEventsByDate([...tachoQuery.data, ...dlmQuery.data]);

  return {
    data,
    isFetched: dlmServiceEnabled
      ? tachoQuery.isFetched && dlmQuery.isFetched
      : tachoQuery.isFetched,
    isFetching: tachoQuery.isFetching || dlmQuery.isFetching,
  };
};
