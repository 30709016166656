import { action, computed, makeObservable, observable, when } from 'mobx';
import { DEFAULT_DRIVING_LESSON_DURATION } from 'src/constants';
import EditingDrivingLessonStore from 'src/core/stores/EditingDrivingLessonStore';
import { Result } from 'src/types';
import {
  getCurrentDate,
  getDate,
  getEventEndDate,
  getMinuteStart,
  isSameMinuteDates,
} from 'src/utils/time';

import { PickUpPoint } from '../PickUpPoint';
import { ScheduleEvent, UpdateDrivingLessonData } from '../ScheduleEvent';

interface Student {
  id: string;
  firstName: string;
  lastName: string;
}

export class EditingDrivingLesson {
  readonly id: string;

  @observable startDate: Date;
  @observable duration: number;
  @observable student?: Student | null;
  @observable pickUpPoint?: PickUpPoint | null;
  @observable category: string;

  @observable eventDataLoaded: boolean;

  constructor(id: string, private store: EditingDrivingLessonStore) {
    makeObservable(this);

    this.id = id;
    this.startDate = getCurrentDate();
    this.duration = DEFAULT_DRIVING_LESSON_DURATION;
    this.student = null;
    this.pickUpPoint = null;
    this.category = '';

    this.eventDataLoaded = false;
  }

  @computed
  get event(): ScheduleEvent | undefined {
    return this.store.event;
  }

  @computed
  get endDate(): Date {
    return getEventEndDate(this.startDate, this.duration);
  }

  @action
  async setEventData({
    startDate,
    duration,
    studentAttendee,
    pickUpPoint,
    drivingCategoryId,
  }: ScheduleEvent): Promise<void> {
    this.startDate = getDate(startDate);
    this.duration = duration;
    this.student = this.student || studentAttendee?.student;
    this.pickUpPoint = this.pickUpPoint || pickUpPoint;

    this.category = drivingCategoryId || '';

    this.eventDataLoaded = true;
  }

  @action
  setStartDate(startDate: Date): void {
    this.startDate = startDate;
  }

  @action
  setDuration(duration: string): void {
    this.duration = Number(duration);
  }

  @action
  setStudent(student: Student): void {
    this.student = student;
    this.category = '';
  }

  @action
  setPickUpPoint(pickUpPoint: PickUpPoint): void {
    this.pickUpPoint = pickUpPoint;
  }

  @action
  setCategory(categoryId: string): void {
    this.category = categoryId;
  }

  @action
  async save(): Promise<Result> {
    await when(() => Boolean(this.event));

    if (!this.event) {
      return { success: false };
    }

    let data: UpdateDrivingLessonData = {};

    if (!isSameMinuteDates(this.startDate, this.event.startDate)) {
      data = { ...data, startDate: getMinuteStart(this.startDate) };
    }

    if (!isSameMinuteDates(this.endDate, this.event.endDate)) {
      data = { ...data, endDate: getMinuteStart(this.endDate) };
    }

    if (
      this.duration !== this.event.duration &&
      this.event.drivingLessonTypes.length > 0
    ) {
      data = { ...data, drivingLessonTypes: [] };
    }

    if (this.category && this.category !== this.event.drivingCategoryId) {
      data = { ...data, category: this.category };
    }

    if (
      this.pickUpPoint &&
      this.pickUpPoint?.id !== this.event.pickUpPoint?.id
    ) {
      data = {
        ...data,
        resource: {
          ...(this.event.resourceId && {
            id: `/api/v1/resources/${this.event.resourceId}`,
          }),
          pickUpPoint: this.pickUpPoint.id,
        },
      };
    }

    const eventUpdateResult = await this.store.updateDrivingLesson(
      this.event,
      data
    );

    if (!eventUpdateResult.success) {
      this.store.reloadScheduleEventData(this.id);

      return eventUpdateResult;
    }

    if (this.student && this.student.id !== this.event.studentId) {
      const studentUpdateResult = await this.store.updateDrivingLessonStudent(
        this.event,
        this.student.id,
        this.event.studentAttendee?.id
      );

      if (!studentUpdateResult.success) {
        this.store.reloadScheduleEventData(this.id);

        return studentUpdateResult;
      }
    }

    this.store.reloadScheduleEventData(this.id);

    return { success: true };
  }
}

export default EditingDrivingLesson;
