import { dlmAPI } from 'src/App';

import { useQuery } from '@tanstack/react-query';

import { DLMDrivingLesson } from '../DrivingLesson';

export const useDLMDrivingLessonDetailsQuery = (lessonId: string) =>
  useQuery<DLMDrivingLesson>({
    queryKey: ['dlm', 'lesson details', { lessonId }],
    queryFn: async () => {
      const result = await dlmAPI.drivingLessonsAPI.fetchById(lessonId);

      if (result.success) {
        result.data.lesson_definitions?.sort((a, b) =>
          a.label.localeCompare(b.label)
        );

        return result.data;
      }

      throw new Error(result.errorMessage);
    },
  });
