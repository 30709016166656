import { BaseAPI } from 'src/core/api/BaseAPI';
import {
  DLMPreferencesResponse,
  InstructorsStudent,
  NumberOfQRCodeScansData,
  QRCodeData,
  SettingsResponse,
  UpdateDLMPreferencesData,
} from 'src/core/entities/Instructor';
import { ApiRequestResult } from 'src/types';

export class InstructorsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchRatingQRCode(instructorId: string): ApiRequestResult<QRCodeData> {
    return this.baseApi.get(
      `/api/v1/instructors/${instructorId}/qr-codes/request-rating?color=currentColor`
    );
  }

  public fetchNumberOfQRCodeScans(): ApiRequestResult<NumberOfQRCodeScansData> {
    return this.baseApi.get('api/v1/rating/number_of_qr_code_scans');
  }

  public fetchStudents(): ApiRequestResult<InstructorsStudent[]> {
    return this.baseApi.get('/api/v1/instructor/students');
  }

  public markFavorite(studentId: string): ApiRequestResult<void> {
    return this.baseApi.post(
      '/api/v1/instructor/favourite_students',
      {},
      { params: { student_id: studentId } }
    );
  }
  public unmarkFavorite(studentId: string): ApiRequestResult<void> {
    return this.baseApi.delete('/api/v1/instructor/favourite_students', {
      params: { student_id: studentId },
    });
  }

  fetchSettings(): ApiRequestResult<SettingsResponse> {
    return this.baseApi.get('/api/v1/settings');
  }

  fetchDLMPreferences(): ApiRequestResult<DLMPreferencesResponse> {
    return this.baseApi.get('/api/v1/instructor/preferences');
  }

  updateDLMPreferences(
    data: UpdateDLMPreferencesData
  ): ApiRequestResult<DLMPreferencesResponse> {
    return this.baseApi.put('/api/v1/instructor/preferences', data);
  }
}
