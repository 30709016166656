import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { PracticeProtocolHistoryAPI } from 'src/core/api/IspaAPI/PracticeProtocolHistoryAPI';
import { PracticeProtocolHistory } from 'src/core/entities/PracticeProtocolHistory';
import { ProtocolLessonType } from 'src/core/entities/PracticeProtocol';

class PracticeProtocolHistoryStore {
  eventId: string;
  eventType: ProtocolLessonType;
  historyLoading: boolean;
  isDLMLesson: boolean;
  practiceProtocolHistory: PracticeProtocolHistory | null;

  constructor(
    private readonly practiceProtocolHistoryAPI: PracticeProtocolHistoryAPI
  ) {
    makeObservable<
      PracticeProtocolHistoryStore,
      'loadEventPracticeProtocolHistory'
    >(this, {
      eventId: observable,
      eventType: observable,
      historyLoading: observable,
      isDLMLesson: observable,
      practiceProtocolHistory: observable,
      progressNotSet: computed,
      noProgress: computed,
      hasProgress: computed,
      toInitialState: action,
      setEventId: action,
      setEventType: action,
      setDLMLesson: action,
      loadEventPracticeProtocolHistory: action,
    });

    this.toInitialState();

    reaction(
      () => ({
        id: this.eventId,
        type: this.eventType,
        isDLMLesson: this.isDLMLesson,
      }),
      ({ id, type, isDLMLesson }) => {
        if (!id || !type) {
          return;
        }

        this.toInitialState();

        this.loadEventPracticeProtocolHistory(id, type, isDLMLesson);
      },
      { fireImmediately: true }
    );
  }

  get progressNotSet(): boolean {
    return !this.historyLoading && !this.practiceProtocolHistory;
  }

  get noProgress(): boolean {
    return Boolean(
      !this.historyLoading &&
        this.practiceProtocolHistory?.categories &&
        this.practiceProtocolHistory?.categories.length === 0
    );
  }

  get hasProgress(): boolean {
    return Boolean(this.practiceProtocolHistory?.categories);
  }

  public toInitialState(): void {
    this.eventId = '';
    this.historyLoading = false;
    this.practiceProtocolHistory = null;
    this.isDLMLesson = false;
  }

  public setEventId(eventId: string): void {
    this.eventId = eventId;
  }

  public setEventType(type: ProtocolLessonType): void {
    this.eventType = type;
  }

  setDLMLesson(isDLMLesson: boolean = true): void {
    this.isDLMLesson = isDLMLesson;
  }

  // API requests
  private async loadEventPracticeProtocolHistory(
    eventId: string,
    eventType: string,
    isDLMLesson: boolean
  ): Promise<void> {
    if (this.historyLoading) {
      return;
    }

    runInAction(() => {
      this.historyLoading = true;
    });

    const result = isDLMLesson
      ? await this.practiceProtocolHistoryAPI.fetchByDLMEventId(eventId)
      : await this.practiceProtocolHistoryAPI.fetchByEventId(
          eventId,
          eventType
        );

    runInAction(() => {
      this.historyLoading = false;
    });

    if (result.success) {
      runInAction(() => {
        this.practiceProtocolHistory = new PracticeProtocolHistory(result.data);
      });
    } else {
      this.practiceProtocolHistory = null;
    }
  }
}

export default PracticeProtocolHistoryStore;
