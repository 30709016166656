import { action, makeObservable, observable, runInAction } from 'mobx';
import { LessonTypesData } from 'src/core/entities/DrivingLessonType';
import { DrivingLessonsAPI } from '../api/DLMServiceAPI/DrivingLessonsAPI';

import { DrivingLessonTypesAPI } from '../api/TachoAPI/DrivingLessonTypesAPI';
import { DrivingTypeSlug } from '../entities/DLMService';

export class DrivingTypesStore {
  @observable public typesFetching: boolean = false;
  @observable public drivingTypes?: LessonTypesData;

  @observable updatingLessonTypes: Record<string, boolean>;

  constructor(
    private readonly drivingLessonTypesAPI: DrivingLessonTypesAPI,
    private readonly dlmDrivingLessonsAPI: DrivingLessonsAPI
  ) {
    makeObservable(this);

    this.updatingLessonTypes = observable({});
  }

  public getDrivingTypes(): LessonTypesData | undefined {
    if (!this.drivingTypes) {
      this.loadDrivingTypes();
    }

    return this.drivingTypes;
  }

  saveDLMLessonTypes = async (lessonId: string, types: DrivingTypeSlug[]) => {
    if (this.updatingLessonTypes[lessonId]) {
      return;
    }

    this.updatingLessonTypes[lessonId] = true;

    const result = await this.dlmDrivingLessonsAPI.maintain(lessonId, {
      types,
    });

    runInAction(() => {
      this.updatingLessonTypes[lessonId] = false;
    });

    return result;
  };

  // API requests
  @action
  private async loadDrivingTypes(): Promise<void> {
    runInAction(() => {
      this.typesFetching = true;
    });

    const result = await this.drivingLessonTypesAPI.fetchDrivingTypes();

    runInAction(() => {
      this.typesFetching = false;
    });

    if (result.success) {
      runInAction(() => {
        this.drivingTypes = result.data;
      });
    }
  }
}

export default DrivingTypesStore;
