import { EVENT_TYPE } from 'src/core/entities/ScheduleEvent';

import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  whiteTwo: {
    color: '#ececec',
  },
  orangeRed: {
    color: '#ff5722',
  },
  greenBlue: {
    color: '#01ca71',
  },
  greenBlueTwo: {
    color: '#20c898',
  },
  vermilion: {
    color: '#e91a10',
  },
  lightNavyBlue: {
    color: '#295d75',
  },
  absent: {
    color: '#a80800',
  },
  [EVENT_TYPE.DRIVING_LESSON]: {
    color: '#00a7a9',
  },
  [EVENT_TYPE.THEORY_LESSON]: {
    color: '#706aae',
  },
  [EVENT_TYPE.DRIVING_EXAM]: {
    color: '#ff5424',
  },
  [EVENT_TYPE.THEORY_EXAM]: {
    color: '#ff5424',
  },
  [EVENT_TYPE.SIMULATOR_LESSON]: {
    color: '#e82e8a',
  },

  palette: {
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#2a5c76',
    },
    secondary: {
      main: '#ff5722',
    },
    text: {
      primary: '#2a5c76',
      secondary: '#9b9b9c',
    },
    error: {
      main: '#d32f2f',
    },
  },

  typography: {
    fontFamily: ['OpenSans', '"Open Sans"', 'sans-serif'].join(', '),
    fontWeightMedium: 600,
    htmlFontSize: 16,
    body1: {
      fontSize: '0.875rem',
    },
    caption: {
      display: 'block',
    },
  },
  dimensions: {
    APP_BAR_HEIGHT_SM: 54,
    APP_BAR_HEIGHT_SM_UP: 54,
    NAVIGATION_HEIGHT_SM: 52,
    NAVIGATION_HEIGHT_SM_UP: 52,
  },
  overrides: {
    MuiListItem: {
      root: {
        paddingBottom: 11,
        paddingTop: 11,
      },
    },
    MuiListItemAvatar: {
      root: {
        minWidth: 0,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 0,
        marginRight: 16,
        '&$alignItemsFlexStart': { marginTop: 0 },
      },
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0,
      },
      primary: {
        fontSize: '1rem',
      },
      secondary: {
        lineHeight: 1.5,
      },
      multiline: {
        marginBottom: 0,
        marginTop: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: 12,
      },
    },
  },
});

export default theme;
