import { ispaAPI } from 'src/App';

import { useQuery } from '@tanstack/react-query';

export const usePracticeProtocolHistoryQuery = (eventId: string) =>
  useQuery({
    queryKey: ['ina backend', 'practice protocol history', { eventId }],
    queryFn: async () => {
      const result = await ispaAPI.practiceProtocolHistoryAPI.fetchByDLMEventId(
        eventId
      );

      if (result.success) {
        return result.data;
      }

      throw new Error(result.errorMessage);
    },
  });
