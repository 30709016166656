import moment, {
  DurationInputArg2,
  Moment,
  MomentInput,
} from 'moment-timezone';
import { View } from 'react-big-calendar';

import {
  dateFormats,
  DEFAULT_TIME_ZONE,
  HALF_DAY_VACATION_DURATION,
} from '../constants';
import { CALENDAR_DAY_CHANGE_DIRECTION } from '../core/stores/UiStore';
import {
  DatesRange,
  DateString,
  FormattedDatesRange,
  StringOrDate,
} from '../types';

type DateInput = Date | DateString;
type DateFormat = keyof typeof dateFormats;
type FormatDateFunction = (date: DateInput) => string;

// formatting
export const formatDate = (
  date: DateInput,
  format: DateFormat = 'defaultDateFormat'
): string => moment(date).format(dateFormats[format]);

export const formatTime = (
  date: DateInput,
  format: DateFormat = 'defaultTimeFormat'
): string => formatDate(date, format);

export const formatTimeRange = (
  startDate: DateInput,
  endDate: DateInput
): string => `${formatTime(startDate)} - ${formatTime(endDate)}`;

export const formatEventTime: FormatDateFunction = (date) => formatTime(date);

export const formatEventDate: FormatDateFunction = (date) => formatDate(date);

export const formatStudentExamDate: FormatDateFunction = (date) =>
  formatDate(date, 'shortDate');

export const formatFailedEventDate: FormatDateFunction = (date) =>
  formatDate(date, 'failedSlots');

export const formatLoadingStatusDate: FormatDateFunction = (date) =>
  formatDate(date, 'loadingStatus');

export const formatBirthday: FormatDateFunction = (date) => formatDate(date);

export const formatHistoryItemDate: FormatDateFunction = (date) =>
  formatDate(date, 'fullDateWithTime');

export const formatCommentDate: FormatDateFunction = (date) =>
  formatDate(date, 'fullDateWithTime');

export const formatCopySlotDate = (date: Date) =>
  formatDate(date, 'copySlotDate');

export const formatVacationDatesRange = ({
  startDate,
  endDate,
}: DatesRange): FormattedDatesRange => {
  const displayingEndDate = getVacationDisplayingEndDate({
    startDate,
    endDate,
  });

  let vacationStartDate = formatEventDate(startDate);
  let vacationEndDate = formatEventDate(displayingEndDate);

  if (!isDayStart(startDate)) {
    vacationStartDate += `, ${formatEventTime(startDate)}`;
  }

  if (
    !isDayStart(endDate) &&
    !isSameDayDates(startDate, endDate) &&
    !isHalfDayVacation({ startDate, endDate })
  ) {
    vacationEndDate += `, ${formatEventTime(endDate)}`;
  }

  return {
    startDate: vacationStartDate,
    endDate: vacationEndDate,
  };
};

// query
export const isLocalDayStart = (date: Date): boolean =>
  date.getHours() === 0 &&
  date.getMinutes() === 0 &&
  date.getSeconds() === 0 &&
  date.getMilliseconds() === 0;

export const isPastDate = (date: DateString | Date): boolean =>
  moment(date).isBefore(moment());

export const isFutureDate = (date: MomentInput): boolean =>
  moment(date).isAfter(moment());

export const isDateAfter = (
  firstDate: MomentInput,
  secondDate: MomentInput
): boolean => moment(firstDate).isAfter(secondDate);

export const isDateSameOrAfter = (
  firstDate: DateString | Date,
  secondDate: Date
): boolean => moment(firstDate).isSameOrAfter(secondDate);

export const isDateBefore = (
  firstDate: MomentInput,
  secondDate: MomentInput
): boolean => moment(firstDate).isBefore(secondDate);

export const isSameMinuteDates = (
  firstDate: MomentInput,
  secondDate: MomentInput
): boolean => moment(firstDate).isSame(moment(secondDate), 'minute');

export const isSameDayDates = (
  firstDate: StringOrDate,
  secondDate: StringOrDate | null
): boolean => {
  if (!secondDate) {
    return false;
  }

  return moment(firstDate).isSame(moment(secondDate), 'day');
};

export const isSameWeekDates = (
  firstDate: MomentInput,
  secondDate: MomentInput
): boolean => moment(firstDate).isSame(moment(secondDate), 'isoWeek');

export const isSaturday = (date: Moment | Date): boolean =>
  moment(date).isoWeekday() === 6;

export const isSunday = (date: Moment | Date): boolean =>
  moment(date).isoWeekday() === 7;

export const isDayStart = (date: MomentInput): boolean =>
  moment(date).isSame(moment(date).startOf('day'));

export const isWithinInterval = (
  date: Date,
  { start, end }: { start: StringOrDate; end: StringOrDate }
): boolean => moment(date).isBetween(start, end, undefined, '[]');

export const isHalfDayVacation = ({
  startDate,
  endDate,
}: DatesRange): boolean =>
  moment(endDate).diff(moment(startDate), 'minutes') ===
  HALF_DAY_VACATION_DURATION;

// manipulations
export const addWeeksToDate = (date: MomentInput, weeks: number): Date =>
  moment(date).add({ weeks }).toDate();

export const addDaysToDate = (date: Date, days: number): Date =>
  moment(date).add({ days }).toDate();

export const addMinutesToDate = (date: Date, minutes: number): Date =>
  moment(date).add({ minutes }).toDate();

export const addTimeToDate = (
  date: MomentInput,
  value: number,
  unit: DurationInputArg2
): Date => moment(date).add(value, unit).toDate();

// get/set
export const getDate = (date: StringOrDate | Moment): Date =>
  moment(date).toDate();

export const getCurrentDate = (): Date => moment().toDate();

export const getMoment = (date: StringOrDate | Moment): Moment => moment(date);

export const getCurrentMomentMinute = (): Moment => moment().startOf('minute');

export const getTomorrowDate = (): Date =>
  moment().add(1, 'day').startOf('day').toDate();

export const getDayStart = (date: StringOrDate): Date =>
  moment(date).startOf('day').toDate();

export const getNextDayStart = (date: StringOrDate): Date =>
  moment(date).add(1, 'day').startOf('day').toDate();

export const getDayEnd = (date: MomentInput): Date =>
  moment(date).endOf('day').toDate();

export const getWeekStart = (date: MomentInput): Date =>
  moment(date).startOf('isoWeek').toDate();

export const getWeekEnd = (date: Date): Date =>
  moment(date).endOf('isoWeek').toDate();

export const getMinuteStart = (date: Date): Date =>
  moment(date).startOf('minute').toDate();

export const getDiff = (
  startDate: DateString | Date,
  endDate: DateString | Date
): number => moment(startDate).diff(moment(endDate));

export const getWeeksDiff = (startDate: Date, endDate: Date): number =>
  moment(startDate).diff(moment(endDate), 'weeks');

export const getIsoWeek = (date: Date): number => moment(date).isoWeek();

export const getEventDuration = (
  startDate: MomentInput,
  endDate: MomentInput
): number => moment(endDate).diff(moment(startDate), 'minutes');

export const getEventEndDate = (startDate: Date, duration: number): Date =>
  moment(startDate).add(duration, 'minutes').toDate();

export const getAge = (date: DateString | Date): number =>
  moment().diff(moment(date), 'years');

export const defaultCalendarDisabledDate = (date: Moment): boolean =>
  isSunday(date);

export const getCalendarInitialScroll = (): Date =>
  moment().set('hour', 7).startOf('hour').toDate();

export const getNewSlotStartDate = (
  date: StringOrDate,
  calendarView: View
): Date => {
  const currentDate = getCurrentDate();
  let startDate: Date = currentDate;
  const currentCalendarDate = moment(date);

  switch (calendarView) {
    case 'day': {
      startDate = currentCalendarDate.isAfter(moment())
        ? currentCalendarDate.toDate()
        : currentDate;
      break;
    }
    case '3Days' as View: {
      startDate =
        moment().isBetween(
          currentCalendarDate,
          currentCalendarDate.clone().add(2, 'days'),
          'day',
          '[]'
        ) || currentCalendarDate.isBefore(moment())
          ? currentDate
          : currentCalendarDate.toDate();
      break;
    }
    case 'work_week': {
      startDate = currentCalendarDate.isSameOrBefore(moment(), 'isoWeek')
        ? currentDate
        : currentCalendarDate.clone().startOf('isoWeek').toDate();
      break;
    }
  }

  return startDate;
};

export const getDatesRange = (startDate: Date, length: number): Date[] =>
  Array.from({ length }, (_, index: number) =>
    moment(startDate).add(index, 'days').toDate()
  );

export const getThreeDaysRangeEnd = (date: Date): Date =>
  moment(date).add(2, 'days').toDate();

export const getNextThreeDaysDate = (date: Date): Date =>
  moment(date).subtract(3, 'days').toDate();

export const getPrevThreeDaysDate = (date: Date): Date =>
  moment(date).add(3, 'days').toDate();

export const getWorkWeekRangeStart = (date: Date): Date =>
  moment(date).startOf('isoWeek').toDate();

export const getWorkWeekRangeEnd = (date: Date): Date =>
  moment(date).endOf('isoWeek').subtract(1, 'day').toDate();

export const getNextWorkWeekDate = (date: Date): Date =>
  moment(date).subtract(1, 'week').toDate();

export const getPrevWorkWeekDate = (date: Date): Date =>
  moment(date).add(1, 'week').toDate();

export const getNextMonday = (): Date =>
  moment().add(1, 'week').startOf('isoWeek').toDate();

export const getInitialCalendarDate = (currentCalendarView: View): Date => {
  const today = moment().startOf('day');
  let newDate: Date = moment().toDate();

  switch (currentCalendarView) {
    case 'day': {
      newDate = today.toDate();
      break;
    }
    case '3Days' as View: {
      const weekDay = today.isoWeekday();

      if (weekDay <= 3) {
        newDate = today.startOf('isoWeek').toDate();
      } else if (weekDay === 7) {
        newDate = today.add(1, 'day').toDate();
      } else {
        newDate = today.startOf('isoWeek').add(3, 'days').toDate();
      }
      break;
    }
    case 'work_week': {
      newDate = today.startOf('isoWeek').toDate();
      break;
    }
  }

  return newDate;
};

export const getCalendarDate = (
  currentCalendarDate: StringOrDate,
  currentCalendarView: View,
  nextCalendarView: View
): Date => {
  let newDate: Moment = moment().startOf('day');
  const today = moment().startOf('day');
  const calendarDate = moment(currentCalendarDate);

  switch (nextCalendarView) {
    case 'day': {
      switch (currentCalendarView) {
        case '3Days' as View: {
          newDate = today.isBetween(
            calendarDate,
            calendarDate.clone().add(2, 'days'),
            'day',
            '[]'
          )
            ? today
            : calendarDate;
          break;
        }
        case 'work_week': {
          newDate = calendarDate.isSame(today, 'isoWeek')
            ? today
            : calendarDate.startOf('isoWeek');
          break;
        }
      }
      break;
    }
    case '3Days' as View: {
      const currentWeekDay = today.isSame(currentCalendarDate, 'isoWeek')
        ? today.isoWeekday()
        : calendarDate.isoWeekday();

      if (currentWeekDay <= 3) {
        newDate = calendarDate.startOf('isoWeek');
      } else {
        newDate = calendarDate.startOf('isoWeek').add(3, 'days');
      }
      break;
    }
    case 'work_week': {
      newDate = calendarDate.startOf('isoWeek');
      break;
    }
  }

  return newDate.toDate();
};

export const getNewCalendarStartDate = (
  currentCalendarDate: StringOrDate,
  currentCalendarView: View,
  direction: CALENDAR_DAY_CHANGE_DIRECTION
): Date => {
  let newStartDate: Date = moment().startOf('day').toDate();
  const startDate = moment(currentCalendarDate);
  const isPrevDays = direction === CALENDAR_DAY_CHANGE_DIRECTION.PREV;

  switch (currentCalendarView) {
    case 'day': {
      newStartDate = startDate.add(isPrevDays ? -1 : 1, 'days').toDate();
      if (moment(newStartDate).isoWeekday() === 7) {
        newStartDate = moment(newStartDate)
          .add(isPrevDays ? -1 : 1, 'days')
          .toDate();
      }
      break;
    }
    case '3Days' as View: {
      const currentWeekDay = startDate.isoWeekday();
      if (currentWeekDay <= 3) {
        newStartDate = startDate.add(isPrevDays ? -4 : 3, 'days').toDate();
      } else {
        newStartDate = startDate.add(isPrevDays ? -3 : 4, 'days').toDate();
      }
      break;
    }
    case 'work_week': {
      newStartDate = startDate.add(isPrevDays ? -1 : 1, 'weeks').toDate();
      break;
    }
  }

  return newStartDate;
};

const parseVacationDateString = (string: string): Date =>
  moment.tz(string, DEFAULT_TIME_ZONE).toDate();

export const parseVacationDatesRange = (
  description: string
): DatesRange | undefined => {
  const dates = description.match(/[\d-]{10} [\d:]{8}/g);

  if (!dates || dates.length < 2) {
    return;
  }

  const [start, end] = dates;
  const startDate = parseVacationDateString(start);
  const endDate = parseVacationDateString(end);

  return {
    startDate,
    endDate,
  };
};

const getVacationDisplayingEndDate = ({
  startDate,
  endDate,
}: DatesRange): Date => {
  if (isHalfDayVacation({ startDate, endDate })) {
    return getDayEnd(startDate);
  }

  return isDayStart(endDate)
    ? addTimeToDate(endDate, -1, 'millisecond')
    : endDate;
};

export const getMilliseconds = ({ seconds = 0, minutes = 0, hours = 0 }) =>
  seconds * 1000 + minutes * 60 * 1000 + hours * 60 * 60 * 1000;
