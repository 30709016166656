import { BaseAPI } from 'src/core/api/BaseAPI';
import { INABackendStudentStatisticsResponse } from 'src/core/entities/StudentStatistics';
import { ApiRequestResult } from 'src/types';

export class StudentStatisticsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchByStudentId(
    studentId: string
  ): ApiRequestResult<INABackendStudentStatisticsResponse> {
    return this.baseApi.get(`/api/v1/student/statistics/${studentId}`);
  }
}
