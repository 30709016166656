import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';

const endpoints = {
  approve: (imageId: string) => `/api/v1/attendance_images/${imageId}/approve`,
  reject: (imageId: string) => `/api/v1/attendance_images/${imageId}/reject`,
};

export class AttendanceImagesAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public approve(imageId: string): ApiRequestResult {
    return this.baseApi.put(endpoints.approve(imageId));
  }

  public reject(imageId: string): ApiRequestResult {
    return this.baseApi.put(endpoints.reject(imageId));
  }
}
