import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';
import { UnmaintainedScheduleEventData } from 'src/core/entities/UnmaintainedScheduleEvent';

const endpoints = {
  fetchByInstructorId: (instructorId: string) =>
    `/api/v1/instructors/${instructorId}/unmaintained-lessons`,
};

export class UnmaintainedScheduleEventAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchByInstructorId(
    instructorId: string
  ): ApiRequestResult<UnmaintainedScheduleEventData[]> {
    return this.baseApi.get(endpoints.fetchByInstructorId(instructorId));
  }
}
