import { SNA_BACKEND_BASE_URL } from 'src/constants';

type ProfilePictureType = 'thumbnail' | 'full';

interface ProfilePictureOptions {
  type?: ProfilePictureType;
}

export const getStudentProfilePicture = (
  studentId: string,
  { type = 'thumbnail' }: ProfilePictureOptions = {}
): string | null => {
  if (!SNA_BACKEND_BASE_URL) {
    return null;
  }

  try {
    const url = new URL(`/${studentId}/profile-picture`, SNA_BACKEND_BASE_URL);

    if (type === 'thumbnail') {
      url.searchParams.set('filters[height]', '150');
      url.searchParams.set('filters[width]', '150');
    }

    return url.toString();
  } catch {
    return null;
  }
};
