import { action, makeObservable, observable, when } from 'mobx';
import { InstructorData } from 'src/core/entities/Instructor';
import { UserData, WhoAmIData } from 'src/core/entities/User';
import {
  FailedRequestResult,
  RequestResult,
  SuccessRequestResult,
} from 'src/types';

type StoreName = (typeof OBJECT_STORE_NAME)[keyof typeof OBJECT_STORE_NAME];

const DB_NAME = 'Tacho_data';
const DB_VERSION = 1;

const OBJECT_STORE_NAME = {
  USERS: 'Users',
  INSTRUCTORS: 'Instructors',
} as const;

const storeNames = Object.keys(OBJECT_STORE_NAME).map(
  (key) => OBJECT_STORE_NAME[key]
);

class IndexedDBService {
  private connection: IDBOpenDBRequest;
  @observable private initialized: boolean;
  @observable private initializationFailed: boolean;

  constructor() {
    makeObservable(this);

    this.initialized = false;
    this.initializationFailed = false;

    if ('indexedDB' in window) {
      this.connection = indexedDB.open(DB_NAME, DB_VERSION);

      this.connection.onupgradeneeded = (): void => {
        const db = this.connection.result;

        const existedStores = db.objectStoreNames;
        const newStores = storeNames.filter(
          (name) => !existedStores.contains(name)
        );

        newStores.forEach((name) => {
          db.createObjectStore(name, { keyPath: 'id' });
        });
      };

      this.connection.onerror = action((): void => {
        this.initializationFailed = true;
      });

      this.connection.onsuccess = action((): void => {
        this.initialized = true;
      });
    } else {
      this.initializationFailed = true;
    }
  }

  public async clear(): Promise<void> {
    await when(() => this.initialized || this.initializationFailed);

    if (this.initializationFailed) {
      return;
    }

    const transaction = this.connection.result.transaction(
      storeNames,
      'readwrite'
    );

    storeNames.forEach((name) => {
      const store = transaction.objectStore(name);

      store.clear();
    });
  }

  public async getCurrentUser(): Promise<RequestResult<WhoAmIData>> {
    try {
      const user = await this.getOne<UserData>(OBJECT_STORE_NAME.USERS);

      const result: SuccessRequestResult<WhoAmIData> = {
        success: true,
        data: { user },
      };

      return result;
    } catch (error) {
      const result: FailedRequestResult = {
        success: false,
      };

      return result;
    }
  }

  public async setCurrentUser(user: UserData): Promise<void> {
    this.handleRequest(this.addValues(OBJECT_STORE_NAME.USERS, user));
  }

  public async getCurrentInstructor(): Promise<RequestResult<InstructorData>> {
    return this.handleRequest(this.getOne(OBJECT_STORE_NAME.INSTRUCTORS));
  }

  public async setCurrentInstructor(instructor: InstructorData): Promise<void> {
    this.handleRequest(
      this.addValues(OBJECT_STORE_NAME.INSTRUCTORS, instructor)
    );
  }

  public async updateCurrentInstructor(
    instructor: InstructorData
  ): Promise<void> {
    this.handleRequest(
      this.putValues(OBJECT_STORE_NAME.INSTRUCTORS, instructor)
    );
  }

  private async getValues<T>(storeName: StoreName): Promise<T[]> {
    await when(() => this.initialized || this.initializationFailed);

    if (this.initializationFailed) {
      return Promise.reject();
    }

    return new Promise((res, rej) => {
      const transaction = this.connection.result.transaction(
        storeName,
        'readonly'
      );

      transaction.onerror = () => rej();

      const store = transaction.objectStore(storeName);

      const request = store.getAll();

      if (!request) {
        rej();
      }

      request.onsuccess = () => {
        if (request.result.length === 0) {
          rej();
        }

        res(request.result);
      };
    });
  }

  private async getOne<T>(storeName: StoreName): Promise<T> {
    const data = await this.getValues<T>(storeName);

    return data[0];
  }

  private async addValues<T>(
    storeName: StoreName,
    data: T | T[]
  ): Promise<void> {
    await when(() => this.initialized || this.initializationFailed);

    if (this.initializationFailed) {
      return;
    }

    return new Promise((res, rej) => {
      const transaction = this.connection.result.transaction(
        storeName,
        'readwrite'
      );

      transaction.onerror = () => rej();

      const store = transaction.objectStore(storeName);
      if (Array.isArray(data)) {
        data.forEach((value) => {
          store.add(value);
        });
      } else {
        store.add(data);
      }

      res();
    });
  }

  private async putValues<T>(
    storeName: StoreName,
    data: T | T[]
  ): Promise<void> {
    await when(() => this.initialized || this.initializationFailed);

    if (this.initializationFailed) {
      return;
    }

    return new Promise((res, rej) => {
      const transaction = this.connection.result.transaction(
        storeName,
        'readwrite'
      );

      transaction.onerror = () => rej();

      const store = transaction.objectStore(storeName);
      if (Array.isArray(data)) {
        data.forEach((value) => {
          store.put(value);
        });
      } else {
        store.put(data);
      }

      res();
    });
  }

  private async handleRequest<T>(
    request: Promise<T>
  ): Promise<RequestResult<T>> {
    try {
      const data = await request;

      const result: SuccessRequestResult<T> = {
        success: true,
        data,
      };

      return result;
    } catch (error) {
      const result: FailedRequestResult = {
        success: false,
      };

      return result;
    }
  }
}

export default IndexedDBService;
