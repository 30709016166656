import { tachoAPI } from 'src/App';
import { getCurrentDate, getDayEnd } from 'src/utils/time';

import { useQuery } from '@tanstack/react-query';

import { EVENT_STATUS, ScheduleEvent } from '..';

export const useTachoNextWorkDayScheduleEventsQuery = (instructorId?: string) =>
  useQuery({
    enabled: Boolean(instructorId),
    initialData: [],
    queryKey: ['events', 'tacho', 'next workday'],
    queryFn: async () => {
      const result = await tachoAPI.scheduleEventsAPI.fetchAll({
        'attendees.instructor': instructorId,
        itemsPerPage: 1,
        pagination: true,
        'order[startDate]': 'asc',
        status: [EVENT_STATUS.FREE, EVENT_STATUS.RESERVED, EVENT_STATUS.BOOKED],
        'startDate[after]': getDayEnd(getCurrentDate()),
      });

      if (result.success) {
        return result.data.map(ScheduleEvent.fromTachoData);
      }

      return [];
    },
  });
