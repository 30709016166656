import { queryClient } from 'src/App';
import { getWeekStart } from 'src/utils/time';

import { ScheduleEventData } from '../ScheduleEvent';
import { CalendarScheduleEvent } from '.';

const getQueryKey = (eventStartDate?: Date | string) => [
  {
    api: 'tacho',
    scope: 'events',
    type: 'calendar',
    ...(eventStartDate && { weekStart: getWeekStart(eventStartDate) }),
  },
];

export const removeTachoCalendarEvent = (eventId: string): void => {
  queryClient.setQueriesData<{ id: string }[]>(
    { queryKey: getQueryKey() },
    (data) => data?.filter(({ id }) => id !== eventId)
  );
};

export const addTachoCalendarEvent = (eventData: ScheduleEventData): void => {
  const newEvent = CalendarScheduleEvent.fromTachoData(eventData);

  queryClient.setQueriesData<{ id: string }[]>(
    { queryKey: getQueryKey(newEvent.startDate) },
    (data) => [...(data || []), newEvent]
  );
};

export const updateTachoCalendarEvent = (
  eventData: ScheduleEventData
): void => {
  removeTachoCalendarEvent(eventData.id);
  addTachoCalendarEvent(eventData);
};
