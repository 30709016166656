import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';
import { StudentData, StudentRequestParams } from 'src/core/entities/Student';

const endpoints = {
  fetchAll: () => '/api/v1/students',
  fetchById: (studentId: string) => `/api/v1/students/${studentId}`,
};

export class StudentsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchAll(
    params?: StudentRequestParams
  ): ApiRequestResult<StudentData[]> {
    return this.baseApi.get(endpoints.fetchAll(), {
      params,
    });
  }

  public fetchById(studentId: string): ApiRequestResult<StudentData> {
    return this.baseApi.get(endpoints.fetchById(studentId));
  }
}
