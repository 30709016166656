import { dlmAPI } from 'src/App';

import { useQuery } from '@tanstack/react-query';

export const useDrivingActivityQuery = (studentEducationId?: string) =>
  useQuery({
    enabled: Boolean(studentEducationId),
    queryKey: [
      {
        api: 'dlm',
        scope: 'driving-activity',
        studentEducationId,
      },
    ],
    queryFn: async () => {
      const result = await dlmAPI.drivingActivityAPI.fetchByStudentEducationId(
        studentEducationId!
      );

      if (result.success) {
        return result.data;
      }

      return null;
    },
  });
