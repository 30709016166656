import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100vw',
      minHeight: '100vh',

      '&.withHeaderAppBar': {
        paddingTop: theme.dimensions.APP_BAR_HEIGHT_SM,
      },
      '&.withBottomNavBar': {
        paddingBottom: theme.dimensions.NAVIGATION_HEIGHT_SM,
      },
    },
  });

export default styles;
