import {
  APP_NAME_API_HEADER,
  DEFAULT_ACCEPT_LANGUAGE_HEADER,
} from 'src/constants';
import { BaseAPI } from 'src/core/api/BaseAPI';

import { InstructorsAPI } from './InstructorsAPI';
import { PracticeProtocolAPI } from './PracticeProtocolAPI';
import { PracticeProtocolHistoryAPI } from './PracticeProtocolHistoryAPI';
import { StudentEducationsAPI } from './StudentEducationsAPI';
import { StudentStatisticsAPI } from './StudentStatisticsAPI';

export class IspaAPI extends BaseAPI {
  public instructorsAPI: InstructorsAPI;
  public practiceProtocolAPI: PracticeProtocolAPI;
  public practiceProtocolHistoryAPI: PracticeProtocolHistoryAPI;
  public studentEducationsAPI: StudentEducationsAPI;
  public studentStatisticsAPI: StudentStatisticsAPI;

  constructor() {
    super(process.env.REACT_APP_ISPA_BACKEND_BASE_URL);

    this.addRequestInterceptors((config) => {
      config.headers['X-Fhr-Client'] = APP_NAME_API_HEADER;
      config.headers['Accept-Language'] = DEFAULT_ACCEPT_LANGUAGE_HEADER;

      return config;
    });

    this.instructorsAPI = new InstructorsAPI(this);
    this.practiceProtocolAPI = new PracticeProtocolAPI(this);
    this.practiceProtocolHistoryAPI = new PracticeProtocolHistoryAPI(this);
    this.studentEducationsAPI = new StudentEducationsAPI(this);
    this.studentStatisticsAPI = new StudentStatisticsAPI(this);
  }
}

export default IspaAPI;
