import { action, computed, makeObservable, observable } from 'mobx';

import {
  PracticeProtocolItemData,
  PracticeProtocolItemLevel,
} from './PracticeProtocol.types';

export class PracticeProtocolItem {
  readonly id: string;
  readonly name: string;
  readonly considerInStatistic: boolean;

  newLevel: PracticeProtocolItemLevel;

  private readonly initialLevel: PracticeProtocolItemLevel;

  constructor({
    id,
    name,
    level,
    consider_in_statistic,
  }: PracticeProtocolItemData) {
    makeObservable(this, {
      newLevel: observable,
      level: computed,
      changed: computed,
      irrelevant: computed,
      setLevel: action,
    });

    this.id = id;
    this.name = name;
    this.initialLevel = level;
    this.considerInStatistic = consider_in_statistic;
  }

  get level(): PracticeProtocolItemLevel {
    return Number.isFinite(this.newLevel) ? this.newLevel : this.initialLevel;
  }

  get changed(): boolean {
    return Boolean(
      Number.isFinite(this.newLevel) && this.newLevel !== this.initialLevel
    );
  }

  get irrelevant(): boolean {
    return !this.considerInStatistic;
  }

  setLevel(level: number): void {
    this.newLevel = level as PracticeProtocolItemLevel;
  }

  resetLevel = (): void => {
    this.newLevel = 0;
  };
}
