import { action, computed, makeObservable, observable } from 'mobx';

import { Company } from '../Company';
import { DrivingLessonCategory } from '../DrivingLessonCategory';
import { MediaObject } from '../MediaObject';
import { PickUpPoint } from '../PickUpPoint';
import { School } from '../School';
import { InstructorData } from './Instructor.types';

export class Instructor {
  public readonly id: string;

  @observable public email: string;
  @observable public firstName: string;
  @observable public lastName: string;
  @observable public phone: string;
  @observable public profileImage: MediaObject | null;
  @observable public schools: School[];
  @observable
  public preferredDrivingLessonCategory: DrivingLessonCategory | null;
  @observable public preferredPickUpPoint: PickUpPoint | null;
  @observable public company: Company;

  constructor({
    id = '',
    email = '',
    firstName = '',
    lastName = '',
    phone = '',
    profileImage,
    schools,
    preferredDrivingLessonCategory,
    preferredPickUpPoint,
    company,
  }: InstructorData) {
    makeObservable(this);

    this.id = id;

    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.profileImage = profileImage;
    this.schools = schools;
    this.preferredDrivingLessonCategory = preferredDrivingLessonCategory;
    this.preferredPickUpPoint = preferredPickUpPoint;
    this.company = company;
  }

  @computed
  public get avatar(): string | null | undefined {
    return this.profileImage?.contentUrl;
  }

  @computed
  public get avatarThumbnail(): string | null | undefined {
    return this.profileImage?.filters.media_thumbnail_small;
  }

  @action
  updateFromResponse({
    profileImage,
    preferredDrivingLessonCategory,
    preferredPickUpPoint,
  }: InstructorData) {
    this.profileImage = profileImage;
    this.preferredDrivingLessonCategory = preferredDrivingLessonCategory;
    this.preferredPickUpPoint = preferredPickUpPoint;
  }
}

export default Instructor;
