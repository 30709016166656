import { dlmAPI } from 'src/App';

import { useMutation } from '@tanstack/react-query';

import { MaintainDrivingLessonData } from '../DrivingLesson';

interface Data extends MaintainDrivingLessonData {
  lessonId: string;
}

export const useMaintainDrivingLessonMutation = () =>
  useMutation({
    mutationFn: async ({ lessonId, types }: Data) =>
      dlmAPI.drivingLessonsAPI.maintain(lessonId, { types }),
  });
