import { AppAPI, DLMServiceAPI, IspaAPI, TachoAPI } from 'src/core/api';

import IndexedDBService from '../services/IndexedDBService';
import INASocket from '../socket/ina';
import ApplicationStore from './ApplicationStore';
import CalendarScheduleEventsStore from './CalendarScheduleEventsStore';
import DrivingLessonCategoriesStore from './DrivingLessonCategoriesStore';
import DrivingTypesStore from './DrivingTypesStore';
import EditableScheduleEventsStore from './EditableScheduleEventsStore';
import EditingDrivingLessonStore from './EditingDrivingLessonStore';
import { EmitterStore } from './EmitterStore';
import EventScheduleStore from './EventScheduleStore';
import InstructorCommentsStore from './InstructorCommentsStore';
import InstructorsStore from './InstructorsStore';
import InstructorStudentsStore from './InstructorStudentsStore';
import ManualPresenceStore from './ManualPresenceStore';
import NewEventStore from './NewEventStore';
import NotificationsStore from './NotificationsStore';
import PickUpPointsStore from './PickUpPointsStore';
import PracticeProtocolHistoryStore from './PracticeProtocolHistoryStore';
import PracticeProtocolStore from './PracticeProtocolStore';
import StudentEducationsStore from './StudentEducationsStore';
import StudentProfilePictureStore from './StudentProfilePictureStore';
import TimesStore from './TimesStore';
import UiStore from './UiStore';
import UserStore from './UserStore';

class RootStore {
  public applicationStore: ApplicationStore;
  public calendarScheduleEventsStore: CalendarScheduleEventsStore;
  public drivingLessonCategoriesStore: DrivingLessonCategoriesStore;
  public drivingTypesStore: DrivingTypesStore;
  public editableScheduleEventsStore: EditableScheduleEventsStore;
  public editingDrivingLessonStore: EditingDrivingLessonStore;
  public emitterStore: EmitterStore;
  public eventScheduleStore: EventScheduleStore;
  public instructorCommentsStore: InstructorCommentsStore;
  public instructorsStore: InstructorsStore;
  public instructorStudentsStore: InstructorStudentsStore;
  public manualPresenceStore: ManualPresenceStore;
  public newEventStore: NewEventStore;
  public notificationsStore: NotificationsStore;
  public pickUpPointsStore: PickUpPointsStore;
  public practiceProtocolHistoryStore: PracticeProtocolHistoryStore;
  public practiceProtocolStore: PracticeProtocolStore;
  public studentEducationsStore: StudentEducationsStore;
  public studentProfilePictureStore: StudentProfilePictureStore;
  public timesStore: TimesStore;
  public uiStore: UiStore;
  public userStore: UserStore;

  private INASocket: INASocket;

  constructor(
    private readonly appAPI: AppAPI,
    private readonly ispaAPI: IspaAPI,
    private readonly tachoAPI: TachoAPI,
    private readonly dlmAPI: DLMServiceAPI,
    private readonly localDB: IndexedDBService
  ) {
    this.userStore = new UserStore(
      this,
      this.ispaAPI,
      this.tachoAPI,
      this.dlmAPI,
      this.tachoAPI.userAPI,
      this.tachoAPI.mediaObjectsAPI,
      this.localDB
    );
    this.applicationStore = new ApplicationStore(
      this,
      this.appAPI,
      this.ispaAPI.instructorsAPI
    );

    this.INASocket = new INASocket(this.userStore);

    this.instructorsStore = new InstructorsStore(
      this,
      this.tachoAPI.instructorsAPI,
      this.ispaAPI.instructorsAPI
    );
    this.instructorStudentsStore = new InstructorStudentsStore(
      this.userStore,
      this.ispaAPI.instructorsAPI,
      this.INASocket
    );
    this.uiStore = new UiStore();

    this.calendarScheduleEventsStore = new CalendarScheduleEventsStore(
      this,
      this.tachoAPI.calendarScheduleEventsAPI,
      this.dlmAPI.drivingLessonsAPI
    );
    this.drivingLessonCategoriesStore = new DrivingLessonCategoriesStore(
      this,
      this.tachoAPI.drivingLessonCategoriesAPI
    );
    this.drivingTypesStore = new DrivingTypesStore(
      this.tachoAPI.drivingLessonTypesAPI,
      this.dlmAPI.drivingLessonsAPI
    );
    this.editableScheduleEventsStore = new EditableScheduleEventsStore(
      this,
      this.tachoAPI.scheduleEventsAPI,
      this.tachoAPI.attendeesAPI
    );
    this.emitterStore = new EmitterStore();
    this.eventScheduleStore = new EventScheduleStore(
      this,
      this.tachoAPI.scheduleEventsAPI,
      this.tachoAPI.attendeesAPI,
      this.tachoAPI.drivingLessonTypesAPI,
      this.tachoAPI.attendanceImagesAPI
    );
    this.editingDrivingLessonStore = new EditingDrivingLessonStore(
      this,
      this.tachoAPI.scheduleEventsAPI,
      this.tachoAPI.attendeesAPI
    );
    this.instructorCommentsStore = new InstructorCommentsStore(
      this,
      this.tachoAPI.instructorCommentsAPI
    );
    this.manualPresenceStore = new ManualPresenceStore();
    this.newEventStore = new NewEventStore(
      this,
      this.tachoAPI.scheduleEventsAPI,
      this.dlmAPI.drivingLessonsAPI
    );
    this.notificationsStore = new NotificationsStore();
    this.pickUpPointsStore = new PickUpPointsStore(
      this,
      this.tachoAPI.pickUpPointsAPI
    );
    this.practiceProtocolHistoryStore = new PracticeProtocolHistoryStore(
      this.ispaAPI.practiceProtocolHistoryAPI
    );

    this.studentEducationsStore = new StudentEducationsStore(
      this.ispaAPI.studentEducationsAPI
    );
    this.studentProfilePictureStore = new StudentProfilePictureStore();

    this.practiceProtocolStore = new PracticeProtocolStore(
      this,
      this.ispaAPI.practiceProtocolAPI,
      this.tachoAPI.practiceProtocolAPI
    );

    this.timesStore = new TimesStore(this, this.tachoAPI.schoolsAPI);
  }
}

export default RootStore;
