import { dlmAPI } from 'src/App';

import { useQuery } from '@tanstack/react-query';

import { LESSON_STATUS } from '../../DLMService/DrivingLesson';
import CalendarScheduleEvent from '../CalendarScheduleEvent.model';

interface Variables {
  enabled: boolean;
  startDate: string;
  endDate: string;
}
export const useDLMCalendarEventsQuery = ({
  enabled,
  startDate,
  endDate,
}: Variables) =>
  useQuery({
    enabled,
    queryKey: [
      {
        api: 'dlm',
        scope: 'events',
        type: 'calendar',
        startDate,
        endDate,
      },
    ],
    queryFn: async ({ signal }) => {
      const result = await dlmAPI.drivingLessonsAPI.fetchAll(
        {
          page_size: 100,
          start_time: startDate,
          end_time: endDate,
          status: [
            LESSON_STATUS.OPEN,
            LESSON_STATUS.REQUESTED,
            LESSON_STATUS.SCHEDULED,
            LESSON_STATUS.FINISHED,
            LESSON_STATUS.COMPLETED,
          ],
        },
        signal
      );

      if (result.success) {
        return result.data.data.map(CalendarScheduleEvent.fromDLMData);
      }

      return [];
    },
    staleTime: 10 * 1000, // 10 seconds
    refetchInterval: 5 * 60 * 1000, // 5 minutes
  });
