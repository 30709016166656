import {
  APP_NAME_API_HEADER,
  DEFAULT_ACCEPT_LANGUAGE_HEADER,
} from 'src/constants';
import { BaseAPI } from 'src/core/api/BaseAPI';
import IndexedDBService from 'src/core/services/IndexedDBService';

import { AttendanceImagesAPI } from './AttendanceImagesAPI';
import { AttendeesAPI } from './AttendeesAPI';
import { CalendarScheduleEventsAPI } from './CalendarScheduleEventsAPI';
import { DrivingLessonCategoriesAPI } from './DrivingLessonCategoriesAPI';
import { DrivingLessonTypesAPI } from './DrivingLessonTypesAPI';
import { InstructorCommentsAPI } from './InstructorCommentsAPI';
import { InstructorsAPI } from './InstructorsAPI';
import { MediaObjectsAPI } from './MediaObjectsAPI';
import { PickUpPointsAPI } from './PickUpPointsAPI';
import { PracticeProtocolAPI } from './PracticeProtocolAPI';
import { ScheduleEventsAPI } from './ScheduleEventsAPI';
import { SchoolsAPI } from './SchoolsAPI';
import { StudentsAPI } from './StudentsAPI';
import { StudentStatisticsAPI } from './StudentStatisticsAPI';
import { UnmaintainedScheduleEventAPI } from './UnmaintainedScheduleEventAPI';
import { UserAPI } from './UserAPI';

export class TachoAPI extends BaseAPI {
  public attendanceImagesAPI: AttendanceImagesAPI;
  public attendeesAPI: AttendeesAPI;
  public calendarScheduleEventsAPI: CalendarScheduleEventsAPI;
  public drivingLessonCategoriesAPI: DrivingLessonCategoriesAPI;
  public drivingLessonTypesAPI: DrivingLessonTypesAPI;
  public instructorCommentsAPI: InstructorCommentsAPI;
  public instructorsAPI: InstructorsAPI;
  public mediaObjectsAPI: MediaObjectsAPI;
  public pickUpPointsAPI: PickUpPointsAPI;
  public practiceProtocolAPI: PracticeProtocolAPI;
  public scheduleEventsAPI: ScheduleEventsAPI;
  public schoolsAPI: SchoolsAPI;
  public studentsAPI: StudentsAPI;
  public studentStatisticsAPI: StudentStatisticsAPI;
  public unmaintainedScheduleEventAPI: UnmaintainedScheduleEventAPI;
  public userAPI: UserAPI;

  constructor(private readonly localDB: IndexedDBService) {
    super(process.env.REACT_APP_TACHO_BASE_URL);

    this.addRequestInterceptors((config) => {
      config.headers['X-Fhr-Client'] = APP_NAME_API_HEADER;
      config.headers['Accept-Language'] = DEFAULT_ACCEPT_LANGUAGE_HEADER;

      return config;
    });

    this.attendanceImagesAPI = new AttendanceImagesAPI(this);
    this.attendeesAPI = new AttendeesAPI(this);
    this.calendarScheduleEventsAPI = new CalendarScheduleEventsAPI(this);
    this.drivingLessonCategoriesAPI = new DrivingLessonCategoriesAPI(this);
    this.drivingLessonTypesAPI = new DrivingLessonTypesAPI(this);
    this.instructorCommentsAPI = new InstructorCommentsAPI(this);
    this.instructorsAPI = new InstructorsAPI(this, this.localDB);
    this.mediaObjectsAPI = new MediaObjectsAPI(this);
    this.pickUpPointsAPI = new PickUpPointsAPI(this);
    this.practiceProtocolAPI = new PracticeProtocolAPI(this);
    this.scheduleEventsAPI = new ScheduleEventsAPI(this);
    this.schoolsAPI = new SchoolsAPI(this);
    this.studentsAPI = new StudentsAPI(this);
    this.studentStatisticsAPI = new StudentStatisticsAPI(this);
    this.unmaintainedScheduleEventAPI = new UnmaintainedScheduleEventAPI(this);
    this.userAPI = new UserAPI(this, this.localDB);
  }
}

export default TachoAPI;
