import { DLM_SERVICE_BASE_URL } from 'src/constants';
import { BaseAPI } from 'src/core/api/BaseAPI';

import { DrivingActivityAPI } from './DrivingActivityAPI';
import { DrivingLessonsAPI } from './DrivingLessonsAPI';
import { LessonDefinitionsAPI } from './LessonDefinitionsAPI';
import { PickUpPointsAPI } from './PickUpPointsAPI';

export class DLMServiceAPI extends BaseAPI {
  drivingLessonsAPI: DrivingLessonsAPI;
  lessonDefinitionsAPI: LessonDefinitionsAPI;
  pickUpPointsAPI: PickUpPointsAPI;
  drivingActivityAPI: DrivingActivityAPI;

  constructor() {
    super(DLM_SERVICE_BASE_URL);

    this.addRequestInterceptors((config) => {
      config.headers['Accept-Language'] = 'de-DE';

      return config;
    });

    this.drivingLessonsAPI = new DrivingLessonsAPI(this);
    this.lessonDefinitionsAPI = new LessonDefinitionsAPI(this);
    this.pickUpPointsAPI = new PickUpPointsAPI(this);
    this.drivingActivityAPI = new DrivingActivityAPI(this);
  }
}

export default DLMServiceAPI;
