import { dlmAPI } from 'src/App';
import { isPastDate } from 'src/utils/time';

import { useQuery } from '@tanstack/react-query';

import { ScheduleEvent } from '..';
import { LESSON_STATUS } from '../../DLMService';

export const useStudentDLMScheduleEvents = (
  studentId: string,
  enabled: boolean
) =>
  useQuery({
    enabled,
    initialData: [],
    queryKey: ['events', 'dlm', { studentId }],
    queryFn: async () => {
      const result = await dlmAPI.drivingLessonsAPI.fetchAll({
        page_size: 200,
        student_id: studentId,
      });

      if (result.success) {
        return result.data.data
          .filter(
            (lesson) =>
              !(
                isPastDate(lesson.start_time) &&
                lesson.status === LESSON_STATUS.REQUESTED
              )
          )
          .map(ScheduleEvent.fromDLMData);
      }

      return [];
    },
  });
