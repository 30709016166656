export const APP_NAME_API_HEADER = 'ispa';
export const DEFAULT_ACCEPT_LANGUAGE_HEADER = 'de_DE';
export const MAX_AVATAR_PICTURE_SIZE = 10485760;
export const STUDENT_ABSENT_CHECK_DELAY = 60000;
export const OFFICE_PHONE = '+49 800 999 88 66';
export const LESSON_START_CHECK_DELAY = 60000;
export const GERMANY_DIALING_CODE = '+49';

export const DEFAULT_DRIVING_LESSON_DURATION = 45;
export const DRIVING_LESSON_DURATION = 45;
export const MAX_DRIVING_LESSON_DURATION = DRIVING_LESSON_DURATION * 3;
export const BLOCKER_DEFAULT_DURATION = 450;

export const dateFormats = {
  defaultDateFormat: 'DD.MM.YYYY',
  defaultTimeFormat: 'HH:mm',
  shortDate: 'DD.MM.YY',
  loadingStatus: 'DD-MM-YYYY',
  failedSlots: 'MMM D',
  copySlotDate: 'DD.MM',
  weekPickerDay: 'D',
  fullDateWithTime: 'DD.MM.YYYY, HH:mm',
} as const;

export const PRACTICE_PROTOCOL_MIN_ITEM_LEVEL = 0;
export const PRACTICE_PROTOCOL_MAX_ITEM_LEVEL = 3;
export const PRACTICE_PROTOCOL_ITEM_LEVEL_STEP = 1;
export const PRACTICE_PROTOCOL_ITEM_LEVELS = [1, 2, 3];

export const DEFAULT_DRIVING_LESSON_CATEGORY_CHARGER_ID = 1;

export const ATTENDANCE_CODES_LENGTH = 4;

export const GOOGLE_MAP_DEFAULT_ZOOM = 16;
export const GOOGLE_MAP_DEFAULT_CENTER = {
  lat: 50.9532913,
  lng: 6.903111,
};

export const HTTP_STATUS_CODE = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  SERVICE_UNAVAILABLE: 503,
} as const;

export const DEFAULT_TIME_ZONE = 'CET';

export const HALF_DAY_VACATION_DURATION = 225;

export const SNA_BACKEND_BASE_URL = process.env.REACT_APP_SNA_BACKEND_BASE_URL;

if (!process.env.REACT_APP_DLM_SERVICE_BASE_URL) {
  throw new Error(
    '"REACT_APP_DLM_SERVICE_BASE_URL" environment variable is required'
  );
}

export const DLM_SERVICE_BASE_URL = process.env.REACT_APP_DLM_SERVICE_BASE_URL;
