interface VehicleFeature {
  id: string;
  short_code: string;
}

export interface LessonDefinition {
  id: string;
  label: string;
  vehicle_features: VehicleFeature[];
}

export interface LessonDefinitionParams {
  studentEducationId?: string;
}

export const LESSON_DEFINITION_COLOR = {
  'de.123fahrschule:driving-education:lesson-definition:b-manual': '#00a7a9',
  'de.123fahrschule:driving-education:lesson-definition:b-automatic': '#73dda5',
  'de.123fahrschule:driving-education:lesson-definition:a-motorcycle':
    '#e5d86f',
  'de.123fahrschule:driving-education:lesson-definition:moped': '#ffb347',
  'de.123fahrschule:driving-education:lesson-definition:b-trailer': '#77d0e0',
} as const;
