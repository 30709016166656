import { action, makeObservable, observable } from 'mobx';

import { StudentProfilePicture } from '../entities/StudentProfilePicture';

class StudentProfilePictureStore {
  studentProfilePictures: Record<string, StudentProfilePicture | undefined>;

  constructor() {
    makeObservable(this, {
      studentProfilePictures: observable,
      getByStudentId: action,
    });

    this.studentProfilePictures = observable({});
  }

  getByStudentId(studentId: string): StudentProfilePicture {
    let profilePicture = this.studentProfilePictures[studentId];

    if (!profilePicture) {
      profilePicture = new StudentProfilePicture(studentId);
      this.studentProfilePictures[studentId] = profilePicture;
    }

    return profilePicture;
  }
}

export default StudentProfilePictureStore;
