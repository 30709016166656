import { BaseEntity, DateString } from 'src/types';

import { AttendeeData, BaseAttendeePersonData } from '../Attendee';
import { DrivingLessonCategoryData } from '../DrivingLessonCategory';
import {
  BaseScheduleEventData,
  ScheduleEventStatus,
  ScheduleEventType,
} from '../ScheduleEvent';
import { TheoryLessonTopicData } from '../TheoryLessonTopic';

export type CalendarEventTheoryTopic = Pick<
  TheoryLessonTopicData,
  'id' | 'number'
>;

type CalendarEventAttendeeProperties =
  | 'id'
  | 'cancelledAt'
  | 'absentAt'
  | 'reservationExpiresAt'
  | 'reservationConfirmedAt'
  | 'cancelledByAdministrator';

type BaseCalendarAttendee = Pick<AttendeeData, CalendarEventAttendeeProperties>;

export type CalendarEventInstructorAttendee = BaseCalendarAttendee & {
  instructor: BaseEntity;
  student: null;
  vacationApprovedAt: DateString | null;
};

export type CalendarEventStudentAttendee = BaseCalendarAttendee & {
  instructor: null;
  student: BaseAttendeePersonData;
};

export type CalendarEventAttendee =
  | CalendarEventInstructorAttendee
  | CalendarEventStudentAttendee;

export interface CalendarEventResourceData {
  classroom: {
    id: string;
    school: {
      id: string;
      name: string;
    };
  } | null;
  pickUpPoint: {
    id: string;
    name: string;
  } | null;
}

export interface CalendarScheduleEventData extends BaseScheduleEventData {
  attendees: CalendarEventAttendee[];
  resource: CalendarEventResourceData | null;
  topic: CalendarEventTheoryTopic | null;
  title: string | null;
  category: DrivingLessonCategoryData | null;
  description: string | null;
}

export interface CalendarEventRequestParams {
  type?: ScheduleEventType | ScheduleEventType[];
  status?: ScheduleEventStatus | ScheduleEventStatus[];
  'attendees.instructor': string;
  'startDate[before]': DateString | Date;
  'endDate[after]': DateString | Date;
  'public_holiday[startDate]'?: DateString | Date;
  'public_holiday[endDate]'?: DateString | Date;
}

export const isCalendarEventStudentAttendee = (
  attendee: CalendarEventAttendee
): attendee is CalendarEventStudentAttendee => Boolean(attendee.student);

export const isCalendarEventInstructorAttendee = (
  attendee: CalendarEventAttendee
): attendee is CalendarEventInstructorAttendee => Boolean(attendee.instructor);
