import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    search: {
      display: 'flex',
      position: 'relative',
      backgroundColor: 'rgba(255,255,255,0.15)',
      borderRadius: '5px',
      padding: '10px 40px 10px 40px',
      height: 40,
      pointerEvents: 'none',
      color: theme.palette.common.white,
    },
    icon: {
      width: 40,
      height: '100%',
      position: 'absolute',
      top: 0,
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.8,
    },
    searchIcon: {
      left: 0,
    },
    clearIcon: {
      right: 0,
      cursor: 'pointer',
      zIndex: 10,
      pointerEvents: 'auto',

      '&.active': {
        opacity: 1,
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      transition: theme.transitions.create('width'),
      width: 120,
      pointerEvents: 'auto',

      '&:focus': {
        width: 180,
      },
    },
  });

export default styles;
