import { generatePath } from 'react-router';
import { EVENT_TYPE, ScheduleEventType } from 'src/core/entities/ScheduleEvent';

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  INSTRUCTOR_PROFILE: '/profile',
  PREFERRED_PICK_UP_POINT: '/profile/pick-up-point',
  PREFERRED_DLM_PICK_UP_POINT: '/profile/dlm/pick-up-point',
  EVENT_SCHEDULE: '/event-schedule',
  NOT_MAINTAINED_LESSONS: '/not-maintained-lessons',

  ADD_NEW_SLOT: '/add-new-slot',
  ADD_NEW_SLOT_PICKUP_POINTS: '/add-new-slot/pickup-points',
  ADD_NEW_SLOT_STUDENTS: '/add-new-slot/students',

  [EVENT_TYPE.THEORY_LESSON]: '/theory-lesson/:lessonId',
  THEORY_LESSON_ATTENDANCE: '/theory-lesson/:lessonId/attendance',
  THEORY_LESSON_ATTENDANCE_IMAGES:
    '/theory-lesson/:lessonId/attendance-images/:imageId?',
  THEORY_LESSON_ADD_STUDENT: '/theory-lesson/:lessonId/students',

  [EVENT_TYPE.DRIVING_LESSON]: '/driving-lesson/:lessonId',
  DRIVING_LESSON_PICKUP_POINTS: '/driving-lesson/:lessonId/edit/pickup-points',
  EDIT_DRIVING_LESSON: '/driving-lesson/:lessonId/edit',
  DRIVING_LESSON_TYPES: '/driving-lesson/:lessonId/types',
  DRIVING_LESSON_STUDENTS: '/driving-lesson/:lessonId/edit/students',
  DRIVING_LESSON_STUDENT: '/driving-lesson/:lessonId/student/:studentId',
  PRACTICE_PROTOCOL: '/driving-lesson/:lessonId/practice-protocol',
  DRIVING_LESSON_INSTRUCTOR_COMMENTS: '/driving-lesson/:lessonId/comments',

  DLM_DRIVING_LESSON: '/dlm/driving-lesson/:lessonId',
  DLM_DRIVING_LESSON_STUDENT:
    '/dlm/driving-lesson/:lessonId/students/:studentId',
  EDIT_DLM_DRIVING_LESSON: '/dlm/driving-lesson/:lessonId/edit',
  DLM_DRIVING_LESSON_STUDENTS: '/dlm/driving-lesson/:lessonId/edit/students',
  DLM_DRIVING_LESSON_PICKUP_POINTS:
    '/dlm/driving-lesson/:lessonId/edit/pickup-points',
  DLM_DRIVING_LESSON_TYPES: '/dlm/driving-lesson/:lessonId/types',
  DLM_PRACTICE_PROTOCOL:
    '/dlm/driving-lesson/:lessonId/practice-protocol/:studentId/:studentEducationId',
  DLM_DRIVING_LESSON_INSTRUCTOR_COMMENTS:
    '/dlm/driving-lesson/:lessonId/:studentId/comments',

  [EVENT_TYPE.DRIVING_EXAM]: '/driving-exam/:eventId',
  [EVENT_TYPE.MEETING]: '/meeting/:eventId',
  [EVENT_TYPE.OFFICE_ACTIVITY]: '/office-activity/:eventId',
  [EVENT_TYPE.TRAINING]: '/training/:eventId',
  [EVENT_TYPE.VACATION]: '/vacation/:eventId',
  [EVENT_TYPE.SICKNESS]: '/sickness/:eventId',
  [EVENT_TYPE.BLOCKER]: '/blocker/:eventId',
  [EVENT_TYPE.MISC]: '/misc/:eventId',
  DRIVING_EXAM_PRACTICE_PROTOCOL:
    '/driving-exam/:eventId/practice-protocol/:studentEducationId',
  DRIVING_EXAM_INSTRUCTOR_COMMENTS: '/driving-exam/:eventId/comments',

  EDIT_EVENT: {
    [EVENT_TYPE.BLOCKER]: '/blocker/:eventId/edit',
    [EVENT_TYPE.MEETING]: '/meeting/:eventId/edit',
    [EVENT_TYPE.OFFICE_ACTIVITY]: '/office-activity/:eventId/edit',
    [EVENT_TYPE.TRAINING]: '/training/:eventId/edit',
    [EVENT_TYPE.MISC]: '/misc/:eventId/edit',
  },

  STUDENTS_LIST: '/students',
  STUDENT_PAGE: '/students/:studentId',
  BOOKED_LESSONS: '/students/:studentId/booked-lessons',
  STUDENT_PRACTICE_PROTOCOL:
    '/students/:studentId/practice-protocol/:studentEducationId',
  STUDENT_COMMENTS: '/students/:studentId/comments',

  toTheoryLessonPage: (lessonId: string): string =>
    ROUTES[EVENT_TYPE.THEORY_LESSON].replace(/:lessonId$/, lessonId),
  toTheoryLessonAttendance: (lessonId: string): string =>
    ROUTES.THEORY_LESSON_ATTENDANCE.replace(/:lessonId/, lessonId),
  toTheoryLessonAttendanceImages: (
    lessonId: string,
    imageId: string = ''
  ): string =>
    ROUTES.THEORY_LESSON_ATTENDANCE_IMAGES.replace(
      /:lessonId/,
      lessonId
    ).replace(/:imageId\?/, imageId),
  toAddTheoryLessonStudent: (lessonId: string): string =>
    ROUTES.THEORY_LESSON_ADD_STUDENT.replace(/:lessonId/, lessonId),

  toDrivingLessonPage: (lessonId: string): string =>
    ROUTES[EVENT_TYPE.DRIVING_LESSON].replace(/:lessonId$/, lessonId),
  toSelectDrivingLessonPickUpPoint: (lessonId: string): string =>
    ROUTES.DRIVING_LESSON_PICKUP_POINTS.replace(/:lessonId/, lessonId),
  toSelectDrivingLessonStudent: (lessonId: string): string =>
    ROUTES.DRIVING_LESSON_STUDENTS.replace(/:lessonId/, lessonId),
  toEditDrivingLessonPage: (lessonId: string): string =>
    ROUTES.EDIT_DRIVING_LESSON.replace(/:lessonId/, lessonId),
  toDrivingLessonTypesPage: (lessonId: string): string =>
    ROUTES.DRIVING_LESSON_TYPES.replace(/:lessonId/, lessonId),
  toDrivingLessonStudentPage: (lessonId: string, studentId: string): string =>
    ROUTES.DRIVING_LESSON_STUDENT.replace(/:lessonId/, lessonId).replace(
      /:studentId$/,
      studentId
    ),
  toDrivingLessonPracticeProtocol: (eventId: string) =>
    ROUTES.PRACTICE_PROTOCOL.replace(/(:lessonId)/, eventId),
  toDrivingLessonInstructorComments: (eventId: string) =>
    ROUTES.DRIVING_LESSON_INSTRUCTOR_COMMENTS.replace(/(:lessonId)/, eventId),

  toDLMLesson: (lessonId: string): string =>
    generatePath(ROUTES.DLM_DRIVING_LESSON, { lessonId }),
  toDLMLessonStudent: (lessonId: string, studentId: string): string =>
    generatePath(ROUTES.DLM_DRIVING_LESSON_STUDENT, { lessonId, studentId }),
  toEditDLMLesson: (lessonId: string): string =>
    generatePath(ROUTES.EDIT_DLM_DRIVING_LESSON, { lessonId }),
  toSelectDLMDrivingLessonStudent: (lessonId: string) =>
    generatePath(ROUTES.DLM_DRIVING_LESSON_STUDENTS, { lessonId }),
  toSelectDLMDrivingLessonPickUpPoint: (lessonId: string) =>
    generatePath(ROUTES.DLM_DRIVING_LESSON_PICKUP_POINTS, { lessonId }),
  toDLMLessonTypes: (lessonId: string) =>
    generatePath(ROUTES.DLM_DRIVING_LESSON_TYPES, { lessonId }),
  toDLMPracticeProtocol: (
    lessonId: string,
    studentId: string,
    studentEducationId: string
  ) =>
    generatePath(ROUTES.DLM_PRACTICE_PROTOCOL, {
      lessonId,
      studentId,
      studentEducationId,
    }),
  toDLMComments: (lessonId: string, studentId: string) =>
    generatePath(ROUTES.DLM_DRIVING_LESSON_INSTRUCTOR_COMMENTS, {
      lessonId,
      studentId,
    }),

  toEventPage: (type: ScheduleEventType, eventId: string): string | undefined =>
    ROUTES[type] && ROUTES[type].replace(/(:eventId|:lessonId)$/, eventId),
  toDrivingExamPracticeProtocol: (
    eventId: string,
    studentEducationId: string
  ) =>
    ROUTES.DRIVING_EXAM_PRACTICE_PROTOCOL.replace(
      /(:eventId)/,
      eventId
    ).replace(/:studentEducationId/, studentEducationId),
  toDrivingExamInstructorComments: (eventId: string) =>
    ROUTES.DRIVING_EXAM_INSTRUCTOR_COMMENTS.replace(/(:eventId)/, eventId),

  toEditEventPage: (
    type: ScheduleEventType,
    eventId: string
  ): string | undefined =>
    ROUTES.EDIT_EVENT[type] &&
    ROUTES.EDIT_EVENT[type].replace(/(:eventId)/, eventId),

  studentPage: (studentId: string): string =>
    ROUTES.STUDENT_PAGE.replace(/:studentId$/, studentId),
  bookedLessons: (studentId: string): string =>
    ROUTES.BOOKED_LESSONS.replace(/:studentId/, studentId),
  toStudentPracticeProtocol: (
    studentId: string,
    studentEducationId: string
  ): string =>
    ROUTES.STUDENT_PRACTICE_PROTOCOL.replace(/:studentId/, studentId).replace(
      /:studentEducationId/,
      studentEducationId
    ),
  toStudentComments: (studentId: string): string =>
    ROUTES.STUDENT_COMMENTS.replace(/:studentId/, studentId),
};
