import uniqBy from 'lodash/uniqBy';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
} from 'mobx';
import { stringOrDate } from 'react-big-calendar';
import { MAX_DRIVING_LESSON_DURATION } from 'src/constants';
import {
  CalendarEventRequestParams,
  CalendarScheduleEvent,
} from 'src/core/entities/CalendarScheduleEvent';
import { Instructor } from 'src/core/entities/Instructor';
import {
  EVENT_STATUS,
  EVENT_TYPE,
  ScheduleEventType,
} from 'src/core/entities/ScheduleEvent';
import { Result } from 'src/types';
import {
  addMinutesToDate,
  addWeeksToDate,
  getDate,
  getWeekStart,
  isDateAfter,
  isDateBefore,
  isSameWeekDates,
} from 'src/utils/time';

import { DrivingLessonsAPI } from '../api/DLMServiceAPI/DrivingLessonsAPI';
import { CalendarScheduleEventsAPI } from '../api/TachoAPI/CalendarScheduleEventsAPI';
import {
  LESSON_STATUS,
  NewDrivingLessonData,
} from '../entities/DLMService/DrivingLesson';
import RootStore from './RootStore';

type DLMAPI = Pick<DrivingLessonsAPI, 'fetchAll' | 'create'>;

class CalendarScheduleEventsStore {
  @observable public calendarEvents: IObservableArray<CalendarScheduleEvent>;

  constructor(
    private readonly rootStore: RootStore,
    private readonly calendarScheduleEventsAPI: CalendarScheduleEventsAPI,
    private readonly dlmAPI: DLMAPI
  ) {
    makeObservable(this);

    this.calendarEvents = observable.array();
  }

  @computed
  public get drivingLessonsForCopy(): CalendarScheduleEvent[] {
    const weekStart = getWeekStart(getDate(this.currentCalendarDate));

    const allEvents = this.calendarEvents
      .filter((event) => isSameWeekDates(event.startDate, weekStart))
      .filter(({ type }) => type === EVENT_TYPE.DRIVING_LESSON);

    if (this.rootStore.applicationStore.dlmServiceEnabled) {
      return allEvents.filter(({ isDLMLesson }) => isDLMLesson);
    }

    return allEvents.filter(({ isTachoLesson }) => isTachoLesson);
  }

  public async loadDrivingLessonsForCopy(): Promise<void> {
    if (!this.currentInstructor) {
      return;
    }

    const calendarDate = getDate(this.currentCalendarDate);

    const requestParamsStartDate = addMinutesToDate(
      getWeekStart(calendarDate),
      -MAX_DRIVING_LESSON_DURATION
    );

    const requestParamsEndDate = addMinutesToDate(
      getWeekStart(addWeeksToDate(calendarDate, 1)),
      MAX_DRIVING_LESSON_DURATION
    );

    const params: CalendarEventRequestParams = {
      'attendees.instructor': this.currentInstructor.id,
      'startDate[before]': requestParamsEndDate,
      'endDate[after]': requestParamsStartDate,
      type: EVENT_TYPE.DRIVING_LESSON,
      status: [
        EVENT_STATUS.FREE,
        EVENT_STATUS.BOOKED,
        EVENT_STATUS.RESERVED,
        EVENT_STATUS.ABSENT,
      ],
    };

    const events = await this.loadCalendarScheduleEvents(params);

    this.addEventsToDateRange(
      requestParamsStartDate,
      requestParamsEndDate,
      events,
      EVENT_TYPE.DRIVING_LESSON
    );
  }

  @action
  async addEventsToDateRange(
    start: Date,
    end: Date,
    events: CalendarScheduleEvent[],
    typeFilter?: ScheduleEventType
  ): Promise<void> {
    const filteredEvents = this.calendarEvents.filter(
      ({ startDate, endDate, type }) =>
        !(isDateBefore(startDate, end) && isDateAfter(endDate, start)) ||
        (typeFilter && type !== typeFilter)
    );
    this.calendarEvents.replace(uniqBy([...events, ...filteredEvents], 'id'));
  }

  @computed
  public get currentCalendarDate(): stringOrDate {
    return this.rootStore.uiStore.currentCalendarDate;
  }

  @computed
  private get currentInstructor(): Instructor | undefined | null {
    return this.rootStore.instructorsStore.currentInstructor;
  }

  // API requests
  @action
  private async loadCalendarScheduleEvents(
    params: CalendarEventRequestParams
  ): Promise<CalendarScheduleEvent[]> {
    if (this.rootStore.applicationStore.dlmServiceEnabled) {
      const tachoResponse = await this.calendarScheduleEventsAPI.fetchAll({
        ...params,
      });

      const dlmResponse = await this.dlmAPI.fetchAll({
        page_size: 100,
        start_time: params['endDate[after]'],
        end_time: params['startDate[before]'],
        status: [
          LESSON_STATUS.OPEN,
          LESSON_STATUS.REQUESTED,
          LESSON_STATUS.SCHEDULED,
          LESSON_STATUS.FINISHED,
          LESSON_STATUS.COMPLETED,
        ],
      });

      const tachoEvents = tachoResponse.success
        ? tachoResponse.data.map(CalendarScheduleEvent.fromTachoData)
        : [];
      const dlmEvents = dlmResponse.success
        ? dlmResponse.data.data.map(CalendarScheduleEvent.fromDLMData)
        : [];

      return [...tachoEvents, ...dlmEvents];
    }

    const result = await this.calendarScheduleEventsAPI.fetchAll(params);

    if (result.success) {
      const events = result.data.map((data) =>
        CalendarScheduleEvent.fromTachoData(data)
      );

      return events;
    }

    return [];
  }

  async copyDLMLesson(data: NewDrivingLessonData): Promise<Result> {
    return this.dlmAPI.create(data);
  }
}

export default CalendarScheduleEventsStore;
