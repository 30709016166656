import { BaseEntity, DateString } from 'src/types';

import {
  AttendanceImageData,
  BaseAttendanceImageData,
} from '../AttendanceImage';

export enum ATTENDEES_TYPES {
  INSTRUCTOR = 'instructor',
  STUDENT = 'student',
}

export const ATTENDEE_TYPE = {
  INSTRUCTOR: 'instructor',
  STUDENT: 'student',
} as const;

export type AttendeeType = (typeof ATTENDEE_TYPE)[keyof typeof ATTENDEE_TYPE];

export interface BaseAttendeePersonData {
  id: string;
  firstName: string;
  lastName: string;
}

export type AttendeePersonData = BaseAttendeePersonData;

export interface BaseAttendeeData {
  id: string;
  createdAt: DateString;
  absentAt: DateString | null;
  presentAt: DateString | null;
  cancelledAt: DateString | null;
  reservedAt: DateString | null;
  reservationConfirmedAt: DateString | null;
  reservationExpiresAt: DateString | null;
  cancelledTooLate: boolean;
  cancelledByAdministrator: BaseEntity | null;
  cancelledByInstructor: BaseEntity | null;
  cancelledByStudent: BaseEntity | null;
  instructor: AttendeePersonData | null;
  student: AttendeePersonData | null;
  usedWebinarAttendanceCodes: UsedWebinarTokenData[];
  attendanceImages: BaseAttendanceImageData[];
}

export interface InstructorAttendee extends BaseAttendeeData {
  instructor: AttendeePersonData;
  student: null;
  vacationApprovedBy: {
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  vacationApprovedAt: DateString | null;
}

export interface StudentAttendee extends BaseAttendeeData {
  instructor: null;
  student: AttendeePersonData;
}

export type AttendeeData = InstructorAttendee | StudentAttendee;

export interface TogglePresenceData {
  presentAt: { date: DateString } | null;
}

export interface UsedWebinarTokenData {
  id: string;
  valid: boolean;
}

export interface WebinarAttendeeData extends BaseAttendeeData {
  attendanceImages: AttendanceImageData[];
}

export interface CreateStudentAttendeeData {
  scheduleEvent: string;
  [ATTENDEE_TYPE.STUDENT]: string;
}

export type CreateAttendeeData = CreateStudentAttendeeData;

export const isStudentAttendee = (
  attendee: AttendeeData
): attendee is StudentAttendee => Boolean(attendee.student);

export const isInstructorAttendee = (
  attendee: AttendeeData
): attendee is InstructorAttendee => Boolean(attendee.instructor);

export interface WebinarHostUrlData {
  start_url: string;
}
