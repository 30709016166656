import { LessonDefinition } from './LessonDefinition';
import { PickUpPoint } from './PickUpPoint';

export const LESSON_DURATION_IN_MINUTES = 45;

export const LESSON_STATUS = {
  OPEN: 'open',
  REQUESTED: 'requested',
  SCHEDULED: 'scheduled',
  FINISHED: 'finished',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  REJECTED: 'rejected',
} as const;

export const DLM_DRIVING_LESSON_TYPE = {
  NORMAL: 'de.123fahrschule:driving-lesson-management:driving-activity:normal',
  NIGHT_DRIVE:
    'de.123fahrschule:driving-lesson-management:driving-activity:night_drive',
  HIGHWAY:
    'de.123fahrschule:driving-lesson-management:driving-activity:highway',
  OVERLAND:
    'de.123fahrschule:driving-lesson-management:driving-activity:overland',
  VEHICLE_INSTRUCTION:
    'de.123fahrschule:driving-lesson-management:driving-activity:vehicle_instruction',
  BASIC_DRIVING:
    'de.123fahrschule:driving-lesson-management:driving-activity:basic_driving',
  MANUAL_DRIVING_VERIFICATION:
    'de.123fahrschule:driving-lesson-management:driving-activity:manual_driving_verification',
} as const;

export const DEFAULT_DLM_DRIVING_LESSON_TYPE =
  'de.123fahrschule:driving-lesson-management:driving-activity:normal';

export type LessonStatus = (typeof LESSON_STATUS)[keyof typeof LESSON_STATUS];

export type DrivingTypeSlug =
  (typeof DLM_DRIVING_LESSON_TYPE)[keyof typeof DLM_DRIVING_LESSON_TYPE];

export interface DrivingActivity {
  id: string;
  type: DrivingTypeSlug;
  label: string;
}

interface Instructor {
  id: string;
  full_name: string;
}

export interface Student {
  id: string;
  full_name: string;
}

type HistoryItemAction =
  | 'lesson_created'
  | 'lesson_reserved'
  | 'reservation_expired'
  | 'reservation_confirmed'
  | 'booked_by_student'
  | 'cancelled_by_student'
  | 'cancelled_by_student_too_late'
  | 'cancelled_by_instructor'
  | 'cancelled_by_administrator'
  | 'student_absent';

export interface HistoryItem {
  id: string;
  date: string;
  student?: Student;
  action: HistoryItemAction;
}

export interface Lesson {
  id: string;
  driving_activity_id: DrivingTypeSlug;
}

export interface DrivingLessonResponse {
  id: string;
  start_time: string;
  end_time: string;
  duration_in_minutes: number;
  status: LessonStatus;
  pick_up_point?: PickUpPoint;
  pickup_location?: PickUpPoint;
  instructor: Instructor;
  student?: Student;
  student_education_id?: string;
  lesson_definition?: LessonDefinition;
  lesson_definitions?: LessonDefinition[];
  history: HistoryItem[];
  lessons: Lesson[];
  student_did_not_show_up: boolean;
  has_pending_changes: boolean;
  pending_changes?: Partial<{
    start_time: string;
    duration_in_minutes: number;
    end_time: string;
    driving_activities: string[];
    pick_up_point: PickUpPoint;
    pickup_location: PickUpPoint;
  }>;
}

export interface PaginatedLessonsResponse {
  data: DrivingLessonResponse[];
}

export type DLMDrivingLesson = Readonly<DrivingLessonResponse>;

export interface NewRequestedDrivingLessonData {
  start_time: string;
  pick_up_point_id: string;
  pickup_location_id: string;
  student_education_id: string;
  lessons: Array<{
    lesson_definition_id: string;
    driving_activity_id: string;
    duration_in_minutes: number;
  }>;
}

export interface NewOpenDrivingLessonData {
  start_time: string;
  pick_up_point_id: string;
  pickup_location_id: string;
  lesson_definitions: string[];
  lessons: Array<{
    driving_activity_id: string;
    duration_in_minutes: number;
  }>;
}

export type NewDrivingLessonData =
  | NewRequestedDrivingLessonData
  | NewOpenDrivingLessonData;

export interface UpdateDrivingLessonData {
  start_time?: string;
  pick_up_point_id?: string;
  pickup_location_id?: string;
  student_education_id?: string;
  lesson_definition_id?: string;
  lesson_definitions?: string[];
  lessons?: Array<{
    driving_activity_id: string;
    duration_in_minutes: number;
  }>;
}

export interface MaintainDrivingLessonData {
  types: string[];
}

export interface RequestParams {
  start_time?: string | Date;
  end_time?: string | Date;
  student_id?: string;
  status?: LessonStatus[];
  page?: number;
  page_size?: number;
}
