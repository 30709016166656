import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  when,
} from 'mobx';
import { EditingDrivingLesson } from 'src/core/entities/EditingDrivingLesson';
import {
  ScheduleEvent,
  UpdateDrivingLessonData,
} from 'src/core/entities/ScheduleEvent';
import { Result } from 'src/types';

import { AttendeesAPI } from '../api/TachoAPI/AttendeesAPI';
import { ScheduleEventsAPI } from '../api/TachoAPI/ScheduleEventsAPI';
import { EditableDLMDrivingLesson } from '../entities/DLMService';
import RootStore from './RootStore';

class EditingDrivingLessonStore {
  @observable eventId: string | null;

  @observable editingDrivingLesson: EditingDrivingLesson | null;
  @observable editableDLMDrivingLesson: EditableDLMDrivingLesson | null;

  constructor(
    private readonly rootStore: RootStore,
    private readonly scheduleEventsAPI: ScheduleEventsAPI,
    private readonly attendeesAPI: AttendeesAPI
  ) {
    makeObservable(this);

    this.toInitialState();

    reaction(
      () => ({ eventId: this.eventId, event: this.event }),
      ({ eventId, event }) => {
        if (eventId && !event) {
          this.rootStore.eventScheduleStore.getScheduleEventById(eventId);
        }
      }
    );
  }

  /* eslint getter-return: ["error", { allowImplicit: true }] */
  @computed
  get event(): ScheduleEvent | undefined {
    if (!this.eventId) {
      return;
    }

    const event = this.rootStore.eventScheduleStore.findScheduleEventById(
      this.eventId
    );

    return event;
  }

  @action
  toInitialState(): void {
    this.eventId = null;
    this.editingDrivingLesson = null;
    this.editableDLMDrivingLesson = null;
  }

  @action
  setEventId(eventId: string | null = null): void {
    if (!eventId) {
      this.toInitialState();
      return;
    }

    if (this.eventId !== eventId) {
      this.toInitialState();
      this.eventId = eventId;
      this.editingDrivingLesson = new EditingDrivingLesson(this.eventId, this);
      this.setEventData();
    }
  }

  @action
  async setEventData(): Promise<void> {
    await when(() => Boolean(this.event));

    this.editingDrivingLesson?.setEventData(this.event!);
  }

  @action
  setDLMLesson = (lessonId?: string): void => {
    if (!lessonId) {
      this.editableDLMDrivingLesson = null;
      return;
    }

    if (lessonId === this.editableDLMDrivingLesson?.id) {
      return;
    }

    this.editableDLMDrivingLesson = new EditableDLMDrivingLesson(lessonId);
  };

  @action
  async updateDrivingLessonStudent(
    event: ScheduleEvent,
    newStudentId: string,
    oldStudentAttendeeId?: string
  ): Promise<Result> {
    if (oldStudentAttendeeId) {
      const deleteAttendeeResult = await this.attendeesAPI.delete(
        oldStudentAttendeeId
      );

      if (!deleteAttendeeResult.success) {
        return deleteAttendeeResult;
      }

      event.markAttendeeAsCancelled(oldStudentAttendeeId);
    }

    const createAttendeeResult = await this.attendeesAPI.create({
      scheduleEvent: event.id,
      student: newStudentId,
    });

    if (createAttendeeResult.success) {
      event.addAttendee(createAttendeeResult.data);
    }

    return createAttendeeResult;
  }

  @action
  async updateDrivingLesson(
    event: ScheduleEvent,
    data: UpdateDrivingLessonData
  ): Promise<Result> {
    if (Object.keys(data).length === 0) {
      return { success: true };
    }

    const result = await this.scheduleEventsAPI.update(event.id, data);

    if (result.success) {
      event.updateFromTachoData(result.data);
    }

    return result;
  }

  public reloadScheduleEventData(eventId: string): Promise<void> {
    return this.rootStore.eventScheduleStore.reloadScheduleEventData(eventId);
  }
}

export default EditingDrivingLessonStore;
