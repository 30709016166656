import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';
import {
  SunriseSunsetRequestParams,
  SunriseSunsetResponseData,
} from 'src/core/entities/School';

const endpoints = {
  fetchSunriseSunsetTimes: (id: string) =>
    `/api/v1/schools/${id}/sunrise-sunset-times`,
};

export class SchoolsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  fetchSunriseSunsetTimes(
    schoolId: string,
    params: SunriseSunsetRequestParams
  ): ApiRequestResult<SunriseSunsetResponseData[]> {
    return this.baseApi.get(endpoints.fetchSunriseSunsetTimes(schoolId), {
      params,
    });
  }
}
