import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';
import {
  CreateDrivingLessonTypeData,
  DrivingLessonTypeData,
  LessonTypesData,
  UpdateDrivingLessonTypeData,
} from 'src/core/entities/DrivingLessonType';

const endpoints = {
  fetchDrivingTypes: () => '/api/v1/lesson_types',
  create: () => '/api/v1/driving_lesson_types',
  update: (typeId: string) => `/api/v1/driving_lesson_types/${typeId}`,
};

export class DrivingLessonTypesAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchDrivingTypes(): ApiRequestResult<LessonTypesData> {
    return this.baseApi.get(endpoints.fetchDrivingTypes());
  }

  public create(
    data: CreateDrivingLessonTypeData
  ): ApiRequestResult<DrivingLessonTypeData> {
    return this.baseApi.post(endpoints.create(), data);
  }

  public update(
    typeId: string,
    data: UpdateDrivingLessonTypeData
  ): ApiRequestResult<DrivingLessonTypeData> {
    return this.baseApi.put(endpoints.update(typeId), data);
  }
}
