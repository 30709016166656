import { ispaAPI } from 'src/App';

import { useQuery } from '@tanstack/react-query';

export const PREFERENCES_QUERY_KEY = ['ina backend', 'instructor preferences'];

export const useDLMPreferencesQuery = () =>
  useQuery({
    staleTime: Infinity,
    cacheTime: Infinity,
    queryKey: PREFERENCES_QUERY_KEY,
    queryFn: async () => {
      const result = await ispaAPI.instructorsAPI.fetchDLMPreferences();

      if (result.success) {
        return result.data;
      }

      throw new Error(result.errorMessage);
    },
  });
