import pickBy from 'lodash/pickBy';
import { BaseAPI } from 'src/core/api/BaseAPI';
import {
  LessonDefinition,
  LessonDefinitionParams,
} from 'src/core/entities/DLMService/LessonDefinition';
import { ApiRequestResult } from 'src/types';
import { keysToSnakeCase } from 'src/utils/helpers';

export class LessonDefinitionsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  fetchAll(
    params?: LessonDefinitionParams
  ): ApiRequestResult<LessonDefinition[]> {
    return this.baseApi.get('/api/v1/ispa/lesson_definitions', {
      params: keysToSnakeCase(pickBy(params)),
    });
  }
}
