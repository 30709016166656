import { action, makeObservable, observable } from 'mobx';
import { getStudentProfilePicture } from 'src/utils/profilePicture';

export class StudentProfilePicture {
  full: string | null;
  thumbnail: string | null;

  constructor(private readonly studentId: string) {
    makeObservable(this, {
      full: observable,
      thumbnail: observable,
      onError: action,
    });

    this.full = getStudentProfilePicture(this.studentId, { type: 'full' });
    this.thumbnail = getStudentProfilePicture(this.studentId);
  }

  onError = (): void => {
    this.full = null;
    this.thumbnail = null;
  };
}
