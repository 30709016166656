import { BaseAPI } from 'src/core/api/BaseAPI';
import { PickUpPoint } from 'src/core/entities/DLMService/PickUpPoint';
import { ApiRequestResult } from 'src/types';

export class PickUpPointsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  fetchAll(): ApiRequestResult<PickUpPoint[]> {
    return this.baseApi.get('/api/v1/ispa/pick_up_points');
  }
}
