import { getPersonFullName } from 'src/utils/helpers';
import { formatDate, formatTime, getDiff, isPastDate } from 'src/utils/time';

import { Attendee } from '../Attendee';
import { HistoryItem as DLMHistoryItem } from '../DLMService';
import { LessonHistoryItem } from './ScheduleEvent.types';

export const getLessonHistoryItem = ({
  id,
  instructor,
  student,
  createdAt,
  reservedAt,
  reservationExpiresAt,
  reservationConfirmedAt,
  cancelledAt,
  cancelledTooLate,
  cancelledByStudent,
  cancelledByInstructor,
  cancelledByAdministrator,
  absentAt,
}: Attendee): LessonHistoryItem | LessonHistoryItem[] | null => {
  if (instructor) {
    return {
      key: id,
      date: createdAt,
      action: 'lesson_created',
    };
  }

  if (!student) {
    return null;
  }

  const studentHistory: LessonHistoryItem[] = [];
  const studentFullName = getPersonFullName(student);

  // reserved
  if (reservedAt && reservationExpiresAt) {
    const action = 'lesson_reserved';

    studentHistory.push({
      action,
      studentFullName,
      date: reservedAt,
      key: `${id}-${action}`,
      formattedDate: formatDate(reservationExpiresAt),
      formattedTime: formatTime(reservationExpiresAt),
    });
  }

  // reservation expired
  if (
    reservationExpiresAt &&
    isPastDate(reservationExpiresAt) &&
    !reservationConfirmedAt
  ) {
    const action = 'reservation_expired';

    studentHistory.push({
      action,
      studentFullName,
      date: reservationExpiresAt,
      key: `${id}-${action}`,
    });
  }

  // reservation confirmed
  if (reservedAt && reservationConfirmedAt) {
    const action = 'reservation_confirmed';

    studentHistory.push({
      action,
      studentFullName,
      date: reservationConfirmedAt,
      key: `${id}-${action}`,
    });
  }

  // booked by student
  if (!reservedAt) {
    const action = 'booked_by_student';

    studentHistory.push({
      action,
      studentFullName,
      date: createdAt,
      key: `${id}-${action}`,
    });
  }

  // cancelled by student
  if (cancelledAt && cancelledByStudent && !cancelledTooLate) {
    const action = 'cancelled_by_student';

    studentHistory.push({
      action,
      studentFullName,
      date: cancelledAt,
      key: `${id}-${action}`,
    });
  }

  // cancelled by student (too late)
  if (cancelledAt && cancelledByStudent && cancelledTooLate) {
    const action = 'cancelled_by_student_too_late';

    studentHistory.push({
      action,
      studentFullName,
      date: cancelledAt,
      key: `${id}-${action}`,
    });
  }

  // cancelled by instructor
  if (cancelledAt && cancelledByInstructor) {
    const action = 'cancelled_by_instructor';

    studentHistory.push({
      action,
      studentFullName,
      date: cancelledAt,
      key: `${id}-${action}`,
    });
  }

  // cancelled by administrator
  if (cancelledAt && cancelledByAdministrator) {
    const action = 'cancelled_by_administrator';

    studentHistory.push({
      action,
      studentFullName,
      date: cancelledAt,
      key: `${id}-${action}`,
    });
  }

  // student absent
  if (absentAt) {
    const action = 'student_absent';

    studentHistory.push({
      action,
      studentFullName,
      date: absentAt,
      key: `${id}-${action}`,
    });
  }

  return studentHistory;
};

export const getDLMLessonHistoryItem = ({
  id,
  date,
  student,
  action,
}: DLMHistoryItem): LessonHistoryItem => ({
  action,
  date,
  key: id,
  studentFullName: student?.full_name,
});

export const sortEventsByDate = <
  T extends { startDate: string; endDate: string }
>(
  arr: T[]
) =>
  arr.sort(
    (a, b) => getDiff(a.startDate, b.startDate) || getDiff(a.endDate, b.endDate)
  );
