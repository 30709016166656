const LOCAL_STORAGE_KEYS = {
  APP_VERSION: 'app-version',
  AUTH_TOKEN: 'auth-token',
  REFRESH_TOKEN: 'refresh-token',
  SU_TOKEN: 'su',
  STUDENTS_FILTER: 'student-filter',
  CALENDAR_VIEW: 'calendar-view',
};

const getItem = (name: string): string | null => localStorage.getItem(name);
const setItem = (name: string, value: string) =>
  localStorage.setItem(name, value);
const removeItem = (name: string) => localStorage.removeItem(name);

export const localStorageService = {
  setAppVersion: (version: string) =>
    setItem(LOCAL_STORAGE_KEYS.APP_VERSION, version),
  getAppVersion: (): string => getItem(LOCAL_STORAGE_KEYS.APP_VERSION) || '',
  removeAppVersion: () => removeItem(LOCAL_STORAGE_KEYS.APP_VERSION),

  setAuthToken: (token: string) =>
    setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, token),
  getAuthToken: (): string | null => getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN),
  removeAuthToken: () => removeItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN),

  setRefreshToken: (token: string) =>
    setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, token),
  getRefreshToken: (): string | null =>
    getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN),
  removeRefreshToken: () => removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN),

  setSuToken: (token: string) => setItem(LOCAL_STORAGE_KEYS.SU_TOKEN, token),
  getSuToken: (): string => getItem(LOCAL_STORAGE_KEYS.SU_TOKEN) || '',
  removeSuToken: () => removeItem(LOCAL_STORAGE_KEYS.SU_TOKEN),

  setStudentsFilter: (filter: string) =>
    setItem(LOCAL_STORAGE_KEYS.STUDENTS_FILTER, filter),
  getStudentsFilter: () => getItem(LOCAL_STORAGE_KEYS.STUDENTS_FILTER),
  removeStudentsFilter: () => removeItem(LOCAL_STORAGE_KEYS.STUDENTS_FILTER),

  setCalendarView: (view: string) =>
    setItem(LOCAL_STORAGE_KEYS.CALENDAR_VIEW, view),
  getCalendarView: () => getItem(LOCAL_STORAGE_KEYS.CALENDAR_VIEW),
  removeCalendarView: () => removeItem(LOCAL_STORAGE_KEYS.CALENDAR_VIEW),
};

export default localStorageService;
