import { makeAutoObservable, when } from 'mobx';
import { Result } from 'src/types';

import { AttendeesAPI } from '../api/TachoAPI/AttendeesAPI';
import { ScheduleEventsAPI } from '../api/TachoAPI/ScheduleEventsAPI';
import {
  EditableScheduleEvent,
  ScheduleEventChangedData,
} from '../entities/EditableScheduleEvent';
import { ScheduleEvent } from '../entities/ScheduleEvent';
import RootStore from './RootStore';

class EditableScheduleEventStore {
  editableScheduleEvents: Record<string, EditableScheduleEvent>;

  constructor(
    private readonly rootStore: RootStore,
    private readonly scheduleEventsAPI: ScheduleEventsAPI,
    private readonly attendeesAPI: AttendeesAPI
  ) {
    makeAutoObservable(this);

    this.editableScheduleEvents = {};
  }

  setEvent(eventId?: string): void {
    if (!eventId || this.editableScheduleEvents[eventId]) {
      return;
    }

    when(
      () =>
        Boolean(
          this.rootStore.eventScheduleStore.findScheduleEventById(eventId)
        ),
      () => {
        const event =
          this.rootStore.eventScheduleStore.findScheduleEventById(eventId);
        this.editableScheduleEvents[eventId] = new EditableScheduleEvent(
          event!,
          this
        );
      }
    );
  }

  removeEvent(eventId: string): void {
    delete this.editableScheduleEvents[eventId];
  }

  findById(eventId?: string): EditableScheduleEvent | undefined {
    if (!eventId) {
      return;
    }

    return this.editableScheduleEvents[eventId];
  }

  async updateScheduleEvent(
    eventId: string,
    data: ScheduleEventChangedData
  ): Promise<Result> {
    if (Object.keys(data).length === 0) {
      return { success: true };
    }

    const result = await this.scheduleEventsAPI.update(eventId, data);

    if (result.success) {
      this.rootStore.eventScheduleStore
        .findScheduleEventById(eventId)
        ?.updateFromTachoData(result.data);
    }

    return result;
  }

  async changeStudent(
    event: ScheduleEvent,
    newStudentId: string,
    oldStudentAttendeeId?: string
  ): Promise<Result> {
    if (oldStudentAttendeeId) {
      const deleteAttendeeResult = await this.attendeesAPI.delete(
        oldStudentAttendeeId
      );

      if (!deleteAttendeeResult.success) {
        return deleteAttendeeResult;
      }

      event.markAttendeeAsCancelled(oldStudentAttendeeId);
    }

    const createAttendeeResult = await this.attendeesAPI.create({
      scheduleEvent: event.id,
      student: newStudentId,
    });

    if (createAttendeeResult.success) {
      event.addAttendee(createAttendeeResult.data);
    }

    return createAttendeeResult;
  }

  reloadScheduleEventData(eventId: string): Promise<void> {
    return this.rootStore.eventScheduleStore.reloadScheduleEventData(eventId);
  }
}

export default EditableScheduleEventStore;
