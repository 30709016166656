import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { StudentEducationsAPI } from 'src/core/api/IspaAPI/StudentEducationsAPI';
import { StudentEducation } from 'src/core/entities/StudentEducation';
import { errorLogger, getRequestErrorDetails } from 'src/utils/errorLogger';

class StudentEducationsStore {
  currentStudentId: string;
  studentEducations: IObservableArray<StudentEducation>;
  educationsLoading: boolean;
  educationsLoadingFailed: boolean;

  constructor(private readonly studentEducationsAPI: StudentEducationsAPI) {
    makeObservable<StudentEducationsStore, 'loadStudentEducations'>(this, {
      currentStudentId: observable,
      studentEducations: observable,
      educationsLoading: observable,
      educationsLoadingFailed: observable,
      educationsTheoryTopics: computed,
      toInitialState: action,
      setStudentId: action,
      loadStudentEducations: action,
    });

    this.toInitialState();
  }

  get educationsTheoryTopics(): number[] {
    if (!this.studentEducations) {
      return [];
    }

    const allEducationsTopics = this.studentEducations.reduce(
      (acc, education) => [
        ...acc,
        ...education.available_theory_topics.map(({ number }) => number),
      ],
      []
    );

    const uniqTopics = Array.from(new Set(allEducationsTopics));

    const sortedTopics = uniqTopics.sort((a, b) => a - b);

    return sortedTopics;
  }

  toInitialState() {
    this.currentStudentId = '';
    this.studentEducations = observable.array();
    this.educationsLoading = false;
    this.educationsLoadingFailed = false;
  }

  setStudentId(studentId: string = ''): void {
    if (!studentId) {
      return;
    }

    if (this.currentStudentId !== studentId) {
      this.toInitialState();
    }

    this.currentStudentId = studentId;
    this.loadStudentEducations(studentId);
  }

  getByDrivingCategoryId(
    categoryChargerId?: number
  ): StudentEducation | undefined {
    if (!categoryChargerId) {
      return;
    }

    const regex = new RegExp(`:${categoryChargerId}$`);

    return this.studentEducations.find(({ features }) =>
      features.find(({ id }) => regex.test(id))
    );
  }

  getFirstEducationId(): string | undefined {
    // Workaround to make it reactive
    return this.studentEducations.find((education) => education)?.education_id;
  }

  // API requests
  private async loadStudentEducations(studentId: string): Promise<void> {
    if (this.educationsLoading) {
      return;
    }

    runInAction(() => {
      this.educationsLoading = true;
    });

    const result = await this.studentEducationsAPI.fetchByStudentId(studentId);

    runInAction(() => {
      this.educationsLoading = false;
    });

    if (result.success) {
      const filtered = result.data.filter(
        ({ replaced_by_education_id }) => !replaced_by_education_id
      );

      runInAction(() => {
        this.studentEducations.replace(filtered);
      });
    } else {
      errorLogger("Error loading student's educations", {
        extras: {
          studentId,
          ...getRequestErrorDetails(result.error),
        },
      });

      runInAction(() => {
        this.educationsLoadingFailed = true;
      });
    }
  }
}

export default StudentEducationsStore;
