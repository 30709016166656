import { BaseAPI } from 'src/core/api/BaseAPI';
import IndexedDBService from 'src/core/services/IndexedDBService';
import { ApiRequestResult, RequestResult } from 'src/types';
import {
  AuthResponseData,
  LoginData,
  WhoAmIData,
} from 'src/core/entities/User';

export const endpoints = {
  whoami: () => '/api/v1/whoami',
  instructorLogin: () => '/login/instructors',
  tokenRefresh: () => '/api/token/refresh',
};

export class UserAPI {
  constructor(
    private readonly baseApi: BaseAPI,
    private readonly localDB: IndexedDBService
  ) {}

  public async whoami(): Promise<RequestResult<WhoAmIData>> {
    const localDBRequestResult = await this.localDB.getCurrentUser();
    if (localDBRequestResult.success) {
      return localDBRequestResult;
    }

    const result = await this.baseApi.get<WhoAmIData>(endpoints.whoami());

    if (result.success) {
      this.localDB.setCurrentUser(result.data.user);
    }

    return result;
  }

  public instructorLogin(data: LoginData): ApiRequestResult<AuthResponseData> {
    return this.baseApi.post(endpoints.instructorLogin(), data);
  }

  public refreshToken(
    refreshToken: string
  ): ApiRequestResult<AuthResponseData> {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    formData.append('refresh_token', refreshToken);

    return this.baseApi.post(endpoints.tokenRefresh(), formData, config);
  }
}
