import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';
import { StudentStatisticsData } from 'src/core/entities/StudentStatistics';

const endpoints = {
  fetchByStudentId: (studentId: string) =>
    `/api/v1/student_profiles/${studentId}`,
};

export class StudentStatisticsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchByStudentId(
    studentId: string
  ): ApiRequestResult<StudentStatisticsData> {
    return this.baseApi.get(endpoints.fetchByStudentId(studentId));
  }
}
