import classNames from 'classnames';
import { action, computed, makeObservable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import NotificationsStore from '../../../core/stores/NotificationsStore';
import RootStore from '../../../core/stores/RootStore';
import styles from './styles';

interface IProps extends WithTranslation<'common'>, WithStyles<typeof styles> {
  stores?: RootStore;
}

const NOTIFICATION_HIDE_DELAY = {
  DEFAULT: 3000,
  SUCCESS: 1500,
};

class Notifications extends React.Component<IProps> {
  private store: NotificationsStore;

  private timer: ReturnType<typeof setTimeout>;

  constructor(props: IProps) {
    super(props);
    makeObservable(this);

    this.store = props.stores!.notificationsStore;

    reaction(
      () => this.store.isOpen,
      (isOpen) => {
        if (isOpen) {
          this.timer = setTimeout(() => {
            this.handleClose();
          }, this.hideDelay);
        }
      }
    );
  }

  @computed
  get hideDelay(): number {
    if (this.store.notificationOptions.variant === 'success') {
      return NOTIFICATION_HIDE_DELAY.SUCCESS;
    }

    return NOTIFICATION_HIDE_DELAY.DEFAULT;
  }

  @action
  handleClose = () => {
    if (this.store.notificationOptions.onClose) {
      this.store.notificationOptions.onClose();
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.store.hideNotification();
  };

  @action
  actionHandler = () => {
    if (this.store.notificationOptions.action!.actionCallback) {
      this.store.notificationOptions.action!.actionCallback();
    }

    this.handleClose();
  };

  @computed
  get message(): string {
    const { message } = this.store.notificationOptions;
    return message || '';
  }

  @computed
  get notificationTitle() {
    const { title } = this.store.notificationOptions;
    if (!title) {
      return null;
    }
    const { classes } = this.props;

    return (
      <DialogTitle disableTypography={true}>
        <Typography className={classes.titleText}>{title}</Typography>
      </DialogTitle>
    );
  }

  @computed
  get notificationAction() {
    const {
      notificationOptions: { action: notificationAction },
    } = this.store;

    if (!notificationAction) {
      return null;
    }
    const { classes } = this.props;
    const { text } = notificationAction;

    return (
      <DialogActions className={classes.action}>
        <Button onClick={this.actionHandler} color="primary" autoFocus={true}>
          {text}
        </Button>
      </DialogActions>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      isOpen,
      notificationOptions: { variant },
    } = this.store;

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleClose}
        classes={{ paper: classes.paper }}
      >
        {this.notificationTitle}

        <DialogContent>
          <DialogContentText
            className={classNames(classes.messageText, variant)}
          >
            {this.message}
          </DialogContentText>
        </DialogContent>

        {this.notificationAction}
      </Dialog>
    );
  }
}

export default withTranslation()(
  withStyles(styles)(inject('stores')(observer(Notifications)))
);
