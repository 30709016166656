import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';
import { MediaObjectData } from 'src/core/entities/MediaObject';

const endpoints = {
  create: () => '/api/v1/media_objects',
};

export class MediaObjectsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public create(file: File): ApiRequestResult<MediaObjectData> {
    const formData = new FormData();
    formData.append('file', file);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    return this.baseApi.post(endpoints.create(), formData, config);
  }
}
