import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon';
import React from 'react';

const IdCardIcon: React.FunctionComponent = () => (
  <SvgIcon>
    <path d="M3.333 4h23.334C27.892 4 29 5.161 29 6.444v17.112C29 24.839 27.892 26 26.667 26H3.333C2.108 26 1 24.839 1 23.556V6.444C1 5.161 2.108 4 3.333 4zm14 3.667v1.222h9.334V7.667h-9.334zm0 2.444v1.222h9.334v-1.222h-9.334zm0 2.445v1.222H25.5v-1.222h-8.167zm-7 4.778c-2.333 0-7 1.333-7 3.777v1.222h14v-1.222c0-2.444-4.666-3.777-7-3.777zm0-9.667c-1.933 0-3.5 1.641-3.5 3.666S8.4 15 10.333 15s3.5-1.642 3.5-3.667-1.567-3.666-3.5-3.666z" />
  </SvgIcon>
);

export default IdCardIcon;
