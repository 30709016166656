import { dlmAPI } from 'src/App';
import { getCurrentDate, getDayEnd } from 'src/utils/time';

import { useQuery } from '@tanstack/react-query';

import { ScheduleEvent } from '..';
import { LESSON_STATUS } from '../../DLMService';

export const useDLMNextWorkDayScheduleEventsQuery = (enabled: boolean) =>
  useQuery({
    enabled,
    initialData: [],
    queryKey: ['events', 'dlm', 'next workday'],
    queryFn: async () => {
      const result = await dlmAPI.drivingLessonsAPI.fetchAll({
        page: 1,
        page_size: 1,
        start_time: getDayEnd(getCurrentDate()).toISOString(),
        status: [
          LESSON_STATUS.OPEN,
          LESSON_STATUS.REQUESTED,
          LESSON_STATUS.SCHEDULED,
          LESSON_STATUS.FINISHED,
          LESSON_STATUS.COMPLETED,
        ],
      });

      if (result.success) {
        return result.data.data.map(ScheduleEvent.fromDLMData);
      }

      return [];
    },
  });
