import { action, computed, makeObservable, observable } from 'mobx';

import {
  PRACTICE_PROTOCOL_MAX_ITEM_LEVEL,
  PRACTICE_PROTOCOL_MIN_ITEM_LEVEL,
} from '../../../constants';
import { PracticeProtocolCategoryData } from './PracticeProtocol.types';
import { PracticeProtocolItem } from './PracticeProtocolItem.model';

export class PracticeProtocolCategory {
  readonly id: string;
  readonly name: string;
  readonly items: ReadonlyArray<PracticeProtocolItem>;

  expanded: boolean;

  constructor({ id, name, items }: PracticeProtocolCategoryData) {
    makeObservable(this, {
      expanded: observable,
      relevantItems: computed,
      progress: computed,
      completed: computed,
      toggleExpanded: action,
      setExpanded: action,
      changeAllItemsLevel: action,
    });

    this.id = id;
    this.name = name;
    this.items = items.map((itemData) => new PracticeProtocolItem(itemData));
  }

  get relevantItems(): PracticeProtocolItem[] {
    return this.items.filter(({ considerInStatistic }) => considerInStatistic);
  }

  get progress(): number {
    const itemsCount = this.relevantItems.length;
    const totalLevel = this.relevantItems.reduce(
      (acc, item) => acc + item.level,
      0
    );

    return (totalLevel / (itemsCount * PRACTICE_PROTOCOL_MAX_ITEM_LEVEL)) * 100;
  }

  get completed(): boolean {
    return this.progress >= 100;
  }

  toggleExpanded = (): void => {
    this.expanded = !this.expanded;
  };

  setExpanded(expanded: boolean): void {
    this.expanded = expanded;
  }

  changeAllItemsLevel = (): void => {
    const newLevel = this.completed
      ? PRACTICE_PROTOCOL_MIN_ITEM_LEVEL
      : PRACTICE_PROTOCOL_MAX_ITEM_LEVEL;

    this.items.forEach((item) => {
      item.setLevel(newLevel);
    });
  };
}
