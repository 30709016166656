import { BaseAPI } from 'src/core/api/BaseAPI';
import {
  PracticeProtocolData,
  UpdatePracticeProtocolItemPayload,
} from 'src/core/entities/PracticeProtocol';
import { ApiRequestResult } from 'src/types';

export class PracticeProtocolAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  fetchByStudentEducationId(
    educationId: string
  ): ApiRequestResult<PracticeProtocolData> {
    return this.baseApi.get(
      `/api/v1/practice_protocol?student_education_id=${educationId}`
    );
  }

  update(
    eventId: string,
    eventType: string,
    studentEducationId: string,
    changedItems: UpdatePracticeProtocolItemPayload[]
  ): ApiRequestResult<PracticeProtocolData> {
    return this.baseApi.put('/api/v1/update_practice_protocol', {
      student_education_id: studentEducationId,
      lesson_id: `de.123fahrschule:tacho:${eventType}:${eventId}`,
      changed_items: changedItems,
    });
  }

  updateDLMLesson(
    lessonId: string,
    studentEducationId: string,
    changedItems: UpdatePracticeProtocolItemPayload[]
  ): ApiRequestResult<PracticeProtocolData> {
    return this.baseApi.put('/api/v1/update_practice_protocol', {
      student_education_id: studentEducationId,
      lesson_id: lessonId,
      changed_items: changedItems,
    });
  }
}
