import { tachoAPI } from 'src/App';
import { MAX_DRIVING_LESSON_DURATION } from 'src/constants';
import {
  addMinutesToDate,
  getCurrentDate,
  getDayEnd,
  getDayStart,
} from 'src/utils/time';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { EVENT_STATUS, ScheduleEvent } from '..';

type Options = Pick<
  UseQueryOptions<ScheduleEvent[], unknown, ScheduleEvent[], string[]>,
  'select'
>;

export const useTachoTodayScheduleEventsQuery = (
  instructorId: string | undefined,
  options?: Options
) =>
  useQuery({
    enabled: Boolean(instructorId),
    initialData: [],
    queryKey: ['events', 'tacho', 'today'],
    queryFn: async () => {
      if (!instructorId) {
        return [];
      }

      const result = await tachoAPI.scheduleEventsAPI.fetchAll({
        'attendees.instructor': instructorId,
        'startDate[after]': addMinutesToDate(
          getDayStart(getCurrentDate()),
          -MAX_DRIVING_LESSON_DURATION
        ),
        'endDate[before]': addMinutesToDate(
          getDayEnd(getCurrentDate()),
          MAX_DRIVING_LESSON_DURATION
        ),
        status: [
          EVENT_STATUS.FREE,
          EVENT_STATUS.BOOKED,
          EVENT_STATUS.RESERVED,
          EVENT_STATUS.ABSENT,
        ],
      });

      if (result.success) {
        return result.data.map(ScheduleEvent.fromTachoData);
      }

      return [];
    },
    ...options,
  });
