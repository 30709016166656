import { action, makeObservable, observable } from 'mobx';

import { PracticeProtocolHistoryCategoryData } from './PracticeProtocolHistory.types';
import { PracticeProtocolHistoryItem } from './PracticeProtocolHistoryItem.model';

export class PracticeProtocolHistoryCategory {
  readonly id: string;
  readonly name: string;

  items: ReadonlyArray<PracticeProtocolHistoryItem>;
  expanded: boolean;

  constructor({ id, name, items }: PracticeProtocolHistoryCategoryData) {
    makeObservable(this, {
      expanded: observable,
      toggleExpanded: action,
    });

    this.id = id;
    this.name = name;
    this.items = items;
    this.expanded = false;
  }

  toggleExpanded = (): void => {
    this.expanded = !this.expanded;
  };
}
