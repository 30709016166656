import { AxiosError } from 'axios';

import * as Sentry from '@sentry/react';

interface IErrorLoggerOptions {
  notifySentry?: boolean;
  tags?: {
    [key: string]: string;
  };
  extras?: {
    request?: {
      name: string;
    };
    [key: string]: any;
  };
}

const defaultConfig: IErrorLoggerOptions = {
  notifySentry: process.env.NODE_ENV === 'production',
};

export const errorLogger = (error: any, options?: IErrorLoggerOptions) => {
  const config: IErrorLoggerOptions = { ...defaultConfig, ...options };

  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable-next-line no-console */
    console.error(error);
  }
  if (config.notifySentry) {
    const { tags = {}, extras = {} } = config;

    Sentry.withScope((scope) => {
      scope.setTags(tags);
      scope.setExtras(extras);

      if (typeof error === 'string') {
        Sentry.captureMessage(error);
      } else {
        Sentry.captureException(error);
      }
    });
  }
};

const isAxiosError = (error: unknown): error is AxiosError =>
  Boolean(error && typeof error === 'object' && 'isAxiosError' in error);

export const getRequestErrorDetails = (error: unknown) => {
  if (isAxiosError(error)) {
    return {
      request: error?.request,
      response: error?.response?.data,
    };
  }

  return null;
};

export const getErrorMessage = (
  error: any,
  defaultMessage: string = ''
): string => {
  const data = error.response?.data || {};

  return (
    data.violations?.[0]?.message ||
    data.title ||
    data.message ||
    data.detail ||
    defaultMessage
  );
};

export default errorLogger;
