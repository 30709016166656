import uniqBy from 'lodash/uniqBy';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { Instructor } from 'src/core/entities/Instructor';
import {
  PickUpPoint,
  PickUpPointRequestParams,
} from 'src/core/entities/PickUpPoint';

import { PickUpPointsAPI } from '../api/TachoAPI/PickUpPointsAPI';
import RootStore from './RootStore';

class PickUpPointsStore {
  @observable public pickUpPointsFetching: boolean;
  @observable pickUpPoints: IObservableArray<PickUpPoint>;

  constructor(
    private readonly rootStore: RootStore,
    private readonly pickUpPointsAPI: PickUpPointsAPI
  ) {
    makeObservable(this);

    this.pickUpPointsFetching = false;
    this.pickUpPoints = observable.array();
  }

  findById = (pickUpPointId?: string): PickUpPoint | undefined => {
    if (!pickUpPointId) {
      return;
    }

    return this.pickUpPoints.find(({ id }) => id === pickUpPointId);
  };

  public async getCurrentInstructorPickUpPoints(): Promise<PickUpPoint[]> {
    await when(() => Boolean(this.currentInstructor));

    await this.loadCurrentInstructorPickUpPoints();

    return this.pickUpPoints;
  }

  public getPickUpPointById(pointId: string): PickUpPoint | undefined {
    const pickUpPoint = this.pickUpPoints.find(({ id }) => id === pointId);
    if (!pickUpPoint) {
      this.loadPickUpPointById(pointId);
    }

    return pickUpPoint;
  }

  @computed
  public get filteredPickUpPoints(): PickUpPoint[] {
    return this.pickUpPoints.filter(
      ({
        name,
        address: { zipCode, city, street, streetNumber },
      }: PickUpPoint): boolean =>
        `${name}${zipCode}${city}${street}${streetNumber}`
          .toLowerCase()
          .includes(this.searchString.toLowerCase())
    );
  }

  public async requestPickUpPointsBySearchString(searchString: string) {
    if (!searchString || this.pickUpPointsFetching || !this.currentInstructor) {
      return;
    }

    const params: PickUpPointRequestParams = {
      searchParam: searchString,
    };

    await this.loadInstructorPickUpPoints(this.currentInstructor.id, params);
  }

  @computed
  get currentInstructor(): Instructor | null | undefined {
    return this.rootStore.instructorsStore.currentInstructor;
  }

  @computed
  private get searchString(): string {
    return this.rootStore.uiStore.searchString;
  }

  // API requests
  @action
  private async loadCurrentInstructorPickUpPoints(): Promise<void> {
    if (!this.currentInstructor) {
      return;
    }

    runInAction(() => {
      this.pickUpPointsFetching = true;
    });

    const result = await this.pickUpPointsAPI.fetchByInstructorId(
      this.currentInstructor.id
    );

    runInAction(() => {
      this.pickUpPointsFetching = false;
    });

    if (result.success) {
      const pickUpPoints = result.data;

      runInAction(() => {
        this.pickUpPoints.replace(
          uniqBy([...pickUpPoints, ...this.pickUpPoints], 'id')
        );
      });
    }
  }

  @action
  private async loadInstructorPickUpPoints(
    instructorId: string,
    params?: PickUpPointRequestParams
  ): Promise<void> {
    runInAction(() => {
      this.pickUpPointsFetching = true;
    });

    const result = await this.pickUpPointsAPI.fetchByInstructorId(
      instructorId,
      params
    );

    runInAction(() => {
      this.pickUpPointsFetching = false;
    });

    if (result.success) {
      const pickUpPoints = result.data;

      runInAction(() => {
        this.pickUpPoints.replace(
          uniqBy([...pickUpPoints, ...this.pickUpPoints], 'id')
        );
      });
    }
  }

  @action
  private async loadPickUpPointById(pointId: string): Promise<void> {
    const result = await this.pickUpPointsAPI.fetchById(pointId);

    if (result.success) {
      const pickUpPoint = result.data;

      runInAction(() => {
        this.pickUpPoints.replace(
          uniqBy([pickUpPoint, ...this.pickUpPoints], 'id')
        );
      });
    }
  }
}

export default PickUpPointsStore;
