import { sortEventsByDate } from '../ScheduleEvent.utils';
import { useStudentDLMScheduleEvents } from './useStudentDLMScheduleEvents';
import { useStudentTachScheduleEvents } from './useStudentTachScheduleEvents';

export const useStudentScheduleEvents = (
  studentId: string,
  dlmServiceEnabled: boolean
) => {
  const tachoQuery = useStudentTachScheduleEvents(studentId);
  const dlmQuery = useStudentDLMScheduleEvents(studentId, dlmServiceEnabled);

  const data = sortEventsByDate([...tachoQuery.data, ...dlmQuery.data]);

  return {
    data,
    isFetching: tachoQuery.isFetching || dlmQuery.isFetching,
    isFetched: dlmServiceEnabled
      ? tachoQuery.isFetched && dlmQuery.isFetched
      : tachoQuery.isFetched,
  };
};
