import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';

export class PracticeProtocolAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public setNoProgress(eventId: string): ApiRequestResult {
    return this.baseApi.put(`/api/v1/schedule_events/${eventId}/no_progress`);
  }
}
