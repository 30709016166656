import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './locales/de-De.json';

export const resources = {
  de: {
    common: de.common,
    dialogs: de.dialogs,
    countries: de.countries,
    welcomingMessage: de.welcoming_message,
  },
} as const;

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === 'development',
  ns: ['common', 'dialogs', 'welcomingMessage', 'countries'],
  defaultNS: 'common',
  fallbackNS: 'common',
  resources,
  lng: 'de',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'de',
});

export default i18n;
