import { tachoAPI } from 'src/App';
import { getWeekStart } from 'src/utils/time';

import { useQuery } from '@tanstack/react-query';

import { EVENT_STATUS } from '../../ScheduleEvent/ScheduleEvent.types';
import { CalendarScheduleEvent } from '../CalendarScheduleEvent.model';

interface Variables {
  instructorId: string;
  startDate: string;
  endDate: string;
  enabled: boolean;
}

export const useTachoCalendarEventsQuery = ({
  instructorId,
  startDate,
  endDate,
  enabled,
}: Variables) =>
  useQuery({
    enabled,
    placeholderData: [],
    queryKey: [
      {
        api: 'tacho',
        scope: 'events',
        type: 'calendar',
        weekStart: getWeekStart(startDate),
        startDate,
        endDate,
      },
    ],
    queryFn: async ({ signal }) => {
      const result = await tachoAPI.calendarScheduleEventsAPI.fetchAll(
        {
          'attendees.instructor': instructorId,
          'startDate[before]': endDate,
          'endDate[after]': startDate,
          status: [
            EVENT_STATUS.FREE,
            EVENT_STATUS.BOOKED,
            EVENT_STATUS.RESERVED,
            EVENT_STATUS.ABSENT,
          ],
          'public_holiday[startDate]': startDate,
          'public_holiday[endDate]': endDate,
        },
        signal
      );

      if (result.success) {
        return result.data.map(CalendarScheduleEvent.fromTachoData);
      }

      return [];
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
