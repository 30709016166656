import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';
import {
  PickUpPointData,
  PickUpPointRequestParams,
} from 'src/core/entities/PickUpPoint';

const endpoints = {
  fetchByInstructorId: (instructorId: string) =>
    `/api/v1/instructors/${instructorId}/pick_up_points`,
  fetchById: (pickUpPointId: string) =>
    `/api/v1/pick_up_points/${pickUpPointId}`,
};

export class PickUpPointsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchByInstructorId(
    instructorId: string,
    params?: PickUpPointRequestParams
  ): ApiRequestResult<PickUpPointData[]> {
    return this.baseApi.get(endpoints.fetchByInstructorId(instructorId), {
      params,
    });
  }

  public fetchById(pickUpPointId: string): ApiRequestResult<PickUpPointData> {
    return this.baseApi.get(endpoints.fetchById(pickUpPointId));
  }
}
