import { ApiRequestResult, AppVersion } from 'src/types';

import { BaseAPI } from '../BaseAPI';

const endpoints = {
  getVersion: () => '/version.json',
};

export class AppAPI extends BaseAPI {
  constructor() {
    super('/');
  }

  getAppVersion(): ApiRequestResult<AppVersion> {
    return this.get(endpoints.getVersion());
  }
}
