import { AxiosRequestConfig } from 'axios';
import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';
import { endpoints } from 'src/core/api/TachoAPI/UserAPI';
import { Address } from 'src/core/entities/Address';
import { AttendanceImage } from 'src/core/entities/AttendanceImage';
import { DLMAddress } from 'src/core/entities/DLMService/PickUpPoint';

import { CamelKeysToSnakeCase, Person } from '../types';

export const canOpenMapAtAddress = (address: Partial<Address>): boolean => {
  const { latitude, longitude, country, city, street, streetNumber } = address;
  return Boolean(
    (latitude && longitude) || country || city || street || streetNumber
  );
};

export const openMapAtAddress = (
  address?: Partial<Address> | DLMAddress | null
): void => {
  if (!address) {
    return;
  }

  let query: string;

  const { latitude, longitude } = address;
  if (latitude && longitude) {
    query = `${latitude},${longitude}`;
  } else if (!('country' in address)) {
    return;
  } else {
    const { country = '', city = '', street = '', streetNumber = '' } = address;
    query = `${country}, ${city}, ${street}, ${streetNumber}`;
  }

  const filteredQuery = cleanAddressString(query);

  if (!filteredQuery) {
    return;
  }

  const encodedQuery = encodeURIComponent(filteredQuery);

  window.open(
    `https://www.google.com/maps/search/?api=1&query=${encodedQuery}`,
    '_blank'
  );
};

export const cleanAddressString = (address: string): string =>
  address
    .replace(/\bnull,?/g, '')
    .trim()
    .replace(/^, ?|,$/g, '');

export const findAttendeeCurrentAttendanceImage = (
  attendanceImages: AttendanceImage[] = []
): AttendanceImage | undefined => {
  const images = attendanceImages
    .filter(({ image }) => image)
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

  if (images.length <= 1) {
    return images[0];
  }

  const approved = images.find(
    ({ approvedAt, rejectedAt }) => approvedAt && !rejectedAt
  );

  if (approved) {
    return approved;
  }

  const pending = images.find(
    ({ approvedAt, rejectedAt }) => !approvedAt && !rejectedAt
  );

  if (pending) {
    return pending;
  }

  return images.find(({ approvedAt, rejectedAt }) => rejectedAt && !approvedAt);
};

export const getPersonFullName = ({
  firstName = '',
  lastName = '',
}: Partial<Person>): string => `${firstName} ${lastName}`.trim();

export const isTokenRefreshRequest = (config: AxiosRequestConfig): boolean =>
  Boolean(config.url?.includes(endpoints.tokenRefresh()));

export const keysToSnakeCase = <T extends Record<string, any>>(data: T) =>
  mapKeys<T>(data, (_, key) => snakeCase(key)) as CamelKeysToSnakeCase<T>;
