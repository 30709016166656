import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';
import {
  DrivingLessonCategoryData,
  DrivingLessonCategoryRequestParams,
} from 'src/core/entities/DrivingLessonCategory';

const endpoints = {
  fetchAll: () => '/api/v1/driving_lesson_categories',
};

export class DrivingLessonCategoriesAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchAll(
    params?: DrivingLessonCategoryRequestParams
  ): ApiRequestResult<DrivingLessonCategoryData[]> {
    return this.baseApi.get(endpoints.fetchAll(), {
      params,
    });
  }
}
