import uniqBy from 'lodash/uniqBy';
import { makeAutoObservable } from 'mobx';
import { DateString } from 'src/types';
import { getPersonFullName } from 'src/utils/helpers';
import {
  formatTimeRange,
  getCurrentDate,
  getDiff,
  getEventDuration,
  isPastDate,
} from 'src/utils/time';

import { Address } from '../Address';
import {
  Attendee,
  InstructorAttendee,
  isInstructorAttendee,
  isStudentAttendee,
  StudentAttendee,
} from '../Attendee';
import { Classroom } from '../Classroom';
import {
  DrivingLessonResponse,
  getDLMLessonStatus,
  getLessonDefinitionColor,
  Lesson,
  LessonDefinition,
} from '../DLMService';
import { DrivingLessonType } from '../DrivingLessonType';
import { ExamAddress } from '../ExamAddress';
import { MediaObject } from '../MediaObject';
import { PickUpPoint } from '../PickUpPoint';
import { Simulator } from '../Simulator';
import { TheoryLessonTopic } from '../TheoryLessonTopic';
import {
  EVENT_CANCELLATION_TYPE,
  EVENT_STATUS,
  EVENT_TYPE,
  LessonHistoryItem,
  ScheduleEventCancellationType,
  ScheduleEventData,
  ScheduleEventStatus,
  ScheduleEventType,
} from './ScheduleEvent.types';
import {
  getDLMLessonHistoryItem,
  getLessonHistoryItem,
} from './ScheduleEvent.utils';

type TachoData = Pick<
  ScheduleEventData,
  | 'attendees'
  | 'topic'
  | 'title'
  | 'drivingLessonTypes'
  | 'resource'
  | 'maintained'
  | 'description'
  | 'webinar'
  | 'webinarUrl'
  | 'webinarAttendanceCodes'
  | 'category'
  | 'passingStatus'
>;

type DLMData = Pick<
  DrivingLessonResponse,
  | 'student'
  | 'student_education_id'
  | 'status'
  | 'history'
  | 'pick_up_point'
  | 'pickup_location'
  | 'lessons'
  | 'lesson_definition'
  | 'lesson_definitions'
  | 'instructor'
  | 'has_pending_changes'
>;

export class ScheduleEvent {
  static fromTachoData({
    id,
    type,
    status,
    startDate,
    endDate,
    ...rest
  }: ScheduleEventData): ScheduleEvent {
    return new ScheduleEvent(id, type, status, startDate, endDate, rest, null);
  }

  static fromDLMData(data: DrivingLessonResponse): ScheduleEvent {
    const { id, start_time, end_time, ...rest } = data;
    const eventStatus = getDLMLessonStatus(data);

    return new ScheduleEvent(
      id,
      EVENT_TYPE.DRIVING_LESSON,
      eventStatus,
      start_time,
      end_time,
      null,
      rest
    );
  }

  constructor(
    public readonly id: string,
    public readonly type: ScheduleEventType,
    public status: ScheduleEventStatus,
    public startDate: DateString,
    public endDate: DateString,
    private tachoData: TachoData | null,
    private dlmData: DLMData | null
  ) {
    makeAutoObservable(this);

    this.dlmData?.lesson_definitions?.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
  }

  private get isTachoEvent(): boolean {
    return Boolean(this.tachoData);
  }

  get isDLMLesson(): boolean {
    return Boolean(this.dlmData);
  }

  get isFree(): boolean {
    return this.status === EVENT_STATUS.FREE;
  }

  get isReserved(): boolean {
    return this.status === EVENT_STATUS.RESERVED;
  }

  get isBooked(): boolean {
    return this.status === EVENT_STATUS.BOOKED;
  }

  get isAbsent(): boolean {
    return this.status === EVENT_STATUS.ABSENT;
  }

  get isCancelled(): boolean {
    return this.status === EVENT_STATUS.CANCELLED;
  }

  get isStarted(): boolean {
    return isPastDate(this.startDate);
  }

  get isFinished(): boolean {
    return isPastDate(this.endDate);
  }

  get isUpcoming(): boolean {
    return (
      !this.isFinished && (this.isFree || this.isReserved || this.isBooked)
    );
  }

  get isMaintained(): boolean {
    return Boolean(this.tachoData?.maintained);
  }

  get hasPendingChanges(): boolean {
    return Boolean(this.dlmData?.has_pending_changes);
  }

  get isWebinar(): boolean {
    return Boolean(this.tachoData?.webinar);
  }

  get webinarAttendanceCodes(): string[] {
    return this.tachoData?.webinarAttendanceCodes || [];
  }

  get drivingLessonTypes(): DrivingLessonType[] {
    return this.tachoData?.drivingLessonTypes || [];
  }

  set drivingLessonTypes(types: DrivingLessonType[]) {
    if (!this.tachoData?.drivingLessonTypes) {
      return;
    }

    this.tachoData.drivingLessonTypes = uniqBy(types, 'indexNumber');
  }

  get dlmDrivingTypes(): Lesson[] {
    return this.dlmData?.lessons ?? [];
  }

  get duration(): number {
    return getEventDuration(this.startDate, this.endDate);
  }

  get topic(): TheoryLessonTopic | null | undefined {
    return this.tachoData?.topic;
  }

  get description(): string {
    return this.tachoData?.description || '';
  }

  get instructorAttendee(): InstructorAttendee | undefined {
    const instructor = this.tachoData?.attendees
      .filter(isInstructorAttendee)
      .find(({ cancelledAt }) => !cancelledAt);

    return instructor;
  }

  get studentAttendee(): StudentAttendee | undefined {
    if (this.status === EVENT_STATUS.ABSENT) {
      return this.allStudentsAttendees?.find(({ absentAt }) => absentAt);
    }

    const studentAttendee = this.allStudentsAttendees?.find(
      ({
        student,
        cancelledAt,
        absentAt,
        reservationExpiresAt,
        reservationConfirmedAt,
      }) =>
        Boolean(student) &&
        !cancelledAt &&
        !absentAt &&
        (!reservationExpiresAt ||
          reservationConfirmedAt ||
          !isPastDate(reservationExpiresAt))
    );

    return studentAttendee;
  }

  get studentId(): string | undefined {
    if (this.isTachoEvent) {
      return this.studentAttendee?.student.id;
    }

    return this.dlmData?.student?.id;
  }

  get studentFullName(): string | undefined {
    if (this.isTachoEvent) {
      return (
        this.studentAttendee && getPersonFullName(this.studentAttendee.student)
      );
    }

    return this.dlmData?.student?.full_name;
  }

  get studentEducationId(): string | undefined {
    return this.dlmData?.student_education_id;
  }

  get instructorId(): string | undefined {
    if (this.isTachoEvent) {
      return this.instructorAttendee?.instructor.id;
    }

    return this.dlmData?.instructor.id;
  }

  get instructorFullName(): string | undefined {
    if (this.isTachoEvent) {
      return (
        this.instructorAttendee &&
        getPersonFullName(this.instructorAttendee.instructor)
      );
    }

    return this.dlmData?.instructor.full_name;
  }

  private get allStudentsAttendees(): StudentAttendee[] | undefined {
    return this.tachoData?.attendees
      .filter(isStudentAttendee)
      .sort((a, b) => getDiff(a.createdAt, b.createdAt));
  }

  get students(): StudentAttendee[] | undefined {
    return this.allStudentsAttendees
      ?.filter(({ student, cancelledAt }) => Boolean(student) && !cancelledAt)
      .sort((a, b) =>
        getPersonFullName(a.student).localeCompare(getPersonFullName(b.student))
      );
  }

  public get address(): Address | null | undefined {
    switch (this.type) {
      case EVENT_TYPE.DRIVING_LESSON: {
        return this.pickUpPoint?.address;
      }
      case EVENT_TYPE.SIMULATOR_LESSON: {
        return this.simulator?.address;
      }
      case EVENT_TYPE.DRIVING_EXAM: {
        return this.examAddress?.address;
      }
      case EVENT_TYPE.MEETING:
      case EVENT_TYPE.OFFICE_ACTIVITY:
      case EVENT_TYPE.TRAINING:
      case EVENT_TYPE.THEORY_LESSON: {
        return this.classroom?.address;
      }
      default: {
        return null;
      }
    }
  }

  get pickUpPointName(): string | undefined {
    if (this.isTachoEvent) {
      return this.pickUpPoint?.name;
    }

    return (
      this.dlmData?.pickup_location?.name || this.dlmData?.pick_up_point?.name
    );
  }

  get pickUpPointPhoto(): MediaObject | null | undefined {
    return this.pickUpPoint?.pickUpPointPhoto;
  }

  get streetAndNumber(): string | undefined {
    if (this.isTachoEvent) {
      return `${this.address?.street || ''} ${
        this.address?.streetNumber || ''
      }`.trim();
    }

    return (
      this.dlmData?.pickup_location?.address?.street_and_number ||
      this.dlmData?.pick_up_point?.address?.street_and_number
    );
  }

  get drivingCategoryId(): string | undefined {
    if (this.isTachoEvent) {
      return this.tachoData?.category?.id;
    }

    return this.dlmData?.lesson_definition?.id;
  }

  get drivingCategoryName(): string | undefined {
    if (this.isTachoEvent) {
      return this.tachoData?.category?.name;
    }

    if (this.isFree) {
      return;
    }

    return this.dlmData?.lesson_definition?.label;
  }

  get lessonDefinitions(): LessonDefinition[] {
    return this.dlmData?.lesson_definitions ?? [];
  }

  get drivingCategoryChargerId(): number | undefined {
    return this.tachoData?.category?.chargerId;
  }

  get zipCodeAndCity(): string | undefined {
    if (this.isTachoEvent) {
      const { zipCode, city } = this.address || {};

      return `${zipCode || ''} ${city || ''}`.trim();
    }

    const { zip_code, city } =
      this.dlmData?.pickup_location?.address ||
      this.dlmData?.pick_up_point?.address ||
      {};

    return `${zip_code || ''} ${city || ''}`.trim();
  }

  get resourceId(): string | undefined {
    return this.tachoData?.resource?.id;
  }

  get pickUpPoint(): PickUpPoint | null | undefined {
    if (this.isTachoEvent) {
      return this.tachoData?.resource?.pickUpPoint;
    }

    return null;
  }

  public get classroom(): Classroom | null | undefined {
    return this.tachoData?.resource?.classroom;
  }

  public get simulator(): Simulator | null | undefined {
    return this.tachoData?.resource?.simulator;
  }

  public get examAddress(): ExamAddress | null | undefined {
    return this.tachoData?.resource?.examAddress;
  }

  public get displayTime(): string {
    return formatTimeRange(this.startDate, this.endDate);
  }

  get categoryHexColor(): string | null | undefined {
    if (this.isTachoEvent) {
      return this.tachoData?.category?.colorHexCode;
    }

    return this.dlmData && getLessonDefinitionColor(this.dlmData);
  }

  addAttendee(attendee: Attendee): void {
    this.tachoData?.attendees.push(attendee);
  }

  markAttendeeAsCancelled(attendeeId: string): void {
    const attendee = this.tachoData?.attendees.find(
      ({ id }) => id === attendeeId
    );

    if (attendee) {
      attendee.cancelledAt = getCurrentDate().toISOString();
    }
  }

  updateFromTachoData({
    startDate,
    endDate,
    status,
    ...rest
  }: ScheduleEventData): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.status = status;
    this.tachoData = rest;
  }

  updateFromDLMData(data: DrivingLessonResponse): void {
    const { start_time, end_time, ...rest } = data;
    this.startDate = start_time;
    this.endDate = end_time;
    this.status = getDLMLessonStatus(data);
    this.dlmData = rest;
  }

  updateWebinarAttendees(attendees: Attendee[]): void {
    if (!this.tachoData) {
      return;
    }

    this.tachoData.attendees = this.tachoData?.attendees.map((attendee) => {
      const updatedAttendee = attendees.find(({ id }) => id === attendee.id);

      if (updatedAttendee && attendee.student) {
        return {
          ...updatedAttendee,
          usedWebinarAttendanceCodes:
            updatedAttendee.usedWebinarAttendanceCodes ||
            attendee.usedWebinarAttendanceCodes,
          student: attendee.student,
          instructor: attendee.instructor,
        };
      }

      return attendee;
    });
  }

  getAttendeeByStudentId(studentId: string): StudentAttendee | undefined {
    const studentAttendees = this.allStudentsAttendees?.filter(
      ({ student }) => student?.id === studentId
    );

    const bookedAttendee = studentAttendees?.find(
      ({ cancelledAt, reservationExpiresAt, reservationConfirmedAt }) =>
        !cancelledAt && (!reservationExpiresAt || reservationConfirmedAt)
    );

    const cancelledAttendee = studentAttendees?.find(
      ({ cancelledAt }) => cancelledAt
    );

    return bookedAttendee || cancelledAttendee;
  }

  getStudentCancellationType(
    studentId: string
  ): ScheduleEventCancellationType | null {
    if (this.isDLMLesson) {
      if (this.isCancelled) {
        return EVENT_CANCELLATION_TYPE.GENERIC;
      }

      if (this.isAbsent) {
        return EVENT_CANCELLATION_TYPE.STUDENT_ABSENT;
      }
    }

    const studentAttendee = this.getAttendeeByStudentId(studentId);
    const isCancelledEvent = Boolean(
      studentAttendee?.cancelledAt ||
        studentAttendee?.absentAt ||
        this.status === EVENT_STATUS.CANCELLED
    );

    if (!studentAttendee || !isCancelledEvent) {
      return null;
    }

    const {
      absentAt,
      cancelledAt,
      cancelledTooLate,
      cancelledByAdministrator,
      cancelledByInstructor,
    } = studentAttendee;

    if (
      cancelledByAdministrator ||
      cancelledByInstructor ||
      (this.status === EVENT_STATUS.CANCELLED && !cancelledAt)
    ) {
      return EVENT_CANCELLATION_TYPE.CANCELLED_BY_SCHOOL;
    }
    if (absentAt) {
      return EVENT_CANCELLATION_TYPE.STUDENT_ABSENT;
    }
    if (cancelledTooLate) {
      return EVENT_CANCELLATION_TYPE.CANCELLED_TOO_LATE;
    }

    return EVENT_CANCELLATION_TYPE.CANCELLED_ON_TIME;
  }

  get history(): LessonHistoryItem[] {
    if (this.isTachoEvent) {
      if (!this.tachoData) {
        return [];
      }

      return this.tachoData.attendees
        .flatMap(getLessonHistoryItem)
        .filter((item): item is LessonHistoryItem => Boolean(item))
        .sort((a, b) => {
          if (a.action === 'lesson_created') {
            return -1;
          }
          if (b.action === 'lesson_created') {
            return 1;
          }

          return getDiff(a.date, b.date);
        });
    }

    if (!this.dlmData) {
      return [];
    }

    return this.dlmData.history.map(getDLMLessonHistoryItem);
  }
}

export default ScheduleEvent;
