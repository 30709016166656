import { LocationListener } from 'history';
import {
  action,
  autorun,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { matchPath } from 'react-router';
import { HTTP_STATUS_CODE } from 'src/constants';
import { RequestResult } from 'src/types';

import * as Sentry from '@sentry/react';

import { ROUTES } from '../../routes';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import { AppAPI } from '../api/AppAPI';
import LocalStorageService from '../services/LocalStorageService';
import { InstructorsAPI } from '../api/IspaAPI/InstructorsAPI';
import RootStore from './RootStore';

const DLM_SERVICE_KEY = 'dlm_service_enabled';

class ApplicationStore {
  maintenanceMode: boolean = false;
  maintenanceModeChecked: boolean = false;
  dlmServiceEnabled: boolean;
  private currentAppVersion: string;

  constructor(
    private readonly rootStore: RootStore,
    private readonly appAPI: AppAPI,
    private readonly ispaInstructorsAPI: InstructorsAPI
  ) {
    makeObservable<
      ApplicationStore,
      'currentAppVersion' | 'checkIfMaintenanceEnabled' | 'loadSettings'
    >(this, {
      maintenanceMode: observable,
      maintenanceModeChecked: observable,
      currentAppVersion: observable,
      dlmServiceEnabled: observable,
      checkAppVersion: action,
      onUpdate: action,
      checkIfMaintenanceEnabled: action,
      loadSettings: action,
    });

    this.currentAppVersion = LocalStorageService.getAppVersion();

    this.dlmServiceEnabled = JSON.parse(
      localStorage.getItem(DLM_SERVICE_KEY) || 'false'
    );

    reaction(
      () => this.currentAppVersion,
      (version) => LocalStorageService.setAppVersion(version)
    );

    autorun(() => Sentry.setTag('appVersion', this.currentAppVersion));

    reaction(
      () => this.dlmServiceEnabled,
      (value, prevValue) => {
        if (prevValue !== undefined && value !== prevValue) {
          localStorage.setItem(DLM_SERVICE_KEY, JSON.stringify(value));
          window.location.reload();
        }
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.rootStore.userStore.isAuthenticated,
      (canLoadSettings) => {
        if (canLoadSettings) {
          this.loadSettings();
        }
      },
      { fireImmediately: true }
    );

    this.updateMaintenanceStatus();
  }

  public locationListener: LocationListener = (location, actionType) => {
    if (
      actionType === 'REPLACE' &&
      matchPath(location.pathname, {
        path: ROUTES.THEORY_LESSON_ATTENDANCE_IMAGES,
      })
    ) {
      return;
    }

    this.checkAppVersion();
    this.loadSettings();
  };

  public async checkAppVersion(): Promise<void> {
    const appVersion = await this.getAppVersion();

    if (!appVersion) {
      return;
    }

    if (!this.currentAppVersion) {
      this.currentAppVersion = appVersion;
      return;
    }
    if (this.currentAppVersion !== appVersion) {
      this.onUpdate(appVersion);
    }
  }

  async updateMaintenanceStatus(
    result?: RequestResult<unknown>
  ): Promise<void> {
    if (result) {
      this.checkIfMaintenanceEnabled(result);
      return;
    }
    const newResult = await this.appAPI.getAppVersion();
    this.checkIfMaintenanceEnabled(newResult);
  }

  private checkIfMaintenanceEnabled(result: RequestResult<unknown>): void {
    runInAction(() => {
      this.maintenanceMode =
        !result.success &&
        result.status === HTTP_STATUS_CODE.SERVICE_UNAVAILABLE;
      this.maintenanceModeChecked = true;
    });
  }

  async onUpdate(newVersion: string): Promise<void> {
    // eslint-disable-next-line no-console
    console.log(`New version found: ${newVersion}, updating`);

    await serviceWorkerRegistration.update();

    runInAction(() => {
      this.currentAppVersion = newVersion;
    });
  }

  private getAppVersion = async (): Promise<string | void> => {
    const result = await this.appAPI.getAppVersion();

    this.updateMaintenanceStatus(result);

    if (result.success) {
      return result.data.version;
    }
  };

  private async loadSettings(): Promise<void> {
    if (!this.rootStore.userStore.isAuthenticated) {
      return;
    }

    const result = await this.ispaInstructorsAPI.fetchSettings();

    if (result.success) {
      runInAction(() => {
        this.dlmServiceEnabled = result.data.use_dlm;
      });
    }
  }
}

export default ApplicationStore;
