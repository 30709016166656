import classNames from 'classnames';
import { computed, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import RootStore from '../../../core/stores/RootStore';
import FhrAppBar from '../../custom/FhrAppBar';
import FhrBottomNavigation from '../../custom/FhrBottomNavigation';
import styles from './styles';

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  stores?: RootStore;
}

class Layout extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    makeObservable(this);
  }

  @computed
  get showBottomNavigation(): boolean {
    return (
      this.props.stores!.userStore.isAuthenticated &&
      this.props.stores!.uiStore.visibleBottomNavigation
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <FhrAppBar />

        <main
          className={classNames(
            classes.content,
            this.props.stores!.userStore.isAuthenticated && {
              withHeaderAppBar: this.props.stores!.uiStore.visibleStatusBar,
              withBottomNavBar:
                this.props.stores!.uiStore.visibleBottomNavigation,
            }
          )}
        >
          {this.props.children}
        </main>

        <FhrBottomNavigation visible={this.showBottomNavigation} />
      </>
    );
  }
}

export default withRouter(
  withStyles(styles)(inject('stores')(observer(Layout)))
);
