import { PracticeProtocolHistoryCategoryData } from './PracticeProtocolHistory.types';
import { PracticeProtocolHistoryCategory } from './PracticeProtocolHistoryCategory.model';

export class PracticeProtocolHistory {
  categories: ReadonlyArray<PracticeProtocolHistoryCategory>;

  constructor(categories: PracticeProtocolHistoryCategoryData[]) {
    this.categories = categories.map(
      (categoryData) => new PracticeProtocolHistoryCategory(categoryData)
    );
  }
}
