import { makeAutoObservable } from 'mobx';
import { DatesRange, DateString } from 'src/types';
import { getPersonFullName } from 'src/utils/helpers';
import {
  addTimeToDate,
  getDate,
  getDayEnd,
  getDayStart,
  getEventDuration,
  isDayStart,
  isLocalDayStart,
  isPastDate,
  isSameDayDates,
  parseVacationDatesRange,
} from 'src/utils/time';
import {
  DEFAULT_DLM_DRIVING_LESSON_TYPE,
  DrivingLessonResponse,
  getDLMLessonStatus,
  getLessonDefinitionColor,
  LESSON_DURATION_IN_MINUTES,
  NewOpenDrivingLessonData,
} from '../DLMService';

import {
  EVENT_STATUS,
  EVENT_TYPE,
  ScheduleEventStatus,
  ScheduleEventType,
} from '../ScheduleEvent';
import {
  CalendarEventInstructorAttendee,
  CalendarEventStudentAttendee,
  CalendarScheduleEventData,
  isCalendarEventInstructorAttendee,
  isCalendarEventStudentAttendee,
} from './CalendarScheduleEvent.types';

type TachoData = Pick<
  CalendarScheduleEventData,
  'topic' | 'attendees' | 'resource' | 'title' | 'category' | 'description'
>;

type DLMData = Pick<
  DrivingLessonResponse,
  | 'status'
  | 'student'
  | 'lesson_definition'
  | 'lesson_definitions'
  | 'pick_up_point'
  | 'pickup_location'
  | 'lessons'
  | 'has_pending_changes'
>;

export class CalendarScheduleEvent {
  static fromTachoData({
    id,
    type,
    status,
    startDate,
    endDate,
    ...rest
  }: CalendarScheduleEventData): CalendarScheduleEvent {
    return new CalendarScheduleEvent(
      id,
      type,
      status,
      startDate,
      endDate,
      rest,
      null
    );
  }

  static fromDLMData(lesson: DrivingLessonResponse): CalendarScheduleEvent {
    const { id, start_time, end_time, ...rest } = lesson;
    const eventStatus = getDLMLessonStatus(lesson);

    return new CalendarScheduleEvent(
      id,
      EVENT_TYPE.DRIVING_LESSON,
      eventStatus,
      start_time,
      end_time,
      null,
      rest
    );
  }

  public title: string | null;
  public allDay: boolean;

  constructor(
    public readonly id: string,
    public readonly type: ScheduleEventType,
    public status: ScheduleEventStatus,
    public startDate: DateString,
    public endDate: DateString,

    private tachoData: TachoData | null,
    private dlmData: DLMData | null
  ) {
    makeAutoObservable(this);

    this.allDay = false;

    this.dlmData?.lesson_definitions?.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
  }

  get isDLMLesson(): boolean {
    return Boolean(this.dlmData);
  }

  public updateFromTachoData({
    status,
    startDate,
    endDate,
    ...rest
  }: CalendarScheduleEventData) {
    this.status = status;
    this.startDate = startDate;
    this.endDate = endDate;

    this.tachoData = rest;

    return this;
  }

  private get instructor(): CalendarEventInstructorAttendee | undefined {
    const instructor = this.tachoData?.attendees
      .filter(isCalendarEventInstructorAttendee)
      .find(({ cancelledAt }) => !cancelledAt);

    return instructor;
  }

  private get allStudentsAttendees():
    | CalendarEventStudentAttendee[]
    | undefined {
    return this.tachoData?.attendees.filter(isCalendarEventStudentAttendee);
  }

  private get studentAttendee(): CalendarEventStudentAttendee | undefined {
    if (this.status === EVENT_STATUS.ABSENT) {
      return this.allStudentsAttendees?.find(({ absentAt }) => absentAt);
    }
    return this.allStudentsAttendees?.find(
      ({
        student,
        cancelledAt,
        absentAt,
        reservationExpiresAt,
        reservationConfirmedAt,
      }) =>
        Boolean(student) &&
        !cancelledAt &&
        !absentAt &&
        (!reservationExpiresAt ||
          reservationConfirmedAt ||
          !isPastDate(reservationExpiresAt))
    );
  }

  get studentFullName(): string | undefined {
    if (this.isTachoLesson) {
      return (
        this.studentAttendee && getPersonFullName(this.studentAttendee.student)
      );
    }

    return this.dlmData?.student?.full_name;
  }

  get duration(): number {
    return getEventDuration(this.startDate, this.endDate);
  }

  get schoolName(): string {
    return this.tachoData?.resource?.classroom?.school?.name || '';
  }

  get vacationDatesRange(): DatesRange | undefined {
    if (!this.tachoData?.description) {
      return;
    }

    return parseVacationDatesRange(this.tachoData.description);
  }

  get calendarStartDate(): Date {
    if (
      this.type === EVENT_TYPE.VACATION &&
      this.vacationDatesRange &&
      !isDayStart(this.startDate)
    ) {
      if (isSameDayDates(this.startDate, this.vacationDatesRange.startDate)) {
        return getDate(this.startDate);
      }

      return !isDayStart(this.endDate) &&
        isSameDayDates(this.endDate, this.vacationDatesRange.endDate)
        ? getDayStart(this.startDate)
        : getDate(this.startDate);
    }

    return getDate(this.startDate);
  }

  get calendarEndDate(): Date {
    let calendarEndDate: Date | DateString = this.endDate;

    if (
      this.type === EVENT_TYPE.VACATION &&
      this.vacationDatesRange &&
      !isDayStart(this.endDate)
    ) {
      if (!isSameDayDates(this.startDate, this.endDate)) {
        calendarEndDate = getDayEnd(this.startDate);
      } else {
        calendarEndDate =
          !isDayStart(this.startDate) &&
          isSameDayDates(this.startDate, this.vacationDatesRange.startDate)
            ? getDayEnd(this.endDate)
            : this.endDate;
      }
    }

    return isLocalDayStart(getDate(calendarEndDate))
      ? addTimeToDate(calendarEndDate, -1, 'millisecond')
      : getDate(calendarEndDate);
  }

  get draggable(): boolean {
    return this.type === EVENT_TYPE.DRIVING_LESSON;
  }

  get categoryId(): string | undefined {
    if (this.isTachoLesson) {
      return this.tachoData?.category?.id;
    }

    return this.dlmData?.lesson_definition?.id;
  }

  get categoryName(): string | undefined {
    if (this.isTachoLesson) {
      return this.tachoData?.category?.name;
    }

    return this.dlmData?.lesson_definition?.label;
  }

  get lessonDefinitionsNames(): string[] {
    return this.dlmData?.lesson_definitions?.map(({ label }) => label) ?? [];
  }

  get categoryHexColor(): string | null | undefined {
    if (this.isTachoLesson) {
      return this.tachoData?.category?.colorHexCode;
    }

    return this.dlmData && getLessonDefinitionColor(this.dlmData);
  }

  get pickUpPointId(): string | undefined {
    if (this.isTachoLesson) {
      return this.tachoData?.resource?.pickUpPoint?.id;
    }

    return this.dlmData?.pickup_location?.id || this.dlmData?.pick_up_point?.id;
  }

  get topicNumber(): number | undefined {
    return this.tachoData?.topic?.number;
  }

  get vacationApprovedAt(): DateString | null | undefined {
    return this.instructor?.vacationApprovedAt;
  }

  get isFree(): boolean {
    return this.status === EVENT_STATUS.FREE;
  }

  get isReserved(): boolean {
    return this.status === EVENT_STATUS.RESERVED;
  }

  get isAbsent(): boolean {
    return this.status === EVENT_STATUS.ABSENT;
  }

  get isTachoLesson(): boolean {
    return Boolean(this.tachoData);
  }

  get hasPendingChanges(): boolean {
    return Boolean(this.dlmData?.has_pending_changes);
  }

  getDlmCopyData = (startDate: Date): NewOpenDrivingLessonData | null => {
    if (
      !this.isDLMLesson ||
      !this.dlmData?.lesson_definition?.id ||
      !this.pickUpPointId ||
      !this.dlmData?.lessons.length
    ) {
      return null;
    }

    return {
      start_time: startDate.toISOString(),
      pick_up_point_id: this.pickUpPointId,
      pickup_location_id: this.pickUpPointId,
      lesson_definitions: this.isFree
        ? this.dlmData.lesson_definitions?.map(({ id }) => id) ?? []
        : [this.dlmData.lesson_definition.id],
      lessons: this.dlmData.lessons.map(() => ({
        driving_activity_id: DEFAULT_DLM_DRIVING_LESSON_TYPE,
        duration_in_minutes: LESSON_DURATION_IN_MINUTES,
      })),
    };
  };
}

export default CalendarScheduleEvent;
