import uniqBy from 'lodash/uniqBy';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { Instructor, UpdateInstructorData } from 'src/core/entities/Instructor';

import { Result } from '../../types';
import { InstructorsAPI } from '../api/TachoAPI/InstructorsAPI';
import { InstructorsAPI as IspaInstructorsAPI } from '../api/IspaAPI/InstructorsAPI';
import RootStore from './RootStore';

class InstructorsStore {
  @observable public instructors: IObservableArray<Instructor>;
  @observable public fetchingInstructorsIds: IObservableArray<string>;
  @observable public qrCode: string | null;
  @observable public qrCodeFetching: boolean;
  @observable public numberOfQrCodeScans?: number;
  @observable public numberOfQRCodeScansFetching: boolean;

  constructor(
    private readonly rootStore: RootStore,
    private readonly instructorsAPI: InstructorsAPI,
    private readonly ispaInstructorsAPI: IspaInstructorsAPI
  ) {
    makeObservable(this);

    this.instructors = observable.array([]);
    this.fetchingInstructorsIds = observable.array();
    this.qrCode = null;
    this.qrCodeFetching = false;
    this.numberOfQRCodeScansFetching = false;
  }

  @computed
  public get currentInstructor(): Instructor | undefined {
    const currentUser = this.rootStore.userStore.getCurrentUser();
    if (!currentUser) {
      return undefined;
    }
    return this.getInstructorById(currentUser.id);
  }

  public getInstructorById(instructorId: string): Instructor | undefined {
    const instructor = this.instructors.find(({ id }) => id === instructorId);
    if (!instructor) {
      this.loadInstructorById(instructorId);
    }
    return instructor;
  }

  updateInstructor(
    instructorId: string,
    instructorData: UpdateInstructorData
  ): Promise<Result> {
    return this.updateInstructorData(instructorId, instructorData);
  }

  @action
  async loadQRCode(): Promise<void> {
    if (!this.currentInstructor?.id || this.qrCodeFetching) {
      return;
    }

    this.qrCodeFetching = true;

    const result = await this.ispaInstructorsAPI.fetchRatingQRCode(
      this.currentInstructor.id
    );

    if (result.success) {
      runInAction(() => {
        this.qrCode = result.data.svg;
      });
    }

    runInAction(() => {
      this.qrCodeFetching = false;
    });
  }

  @action
  async loadNumberOfQRCodeScans(): Promise<void> {
    if (!this.currentInstructor?.id || this.numberOfQRCodeScansFetching) {
      return;
    }

    this.numberOfQRCodeScansFetching = true;

    const result = await this.ispaInstructorsAPI.fetchNumberOfQRCodeScans();

    if (result.success) {
      runInAction(() => {
        this.numberOfQrCodeScans = result.data.number_of_qr_code_scans;
      });
    }

    runInAction(() => {
      this.numberOfQRCodeScansFetching = false;
    });
  }

  // API requests
  @action
  private async loadInstructorById(instructorId: string): Promise<void> {
    if (this.fetchingInstructorsIds.includes(instructorId)) {
      return;
    }

    runInAction(() => {
      this.fetchingInstructorsIds.push(instructorId);
    });

    const result = await this.instructorsAPI.fetchById(instructorId);

    runInAction(() => {
      this.fetchingInstructorsIds.remove(instructorId);
    });

    if (result.success) {
      const instructor = new Instructor(result.data);

      runInAction(() => {
        this.instructors.replace(
          uniqBy([instructor, ...this.instructors], 'id')
        );
      });
    }
  }

  @action
  private async updateInstructorData(
    instructorId: string,
    instructorData: UpdateInstructorData
  ): Promise<Result> {
    const result = await this.instructorsAPI.update(
      instructorId,
      instructorData
    );

    if (result.success) {
      const instructor = this.getInstructorById(instructorId)!;

      instructor.updateFromResponse(result.data);
    }

    return result;
  }
}

export default InstructorsStore;
