import {
  action,
  autorun,
  computed,
  IObservableArray,
  makeAutoObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { InstructorsStudent } from 'src/core/entities/Instructor';
import { ApiRequestResult } from 'src/types';

interface UserStore {
  isAuthenticated: boolean;
}

interface Api {
  fetchStudents(): ApiRequestResult<InstructorsStudent[]>;
  markFavorite(studentId: string): ApiRequestResult<void>;
  unmarkFavorite(studentId: string): ApiRequestResult<void>;
}

interface INASocket {
  onStudentsListUpdated(listener: (data: InstructorsStudent[]) => void): void;
}

class InstructorsStudentsStore {
  dataLoaded: boolean;

  private data: IObservableArray<InstructorsStudent>;
  private loading: boolean;

  constructor(
    private readonly userStore: UserStore,
    private readonly api: Api,
    private readonly socket: INASocket
  ) {
    makeAutoObservable<
      InstructorsStudentsStore,
      'data' | 'loading' | 'canLoadData' | 'loadData' | 'toInitialState'
    >(this, {
      dataLoaded: observable,
      data: observable,
      loading: observable,
      studentsIds: computed,
      favoriteStudentsIds: computed,
      primaryStudentsIds: computed,
      canLoadData: computed,
      toInitialState: action,
      loadData: action,
    });

    this.data = observable.array();
    this.loading = false;
    this.dataLoaded = false;

    autorun(() => {
      if (!this.canLoadData && this.dataLoaded) {
        this.toInitialState();
        return;
      }

      this.loadData();
    });

    this.socket.onStudentsListUpdated((data) => {
      this.data.replace(data);
    });
  }

  get studentsIds() {
    return this.data.map(({ student_id }) => student_id);
  }

  get favoriteStudentsIds() {
    return this.data
      .filter(({ student_is_favourite }) => student_is_favourite)
      .map(({ student_id }) => student_id);
  }

  get primaryStudentsIds() {
    return this.data
      .filter(({ instructor_is_primary }) => instructor_is_primary)
      .map(({ student_id }) => student_id);
  }

  findByStudentId(studentId: string): InstructorsStudent | undefined {
    return this.data.find(({ student_id }) => student_id === studentId);
  }

  async toggleFavorite(studentId: string): Promise<void> {
    const student = this.findByStudentId(studentId);

    if (!student) {
      return;
    }

    if (student.student_is_favourite) {
      const result = await this.api.unmarkFavorite(studentId);

      if (result.success) {
        runInAction(() => {
          student.student_is_favourite = false;
        });
      }
    } else {
      const result = await this.api.markFavorite(studentId);

      if (result.success) {
        runInAction(() => {
          student.student_is_favourite = true;
        });
      }
    }
  }

  private get canLoadData() {
    return this.userStore.isAuthenticated;
  }

  private async toInitialState(): Promise<void> {
    await when(() => !this.loading);

    runInAction(() => {
      this.data.replace([]);
      this.loading = false;
      this.dataLoaded = false;
    });
  }

  private async loadData(): Promise<void> {
    if (!this.canLoadData || this.loading) {
      return;
    }

    this.loading = true;

    const response = await this.api.fetchStudents();

    if (response.success) {
      runInAction(() => {
        this.data.replace(response.data);
        this.dataLoaded = true;
      });
    }

    runInAction(() => {
      this.loading = false;
    });
  }
}

export default InstructorsStudentsStore;
