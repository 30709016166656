import { dlmAPI } from 'src/App';

import { useMutation } from '@tanstack/react-query';

import { UpdateDrivingLessonData } from '../DrivingLesson';

interface Data extends UpdateDrivingLessonData {
  id: string;
}

export const useDLMDrivingLessonUpdateMutation = () =>
  useMutation({
    mutationFn: async ({ id, ...data }: Data) =>
      dlmAPI.drivingLessonsAPI.update(id, data),
  });
