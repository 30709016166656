import { countries } from '../translations/locales/de-De.json';

export enum LoadingStatus {
  None = 'none',
  Loading = 'loading',
  Loaded = 'loaded',
  Failed = 'failed',
}

export type StringOrDate = string | Date;

export interface ISelectOption<T = string> {
  value: T;
  label: string;
  disabled?: boolean;
}

export interface SelectOption<T = string> {
  label: string;
  value: T;
}

export type DateString = string;

export interface Person {
  firstName: string | null;
  lastName: string | null;
}

export interface BaseEntity {
  id: string;
}

export interface AppVersion {
  version: string;
}

export type SortOrder = 'asc' | 'desc';

export type RenderNodeFunction = () => React.ReactNode;

interface BaseSuccessResult {
  success: true;
}

export interface SuccessRequestResult<T> extends BaseSuccessResult {
  data: T;
}

export interface FailedRequestResult {
  error?: unknown;
  success: false;
  status?: number;
  errorMessage?: string;
  errorSlug?: string;
}

export type RequestResult<T> = SuccessRequestResult<T> | FailedRequestResult;

export type ApiRequestResult<T = null> = Promise<RequestResult<T>>;

export type Result = BaseSuccessResult | FailedRequestResult;

export type TimeoutID = ReturnType<typeof setTimeout>;

export type CountryCode = keyof typeof countries;

export type DatesRange = { startDate: Date; endDate: Date };

export type FormattedDatesRange = { startDate: string; endDate: string };

export type DrivingLessonDemand = 'satisfied' | 'need_more' | 'need_much_more';

export type CamelToSnakeCase<S extends string> =
  S extends `${infer T}${infer U}`
    ? `${T extends Capitalize<T>
        ? '_'
        : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
    : S;

export type CamelKeysToSnakeCase<T extends Record<string, unknown>> = {
  [K in keyof T as K extends string ? CamelToSnakeCase<K> : never]: T[K];
};
