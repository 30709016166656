import { EVENT_TYPE } from 'src/core/entities/ScheduleEvent';

import { createStyles, Theme } from '@material-ui/core/styles';

import { APP_BAR_CUSTOM_COLORS } from './FhrAppBar';

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      height: theme.dimensions.APP_BAR_HEIGHT_SM,

      [theme.breakpoints.up('sm')]: {
        height: theme.dimensions.APP_BAR_HEIGHT_SM_UP,
      },

      [`&.${EVENT_TYPE.THEORY_LESSON}`]: {
        backgroundColor: theme[EVENT_TYPE.THEORY_LESSON].color,
      },
      [`&.${EVENT_TYPE.DRIVING_EXAM}`]: {
        backgroundColor: theme[EVENT_TYPE.DRIVING_EXAM].color,
      },
      [`&.${EVENT_TYPE.DRIVING_LESSON}`]: {
        backgroundColor: theme[EVENT_TYPE.DRIVING_LESSON].color,
      },
      [`&.${APP_BAR_CUSTOM_COLORS.GREEN_BLUE}`]: {
        backgroundColor: theme.greenBlue.color,
      },
    },
    appBarTitle: {
      fontSize: 16,
      flexGrow: 1,
    },
    toolbar: {
      flexGrow: 1,
      paddingLeft: 0,
      color: theme.palette.common.white,
      minHeight: theme.dimensions.APP_BAR_HEIGHT_SM_UP,
    },
    iconButton: {
      '&.inactive:hover': {
        backgroundColor: 'transparent',
        cursor: 'auto',
      },
    },
  });

export default styles;
