import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {
  action,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from 'mobx';
import { inject, observer, disposeOnUnmount } from 'mobx-react';
import * as React from 'react';

import InputBase from '@material-ui/core/InputBase';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Clear from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import RootStore from '../../../core/stores/RootStore';
import styles from './styles';

interface IProps extends WithStyles<typeof styles> {
  stores?: RootStore;
}

const DEBOUNCE_DELAY = 350;

class FhrSearch extends React.Component<IProps> {
  @observable inputValue: string = '';
  @disposeOnUnmount disposer: IReactionDisposer;

  constructor(props: IProps) {
    super(props);
    makeObservable(this);

    this.disposer = reaction(
      () => this.inputValue,
      (value) => {
        this.changeSearch(value);
      }
    );
  }

  componentWillUnmount(): void {
    this.clearInput();
  }

  @action
  changeSearch = debounce((value: string) => {
    this.props.stores!.uiStore.setAppBarSearchString(value);
  }, DEBOUNCE_DELAY);

  @action
  inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.inputValue = value;
  };

  @action
  clearInput = () => {
    this.inputValue = '';
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.search}>
        <div className={classNames(classes.icon, classes.searchIcon)}>
          <SearchIcon />
        </div>

        <InputBase
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={this.inputValue}
          onChange={this.inputChangeHandler}
          placeholder="Search…"
        />

        <div
          className={classNames(classes.icon, classes.clearIcon)}
          onClick={this.clearInput}
        >
          <Clear />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(inject('stores')(observer(FhrSearch)));
