import { DateString } from 'src/types';

import { AttendeeData } from '../Attendee';
import { DrivingLessonCategoryData } from '../DrivingLessonCategory';
import { DrivingLessonTypeData } from '../DrivingLessonType';
import { ScheduleEventResourceData } from '../ScheduleEventResource';
import { TheoryLessonTopicData } from '../TheoryLessonTopic';

export const EVENT_TYPE = {
  THEORY_LESSON: 'theory_lesson',
  DRIVING_LESSON: 'driving_lesson',
  DRIVING_EXAM: 'driving_exam',
  THEORY_EXAM: 'theory_exam',
  SIMULATOR_LESSON: 'simulator_lesson',
  MEETING: 'meeting',
  OFFICE_ACTIVITY: 'office_activity',
  TRAINING: 'training',
  VACATION: 'vacation',
  SICKNESS: 'sickness',
  BLOCKER: 'blocker',
  MISC: 'misc',
  PUBLIC_HOLIDAY: 'public_holiday',
  COMPANY_HOLIDAY: 'company_holiday',
} as const;

export const STUDENT_EVENTS_TYPES = [
  EVENT_TYPE.THEORY_LESSON,
  EVENT_TYPE.SIMULATOR_LESSON,
  EVENT_TYPE.DRIVING_LESSON,
  EVENT_TYPE.DRIVING_EXAM,
  EVENT_TYPE.THEORY_EXAM,
];

export const EVENT_STATUS = {
  FREE: 'free',
  BOOKED: 'booked',
  CANCELLED: 'cancelled',
  RESERVED: 'reserved',
  ABSENT: 'absent',
} as const;

export const EXAM_PASSING_STATUS = {
  NONE: 'none',
  PASSED: 'passed',
  FAILED: 'failed',
  NOT_OCCURRED: 'not_occurred',
  EXCUSED: 'excused',
  ABSENT_UNEXCUSED: 'absent_unexcused',
} as const;

export const EVENT_CANCELLATION_TYPE = {
  STUDENT_ABSENT: 'student_absent',
  CANCELLED_ON_TIME: 'event_cancelled_on_time',
  CANCELLED_TOO_LATE: 'event_cancelled_too_late',
  CANCELLED_BY_SCHOOL: 'cancelled_by_school',
  GENERIC: 'generic',
} as const;

export const EXAM_PASSING_STATUS_VALUES = Object.values(EXAM_PASSING_STATUS);

export type ExamPassingStatus =
  (typeof EXAM_PASSING_STATUS)[keyof typeof EXAM_PASSING_STATUS];

export type ScheduleEventType = (typeof EVENT_TYPE)[keyof typeof EVENT_TYPE];

export type ScheduleEventStatus =
  (typeof EVENT_STATUS)[keyof typeof EVENT_STATUS];

export type ScheduleEventCancellationType =
  (typeof EVENT_CANCELLATION_TYPE)[keyof typeof EVENT_CANCELLATION_TYPE];

export const EDITABLE_EVENTS_TYPES: ReadonlyArray<ScheduleEventType> = [
  EVENT_TYPE.DRIVING_LESSON,
  EVENT_TYPE.BLOCKER,
  EVENT_TYPE.MEETING,
  EVENT_TYPE.OFFICE_ACTIVITY,
  EVENT_TYPE.TRAINING,
  EVENT_TYPE.MISC,
];

export interface BaseScheduleEventData {
  id: string;
  type: ScheduleEventType;
  startDate: DateString;
  endDate: DateString;
  status: ScheduleEventStatus;
}

export interface ScheduleEventData {
  id: string;
  type: ScheduleEventType;
  startDate: DateString;
  endDate: DateString;
  attendees: AttendeeData[];
  drivingLessonTypes: DrivingLessonTypeData[];
  topic: TheoryLessonTopicData | null;
  resource: ScheduleEventResourceData | null;
  status: ScheduleEventStatus;
  maintained: boolean;
  description: string | null;
  webinar: boolean;
  webinarUrl: string | null;
  webinarAttendanceCodes: string[];
  title: string | null;
  category: DrivingLessonCategoryData | null;
  passingStatus: ExamPassingStatus;
}

export interface CreateGenericEventData {
  type: ScheduleEventType;
  startDate: DateString | Date;
  endDate: DateString | Date;
  attendees: [{ instructor: string }];
  description?: string;
}

export interface CreateDrivingLessonData {
  type: typeof EVENT_TYPE.DRIVING_LESSON;
  startDate: DateString | Date;
  endDate: DateString | Date;
  attendees: [{ instructor: string }, { student: string }?];
  resource?: {
    pickUpPoint: string;
  };
  category?: string;
}

export interface CreateVacationData {
  startDate: DateString | Date;
  endDate: DateString | Date;
  instructor: string;
  startDateHalfDay?: boolean;
  endDateHalfDay?: boolean;
}

export interface CreateSicknessData {
  startDate: DateString | Date;
  endDate: DateString | Date;
  instructor: string;
}

export type CreateScheduleEventData =
  | CreateDrivingLessonData
  | CreateGenericEventData;

export interface UpdateBlockerData {
  startDate?: DateString | Date;
  endDate?: DateString | Date;
  description?: string;
}

export interface UpdateDrivingLessonData {
  startDate?: DateString | Date;
  endDate?: DateString | Date;
  category?: string;
  drivingLessonTypes?: [];
  resource?: {
    id?: string;
    pickUpPoint: string;
  };
}

export type UpdateScheduleEventData =
  | UpdateBlockerData
  | UpdateDrivingLessonData;

export interface VacationData {
  days: { id: string }[];
}

export interface SicknessData {
  days: { id: string }[];
}

type SortOrder = 'asc' | 'desc';

export interface ScheduleEventRequestParams {
  type?: ScheduleEventType[];
  status?: ScheduleEventStatus[];
  'startDate[after]'?: DateString | Date;
  'endDate[before]'?: DateString | Date;
  'attendees.instructor'?: string;
  'attendees.student'?: string;
  groups?: ['api:attendance_image:read', 'api:student:event', 'api:id'];
  itemsPerPage?: number;
  pagination?: boolean;
  'order[startDate]'?: SortOrder;
}

type LessonHistoryAction =
  | 'lesson_created'
  | 'lesson_reserved'
  | 'reservation_expired'
  | 'reservation_confirmed'
  | 'booked_by_student'
  | 'cancelled_by_student'
  | 'cancelled_by_student_too_late'
  | 'cancelled_by_instructor'
  | 'cancelled_by_administrator'
  | 'student_absent';

export interface LessonHistoryItem {
  key: string;
  date: string;
  action: LessonHistoryAction;
  studentFullName?: string;
  formattedDate?: string;
  formattedTime?: string;
}
