import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
  runInAction,
  when,
} from 'mobx';
import {
  CreateInstructorCommentData,
  InstructorComment,
} from 'src/core/entities/InstructorComment';

import { Result } from '../../types';
import { InstructorCommentsAPI } from '../api/TachoAPI/InstructorCommentsAPI';
import RootStore from './RootStore';

class InstructorCommentsStore {
  @observable studentId: string | null;

  @observable comments: IObservableArray<InstructorComment>;
  @observable commentsLoading: boolean;
  @observable removingCommentsIds: IObservableArray<string>;

  constructor(
    private readonly rootStore: RootStore,
    private readonly instructorCommentsAPI: InstructorCommentsAPI
  ) {
    makeObservable(this);

    this.studentId = null;
    this.comments = observable.array();
    this.commentsLoading = false;
    this.removingCommentsIds = observable.array();

    reaction(
      () => this.studentId,
      async (studentId) => {
        if (!studentId) {
          return;
        }

        await this.resetComments();

        this.loadCommentsByStudentId(studentId);
      }
    );
  }

  @action
  async resetComments(): Promise<void> {
    await when(() => !this.commentsLoading);

    runInAction(() => {
      this.comments.replace([]);
      this.commentsLoading = false;
      this.removingCommentsIds.replace([]);
    });
  }

  @computed
  get availableComments(): InstructorComment[] {
    return this.comments
      .filter(({ id }) => !this.removingCommentsIds.includes(id))
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
  }

  @computed
  get markedComments(): InstructorComment[] {
    return this.availableComments.filter(({ marked }) => marked);
  }

  @computed
  get unmarkedComments(): InstructorComment[] {
    return this.availableComments.filter(({ marked }) => !marked);
  }

  @computed
  get currentInstructorId(): string | undefined {
    return this.rootStore.userStore.currentInstructor?.id;
  }

  @action
  setStudentId(studentId: string | null = null) {
    this.studentId = studentId;
  }

  @action
  async createInstructorComment(commentText: string): Promise<Result> {
    if (!this.currentInstructorId || !this.studentId) {
      return { success: false };
    }

    const payload: CreateInstructorCommentData = {
      authoredBy: this.currentInstructorId,
      student: this.studentId,
      text: commentText,
      marked: false,
    };

    return this.createComment(payload);
  }

  @action
  async loadCommentsByStudentId(studentId: string) {
    if (this.commentsLoading) {
      return;
    }

    runInAction(() => {
      this.commentsLoading = true;
    });

    const result = await this.instructorCommentsAPI.fetchByStudentId(studentId);

    runInAction(() => {
      this.commentsLoading = false;
    });

    if (result.success) {
      const comments = result.data;

      this.comments.replace(comments);
    }
  }

  @action
  async createComment(data: CreateInstructorCommentData): Promise<Result> {
    const result = await this.instructorCommentsAPI.create(data);
    if (result.success) {
      const comment = result.data;

      runInAction(() => {
        this.comments.push(comment);
      });
    }

    return result;
  }

  @action
  async markComment(comment: InstructorComment): Promise<void> {
    const result = await this.instructorCommentsAPI.toggleMark(comment.id);
    if (result.success) {
      runInAction(() => {
        comment.marked = !comment.marked;
      });
    }
  }

  @action
  async removeComment(comment: InstructorComment): Promise<void> {
    if (this.removingCommentsIds.includes(comment.id)) {
      return;
    }

    runInAction(() => {
      this.removingCommentsIds.push(comment.id);
    });

    const result = await this.instructorCommentsAPI.delete(comment.id);

    runInAction(() => {
      this.removingCommentsIds.remove(comment.id);
    });

    if (result.success) {
      runInAction(() => {
        this.comments.remove(comment);
      });
    }
  }
}

export default InstructorCommentsStore;
