import { BaseAPI } from 'src/core/api/BaseAPI';
import { ApiRequestResult } from 'src/types';
import {
  CalendarEventRequestParams,
  CalendarScheduleEventData,
} from 'src/core/entities/CalendarScheduleEvent';

export const endpoints = {
  fetchAll: () => '/api/v1/calendar',
};

export class CalendarScheduleEventsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchAll(
    params: CalendarEventRequestParams,
    signal?: AbortSignal
  ): ApiRequestResult<CalendarScheduleEventData[]> {
    return this.baseApi.get(endpoints.fetchAll(), {
      signal,
      params,
    });
  }
}
