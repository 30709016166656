import {
  action,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
} from 'mobx';
import { View } from 'react-big-calendar';
import { ScheduleEventType } from 'src/core/entities/ScheduleEvent';

import {
  APP_BAR_CUSTOM_COLORS,
  APP_ICONS,
} from '../../components/custom/FhrAppBar';
import {
  AppBarIcon,
  AppBarRightIcon,
} from '../../components/custom/FhrAppBar/FhrAppBar';
import {
  getCalendarDate,
  getInitialCalendarDate,
  getNewCalendarStartDate,
} from '../../utils/time';
import localStorageService from '../services/LocalStorageService';

export enum CALENDAR_DAY_CHANGE_DIRECTION {
  PREV,
  NEXT,
}

class UiStore {
  @observable public language: string = 'en_US';

  @observable public visibleStatusBar: boolean = true;
  @observable public title: string = '';
  @observable public currentEventType: ScheduleEventType | null;
  @observable public appBarCustomColor: APP_BAR_CUSTOM_COLORS | null = null;
  @observable public leftIcon: AppBarIcon;
  @observable public rightIcon: number | null;
  @observable public secondRightIcon: number | null;
  @observable public appBarRightIcons: IObservableArray<AppBarRightIcon>;
  @observable public rightText: string | null;
  @observable public search: boolean = false;
  @observable public searchString: string = '';
  @observable public renderAppBarBottom: React.ReactNode | null;

  @observable public visibleBottomNavigation: boolean = true;

  @observable public currentCalendarDate: Date | string;
  @observable public currentCalendarView: View;
  DEFAULT_CALENDAR_VIEW: View = 'work_week';

  constructor() {
    this.currentCalendarView = this.getStoredView();
    this.appBarRightIcons = observable.array();

    this.setInitialCalendarDate();

    reaction(
      () => this.currentCalendarView,
      (view: View) => {
        localStorageService.setCalendarView(view);
      }
    );

    makeObservable(this);
  }

  @action
  public setVisibleStatusBar(visible: boolean = true): void {
    this.visibleStatusBar = visible;
  }

  @action
  public setAppBarTitle(title: string = ''): void {
    this.title = title;
  }

  @action
  public setAppBarSearch = (visible: boolean = false): void => {
    this.search = visible;
  };

  @action
  public setAppBarSearchString = (string: string = ''): void => {
    this.searchString = string;
  };

  @action
  public setCurrentEventType = (
    type: ScheduleEventType | null = null
  ): void => {
    this.currentEventType = type;
  };

  @action
  public setAppBarCustomColor = (
    color: APP_BAR_CUSTOM_COLORS | null = null
  ): void => {
    this.appBarCustomColor = color;
  };

  @action
  public setAppBarLeftIcon = (icon: AppBarIcon = APP_ICONS.NONE): void => {
    this.leftIcon = icon;
  };

  @action
  public setAppBarRightIcon = (icon: AppBarIcon = APP_ICONS.NONE): void => {
    this.rightIcon = icon;
  };

  @action
  public setAppBarSecondRightIcon = (
    icon: AppBarIcon = APP_ICONS.NONE
  ): void => {
    this.secondRightIcon = icon;
  };

  @action
  setAppBarRightIcons = (icons: AppBarRightIcon[] = []): void => {
    this.appBarRightIcons.replace(icons);
  };

  @action
  public setAppBarRightText = (text: string | null = null): void => {
    this.rightText = text;
  };

  @action
  setAppBarCustomBottomRender = (
    component: React.ReactNode | null = null
  ): void => {
    this.renderAppBarBottom = component;
  };

  @action
  public setVisibleBottomNavigation(visible: boolean = true): void {
    this.visibleBottomNavigation = visible;
  }

  @action
  public setCurrentCalendarView = (view: View): void => {
    const newDate = getCalendarDate(
      this.currentCalendarDate,
      this.currentCalendarView,
      view
    );

    this.currentCalendarView = view;

    this.setCurrentCalendarDate(newDate);
  };

  @action
  public setCurrentCalendarDate = (date: Date): void => {
    this.currentCalendarDate = date;
  };

  @action
  public handleCalendarDayChange = (
    direction: CALENDAR_DAY_CHANGE_DIRECTION
  ): void => {
    const newStartDate = getNewCalendarStartDate(
      this.currentCalendarDate,
      this.currentCalendarView,
      direction
    );

    this.setCurrentCalendarDate(newStartDate);
  };

  @action
  public setInitialCalendarDate = (): void => {
    const initialCalendarDate = getInitialCalendarDate(
      this.currentCalendarView
    );

    this.currentCalendarDate = initialCalendarDate;
  };

  private getStoredView = (): View => {
    const views = ['day', '3Days', 'work_week'];
    const storedView = String(localStorageService.getCalendarView());
    if (views.includes(storedView)) {
      return storedView as View;
    }
    return this.DEFAULT_CALENDAR_VIEW;
  };
}

export default UiStore;
