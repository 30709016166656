import * as React from 'react';

export default function asyncComponent(importComponent: any) {
  class AsyncComponent extends React.Component<any, any> {
    // eslint-disable-next-line react/state-in-constructor
    state: Readonly<any> = {
      component: null,
    };

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component,
      });
    }

    render() {
      const C = this.state.component;

      // eslint-disable-next-line react/jsx-props-no-spreading
      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}
