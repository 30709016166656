import { BaseAPI } from 'src/core/api/BaseAPI';
import { StudentEducationData } from 'src/core/entities/StudentEducation';
import { ApiRequestResult } from 'src/types';

export class StudentEducationsAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  public fetchByStudentId(
    studentId: string
  ): ApiRequestResult<StudentEducationData[]> {
    return this.baseApi.get(
      `/api/v1/student_educations?student_id=${studentId}`
    );
  }
}
