import { BaseAPI } from 'src/core/api/BaseAPI';
import { DrivingActivity } from 'src/core/entities/DLMService/DrivingLesson';
import { ApiRequestResult } from 'src/types';

export class DrivingActivityAPI {
  constructor(private readonly baseApi: BaseAPI) {}

  fetchByStudentEducationId(
    studentEducationId: string
  ): ApiRequestResult<DrivingActivity[]> {
    return this.baseApi.get(
      `/api/v1/ispa/driving_activities?student_education_id=${studentEducationId}`
    );
  }
}
